import axios from "axios";
// import { Auth } from "aws-amplify";
import Auth from '@aws-amplify/auth';
// import CommonUtil from "../Shared/utils/CommonUtil";

const routeWithoutAuthGaurd = ["/", "/login", "/signUp", "/forgotpassword", "/SubListCommodity", "/commoditydetail"];

function resetAppOnLogout() {
    window.localStorage.removeItem('bijakGlobalToken'); // reset auth token & user info
    updateAuthHeader(); // reset axios header, remove Authorization header
}

/**
 * Set Authorization token to header by default
 */

export function updateAuthHeader() {
    const AUTH_TOKEN = window.localStorage.getItem('bijakGlobalToken');
    if (AUTH_TOKEN) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + AUTH_TOKEN;
    } else {
        axios.defaults.headers.common['Authorization'] = '';
    }
}

export function startTokenGenaerationAfter45Min() {

    //  for first time it checks if session exists then replace the id 
    Auth.currentSession().then(function (session) {
        if (!session || !session.refreshToken || !session.refreshToken.token) {
            if (routeWithoutAuthGaurd.indexOf(window.location.pathname) === -1) {
                window.localStorage.setItem('is_user_loggedIn', "false");
                window.localStorage.setItem('loggedInUserName', "");
                switchPathTo("login");
            }
        } else {
            // console.log(session)
            // console.log(session.getIdToken().getJwtToken())
            // console.log(session.refreshToken.token)
            window.localStorage.setItem('is_user_loggedIn', "true");
            window.localStorage.setItem('loggedInUserName', session.getIdToken()["payload"]["name"]);
            window.localStorage.setItem('bijakGlobalToken', session.getIdToken().getJwtToken());
        }
    }, function (err) {
        console.log(err);
        if (routeWithoutAuthGaurd.indexOf(window.location.pathname) === -1) {
            window.localStorage.setItem('is_user_loggedIn', "false");
            window.localStorage.setItem('loggedInUserName', "");
            // window.location = "/login";
            switchPathTo("login");

        }
    })
}

export function switchPathTo(pathName) {
    if (window.location.href.indexOf(pathName) !== -1) {
        window.location.href = window.location.origin + "/" + pathName;
    }
}

export function getToken() {
    const AUTH_TOKEN = window.localStorage.getItem('bijakGlobalToken');
    const s_time = window.localStorage.getItem('bijakGlobalExpTime');
    const c_time = (new Date()).getTime();


    if (((c_time - Number(s_time)) / (1000 * 60 * 60)) < 10) {
        // if(((c_time - Number(s_time) )/(1000 * 60 *60)) < 10  ){
        // console.log("============================> " + (c_time - Number(s_time)) / (1000 * 60 * 60));
        // return "AUTH_TOKEN";
        // console.log(AUTH_TOKEN)
        if (AUTH_TOKEN === null || AUTH_TOKEN === "null" || AUTH_TOKEN === undefined || AUTH_TOKEN === "undefined") {

            if (window.location.href.indexOf("login") === -1) {
                window.localStorage.setItem('is_user_loggedIn', "false");
                window.localStorage.setItem('loggedInUserName', "");
                switchPathTo("login");
            }

            Auth.signOut()
                .then(data => {
                    window.localStorage.setItem('bijakGlobalToken', null);
                    userAuth.resetAppOnLogout();

                    // window.location.reload();
                    return null;
                })
                .catch(err => {
                    console.log(err);
                    window.localStorage.setItem('bijakGlobalToken', null);
                    window.localStorage.setItem('is_user_loggedIn', "false");
                    window.localStorage.setItem('loggedInUserName', "");
                    userAuth.resetAppOnLogout();
                });

            return "null";
        } else {
            return AUTH_TOKEN;
        }

    } else {

        if (window.location.pathname.indexOf("forgot-password") === -1 && window.location.pathname.indexOf("404") === -1 && window.location.pathname.indexOf("login") === -1) {
            Auth.currentAuthenticatedUser({

            }).then(user => {

                // console.log(user);
                // user.preferredMFA = "SMS_MFA";
                user.deviceKey = user.getCachedDeviceKeyAndPassword();
                console.log(user.getCachedDeviceKeyAndPassword());
                user.forgetDevice({
                    onSuccess: function (result) {
                        console.log('call result: ' + result);
                    },

                    onFailure: function (err) {
                        console.log(err);
                    }
                });

            });

            Auth.signOut()
                .then(data => {
                    window.localStorage.setItem('bijakGlobalToken', null);
                    userAuth.resetAppOnLogout();
                    window.localStorage.setItem('loggedInUserName', '');
                    window.localStorage.setItem('is_user_loggedIn', "false");
                    switchPathTo("login");
                    // window.location.reload();
                    return;
                })
                .catch(err => {
                    console.log(err);
                    window.localStorage.setItem('bijakGlobalToken', null);
                    window.localStorage.setItem('loggedInUserName', '');
                    window.localStorage.setItem('is_user_loggedIn', "false");
                    userAuth.resetAppOnLogout();
                });
            switchPathTo("login");
        }
        return "null";
    }

}

var timeoutID;
// var wasUserInactive = false;

function setup() {
    window.localStorage.setItem("wasUserInactive", "false")
    window.addEventListener("mousemove", resetTimer, false);
    window.addEventListener("mousedown", resetTimer, false);
}


function startTimer() {
    timeoutID = window.setTimeout(goInactive, 50000); // wait 2 seconds before calling goInactive
}

function resetTimer(e) {
    window.clearTimeout(timeoutID);
    goActive();
}

function goInactive() {
    window.localStorage.setItem("wasUserInactive", "true");
}

function goActive() {
    // console.log("going Active");
    if (window.localStorage.getItem("wasUserInactive") === "true") {
        window.localStorage.setItem("wasUserInactive", "false")

        if (window.location.href.indexOf("login") === -1) {

            Auth.currentSession().then(function (session) {
                if (!session || !session.refreshToken || !session.refreshToken.token) {
                    console.error("================================session expired");
                    if (routeWithoutAuthGaurd.indexOf(window.location.pathname) === -1) {
                        switchPathTo("login");
                        window.localStorage.setItem('loggedInUserName', '');
                        window.localStorage.setItem('is_user_loggedIn', "false");
                    }
                } else {
                    console.error("================================token refreshed")
                    window.localStorage.setItem('bijakGlobalToken', session.idToken.jwtToken);
                }
            }, function (err) {
                console.error("================================000" + err);
                if (routeWithoutAuthGaurd.indexOf(window.location.pathname) === -1) {
                    window.localStorage.setItem('is_user_loggedIn', "false");
                    window.localStorage.setItem('loggedInUserName', "");
                    switchPathTo("login");
                }
            })
        }
    }
    startTimer();
}


const userAuth = {
    resetAppOnLogout,
    getToken,
    startTokenGenaerationAfter45Min,
    setup,
    switchPathTo
}

export default userAuth;