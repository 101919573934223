import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import locationicon from '../../assests/img/icons/location.png';
import { postSellCommodityEnquiry } from '../../services/postSellService';
import { getCurrencyConversionPrice } from '../../services/commonService';
import { Auth } from 'aws-amplify';
import pdf_icon from '../../assests/img/icons/pdf_icon.png';
import backtopage from '../../assests/icons/backtopage.png';
import CommonUtil from '../../shared/CommonUtil';
// import PopUpView from '../common/PopUpView';
import Tooltip from '@material-ui/core/Tooltip';
import info_icon from '../../assests/icons/info_icon.svg';
import whatsappicon from '../../assests/images/whatsapp_icon.png';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttoncss: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "1.25px",
        textTransform: "uppercase",
        color: "#FFFFFF"
    },
    customInput: {
        width: "100%",
        background: "#fff"
    },
    customInputDisabled: {
        width: "100%",
        // background: "#fff"
    },
    paddingLabel: {
        paddingRight: "5px"
    },
    disabledInput: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        color: "#25282B"
    },
    disabledLabel: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.4px",
        color: "#25282B !important"
    },
    tooltipCls: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.4px",
        color: "rgba(255, 255, 255, 0.88)",
        flex: "none",
        order: 1,
        flexGrow: 0,
        margin: "0px 8px"
    }
});

class ProductDetailsContainer extends Component {

    state = {};
    constructor(props) {
        super(props);
        this.state = {
            errorFields: {},
            selectedCommodity: {},
            postCommodityPayload: {},
            showPostEnquiryModal: false,
            commodityDetails: {},
            buyerQuery: {
                "sell_id": "",
                "buyer_id": "",
                buyer_quantity: "",
                buyer_unit: "",
                buyer_shipment: "",
                buyer_payment_type: "",
                buyer_advance_percentage: '',//this.state.postCommodityPayload[""],
                buyer_comments: '',
                // offer_status: "",
                "buyer_price_unit": "",
                buyer_price: "",
                buyer_currency: "",
            },
            showBuyerSendEnquiryPage: false,
            loading: false,
            openModal: false,
            popUpMessage: "",
            popUpType: '',
            userId: '',
            currencyMapping: {
                price_currency: '',
                price: ''
            },
            buyerSellerCheckError: false,
            sendEnquiryErrorMsg: '',
            showTooltip: false,
            showTooltip2: false
        }
    }

    componentDidMount() {
        // let cMap = this.state.currencyMapping;
        // if (this.state.postCommodityPayload) {
        //     cMap["price"] = this.state.postCommodityPayload.price;
        //     cMap["price_currency"] = this.state.postCommodityPayload.price_currency;
        //     this.setState({ currencyMapping: cMap })
        // }
        this.getId();


        if (this.props.location.state && this.props.location.state.detail && this.props.location.state.detail.hasOwnProperty("selectedProductObj")) {
            let cMap = this.state.currencyMapping;
            cMap["price"] = this.props.location.state.detail["selectedProductObj"].price;
            cMap["price_currency"] = this.props.location.state.detail["selectedProductObj"].price_currency;
            this.setState({
                postCommodityPayload: this.props.location.state.detail["selectedProductObj"],
                currencyMapping: cMap
            });
        } else {
            let lastSearchData = CommonUtil.getDataFromLocal("commoditydetail");
            console.log(lastSearchData)
            if (lastSearchData) {
                let cMap = this.state.currencyMapping;
                cMap["price"] = lastSearchData.price;
                cMap["price_currency"] = lastSearchData.price_currency;
                this.setState({
                    postCommodityPayload: lastSearchData,
                    currencyMapping: cMap
                });
            } else {
                this.props.history.push("/");
            }
        }


    }

    async getId() {
        await Auth.currentAuthenticatedUser().then((user) => {
            console.log(user)
            if (user.attributes && user.attributes["custom:id"]) {
                let buyerVal = this.state.buyerQuery;
                buyerVal["buyer_id"] = user.attributes["custom:id"];
                this.setState({ buyerQuery: buyerVal, userId: user.attributes["custom:id"] });
            }
        });
    }


    checkIfFormIsValid() {
        try {
            let isValid = true;
            let errorFieldsVal = {};
            let ignoreKeyCheck = ['buyer_comments', 'sell_id'];
            for (var key in this.state.buyerQuery) {
                if (!this.state.buyerQuery[key] || this.state.buyerQuery[key] === "") {
                    if (key === "buyer_advance_percentage") {
                        if (this.state.buyerQuery["buyer_payment_type"] === "Advance payment") {
                            isValid = false;
                            errorFieldsVal[key] = true;
                        }

                    } else if (ignoreKeyCheck.indexOf(key) > -1) {

                    } else {
                        isValid = false;
                        errorFieldsVal[key] = true;
                    }
                }
            }

            this.setState({ errorFields: errorFieldsVal });
            console.log(errorFieldsVal)
            return isValid;
        } catch (err) {
            console.log(err);
        }
    }


    async sendEnquiry() {

        let payload = this.state.buyerQuery;
        payload["sell_id"] = Number(this.state.postCommodityPayload["sell_id"]);
        if (!this.checkIfFormIsValid()) {
            this.setState({ loading: false });
            return;
        }

        // let obj = {
        //     "sell_id": this.state.postCommodityPayload["sell_id"],
        //     "buyer_id": config.buyerid,
        //     buyer_quantity: this.state.postCommodityPayload["quantity"],
        //     buyer_unit: this.state.postCommodityPayload["quantity_unit"],
        //     buyer_shipment: this.state.postCommodityPayload["shipment"],
        //     buyer_payment_type: this.state.postCommodityPayload["payment_type"],
        //     buyer_advance_percentage: '0',//this.state.postCommodityPayload[""],
        //     buyer_comments: this.state.postCommodityPayload["buyer_comments"] || '',
        //     // offer_status: "",
        //     buyer_price: this.state.postCommodityPayload["price"],
        //     buyer_currency: this.state.postCommodityPayload["price_currency"],
        // }

        console.log(payload);
        try {

            let resp = await postSellCommodityEnquiry(payload);
            console.log(resp);
            if (resp && resp.type === 1) {
                this.setState({ loading: false }, () => {
                    this.props.history.push({
                        pathname: '/mytradelist',
                        search: '',
                        state: { detail: { "landOn": "buyer" } }
                    });

                });
            } else {
                this.setState({ loading: false, sendEnquiryErrorMsg: resp.extraMsg })
            }



        } catch (error) {
            console.log(error);
        }
    }


    handleInputChange(event) {
        try {
            var floatIds = []; // this values need to be float
            let onlyInteger = [];
            
            var errors = this.state.errorFields;
            let buyerQueryVal = this.state.buyerQuery;
            let id = event.target.id;
            let val = event.target.value;

            if (!id && id === undefined) {
                id = event.target.name;
            }
            // alert(val)
            console.log(id)
            console.log(val)
            // if (floatIds.indexOf(id) > -1) {
            //     // if (val === "" || !isNaN(val)) {
            //     if (val === "" || val.match(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)) {
            //         buyerQueryVal[id] = val;
            //     }
            // } else {
            //     buyerQueryVal[id] = val;
            // }

            let flaotRegex = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
            if (floatIds.indexOf(id) > -1) {
                if (val === "" || flaotRegex.test(val)) {
                    if (id === "advance_percentage") {
                        if (val === "" || (Number(val) > 0 && Number(val) <= 100)) {
                            buyerQueryVal[id] = val;
                        } else {
                            return;
                        }
                    } else {
                        buyerQueryVal[id] = val;
                    }
                } else {
                    return;
                }

            } else if (onlyInteger.indexOf(id) > -1) {
                if (id === "advance_percentage") {
                    if (val === "" || (Number(val) > 0 && Number(val) <= 100)) {
                        // buyerQueryVal[id] = val;
                    } else {
                        return;
                    }
                }
                if (val === "" || val.match(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)) {
                    buyerQueryVal[id] =( val === "" ? val : Number(val));
                }
            } else {
                buyerQueryVal[id] = val;
            }


            if (id === "payment_type") {
                if (val !== "Advance payment") {
                    buyerQueryVal["buyer_advance_percentage"] = '';
                }
            }

            if (id === "buyer_advance_percentage") {
                if (val !== '') {
                    if (val < 1 || val > 100) {
                        return;
                    }
                }
            }

            if (errors.hasOwnProperty(id)) {
                delete errors[id];
            }

            this.setState({
                buyerQuery: buyerQueryVal,
                errorFields: errors,
                showFormError: false
            })

        } catch (err) {
            console.log(err);
        }
    }

    handleInputRadioChange(event, value, id) {
        try {
            event.preventDefault();
            console.log(id);
            console.log(value);
            var floatIds = []; // this values need to be float
            var errors = this.state.errorFields;
            errors[id] = false;
            let buyerQueryVal = this.state.buyerQuery;
            buyerQueryVal[id] = value
            this.setState({ buyerQuery: buyerQueryVal })
        } catch (error) {
            console.log(error);
        }
    }

    checkIfBuyerIsTheSeller() {
        if (this.state.userId.toString() === this.state.postCommodityPayload["userid"].toString()) {
            this.setState({
                buyerSellerCheckError: true,
                // popUpType: "warning",
                // popUpMessage: "You cannot make a Enquiry to your own post"
            })
        } else {
            this.setState({ showBuyerSendEnquiryPage: true }, () => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
        }
    }

    async convertCurrency() {
        try {
            // from_cur=INR&to_cur=AED
            let param = {
                "from_cur": this.state.postCommodityPayload["price_currency"],
                "to_cur": this.state.currencyMapping["price_currency"],
                "price": this.state.postCommodityPayload["price"]
            };
            let resp = await getCurrencyConversionPrice(param);
            let cMap = this.state.currencyMapping;
            cMap["price"] = resp.data.toFixed(2);
            this.setState({
                currencyMapping: cMap
            })
        } catch (error) {
            console.log(error);
        }
    }

    cloasePopUp() {
        this.setState({
            openModal: false,
            popUpType: "",
            popUpMessage: ""
        });
    }

    handleInputCurrencyRadioChange(event, value, id) {
        try {
            let currencyMappingVal = this.state.currencyMapping;
            currencyMappingVal[id] = value;

            this.setState({
                currencyMapping: currencyMappingVal
            }, () => {

                if (currencyMappingVal["price_currency"] === this.state.postCommodityPayload["price_currency"]) {
                    currencyMappingVal["price"] = this.state.postCommodityPayload["price"];
                    this.setState({
                        currencyMapping: currencyMappingVal
                    });
                } else {
                    this.convertCurrency();
                }
            })
        } catch (err) {
            console.log(err);
        }
    }

    handleCurrencyChange(event) {
        try {
            let currencyMappingVal = this.state.currencyMapping;
            let id = event.target.id;
            let val = event.target.value;

            if (!id && id === undefined) {
                id = event.target.name;
            }
            console.log(id)
            console.log(val)
            currencyMappingVal[id] = val;

            this.setState({
                currencyMapping: currencyMappingVal
            }, () => {

                if (currencyMappingVal["price_currency"] === this.state.postCommodityPayload["price_currency"]) {
                    currencyMappingVal["price"] = this.state.postCommodityPayload["price"];
                    this.setState({
                        currencyMapping: currencyMappingVal
                    });
                } else {
                    this.convertCurrency();
                }
            })
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const { classes } = this.props;
        const { errorFields, postCommodityPayload, buyerQuery, loading,
            selectedQualityParams, showBuyerSendEnquiryPage, currencyMapping } = this.state;
        console.log(postCommodityPayload);
        // console.log(this.state.userId);
        return (<div>

            {!showBuyerSendEnquiryPage &&
                <section className="sec_bg my_account_section">
                    <div className="container">
                        <div className="cus_sec_width tb_padding" style={{ padding: "52px 15px" }}>
                            <div className="pt-2">
                                <div className="d-block d-sm-none mb-4">
                                    <a href="#" className="cus_purple closeBtn serach_goBack_txt" onClick={() => this.props.history.goBack()}>
                                        <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;
                                    Go back
                                    </a>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-sm-4">
                                        <div className="comm_upload_title"> Commodity Docs </div>
                                        <div className="comm_img_upload_title pt-4"> Commodity Image </div>
                                        {postCommodityPayload.commodity_images && postCommodityPayload.commodity_images["data"] &&
                                            postCommodityPayload.commodity_images["data"].length > 0 &&
                                            postCommodityPayload.commodity_images["data"].map((item, img_index) =>
                                                <img key={img_index + "--"}
                                                    onClick={() => CommonUtil.openLinkInNewTab(item.url)}
                                                    className="mt-2 w-100  cursor-pointer" src={item["url"]} alt="" />
                                            )
                                        }
                                        <div className="comm_img_upload_title pt-4 pb-1"> Documents </div>
                                        {postCommodityPayload.other_doc_images && postCommodityPayload.other_doc_images["data"] &&
                                            postCommodityPayload.other_doc_images["data"].length > 0 ?
                                            postCommodityPayload.other_doc_images["data"].map((item, img_indexw) => (
                                                item["url"].indexOf(".pdf") > -1 ?
                                                    <div className="card flex-row align-items-center px-2 py-3 mt-2 mb-4  cursor-pointer"
                                                        onClick={() => CommonUtil.openLinkInNewTab(item.url)}>
                                                        <div className="col-2 pl-0">
                                                            <img src={pdf_icon} width="35" alt=" " />
                                                        </div>
                                                        <div className="col-10">
                                                            <p className="mb-0">{item["fname"]}</p>
                                                        </div>
                                                    </div> :
                                                    <img key={img_indexw + "--"}
                                                        onClick={() => CommonUtil.openLinkInNewTab(item.url)}
                                                        className="mt-2 w-100  mb-4 cursor-pointer" src={item["url"]} alt="" />)
                                            ) : <div className="no_document">&nbsp;No documents</div>
                                        }
                                    </div>

                                    <div className="col-sm-8 ">
                                        <div className="comm_upload_title cus_sec_width">Commodity Details</div>
                                        <form className="bootstrap-form needs-validation cus_sec_width pt-4" novalidate>
                                            <div>
                                                <TextField
                                                    id="outlined-select-currency-native"
                                                    label="Select Commodity"
                                                    value={postCommodityPayload.name}
                                                    fullWidth
                                                    disabled
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInputDisabled,
                                                            input: classes.disabledInput
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.disabledLabel
                                                        }
                                                    }}
                                                    error={errorFields["commodity_id"] ? true : false}
                                                    helperText={errorFields["commodity_id"] ? "This detail cannot be empty" : ''}
                                                    variant="outlined"
                                                >
                                                </TextField>
                                            </div>
                                            <div className="mt-4">
                                                <TextField
                                                    id="commodity_desc"
                                                    label="Commodity Description"
                                                    name="commodity_desc"
                                                    error={errorFields["commodity_desc"] ? true : false}
                                                    helperText={errorFields["commodity_desc"] ? "This detail cannot be empty" : ''}
                                                    value={postCommodityPayload.commodity_desc}
                                                    fullWidth
                                                    disabled
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInputDisabled,
                                                            input: classes.disabledInput
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.disabledLabel
                                                        }
                                                    }}
                                                    variant="outlined"
                                                />

                                            </div>
                                            <div className="mt-4  d575_none" style={{ display: "flex" }}>
                                                <TextField
                                                    id="quantity"
                                                    error={errorFields["quantity"] ? true : false}
                                                    type="number"
                                                    helperText={errorFields["quantity"] ? "This detail cannot be empty" : ''}
                                                    label="Selling commodity volume per month"
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInputDisabled,
                                                            input: classes.disabledInput
                                                        },

                                                        inputProps: { min: 0 }

                                                    }}

                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.disabledLabel
                                                        }
                                                    }}

                                                    fullWidth
                                                    disabled
                                                    style={{ width: '60%' }}
                                                    value={postCommodityPayload.quantity}
                                                    onChange={this.handleInputChange.bind(this)}
                                                    variant="outlined"
                                                /> &nbsp;&nbsp;
                                            <TextField
                                                    id="quantity_unit"
                                                    name="quantity_unit"
                                                    label="Unit"
                                                    error={errorFields["quantity_unit"] ? true : false}
                                                    helperText={errorFields["quantity_unit"] ? "This detail cannot be empty" : ''}
                                                    type="text"
                                                    disabled
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInputDisabled,
                                                            input: classes.disabledInput
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.disabledLabel
                                                        }
                                                    }}
                                                    fullWidth
                                                    value={postCommodityPayload.quantity_unit}
                                                    style={{ width: '39%' }}
                                                    variant="outlined">
                                                </TextField>
                                            </div>

                                            <div className="mt-4 d_lg_none_sm" style={{ display: "flex" }}>
                                                <TextField
                                                    id="quantity"
                                                    error={errorFields["quantity"] ? true : false}
                                                    helperText={errorFields["quantity"] ? "This detail cannot be empty" : ''}
                                                    label="Selling commodity volume per month"
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInputDisabled,
                                                            input: classes.disabledInput
                                                        },

                                                        inputProps: { min: 0 }

                                                    }}

                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.disabledLabel
                                                        }
                                                    }}
                                                    fullWidth
                                                    disabled
                                                    value={postCommodityPayload.quantity_unit + " " + postCommodityPayload.quantity}
                                                    onChange={this.handleInputChange.bind(this)}
                                                    variant="outlined"
                                                />
                                            </div>

                                            <div className="mt-4 ">
                                                <TextField
                                                    label="Location of Commodity"
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInputDisabled,
                                                            input: classes.disabledInput
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.disabledLabel
                                                        }
                                                    }}
                                                    fullWidth
                                                    disabled
                                                    variant="outlined"
                                                    id="locality"
                                                    name="locality"
                                                    error={errorFields["locality"] ? true : false}
                                                    helperText={errorFields["locality"] ? "This detail cannot be empty" : ''}
                                                    type="text"
                                                    value={postCommodityPayload.locality}
                                                    onChange={this.handleInputChange.bind(this)}
                                                >
                                                </TextField>

                                            </div>
                                            <div className="mt-4 d575_none">
                                                <TextField
                                                    id="year_of_harvest"
                                                    name="year_of_harvest"
                                                    disabled
                                                    label="Year of harvest"
                                                    error={errorFields["year_of_harvest"] ? true : false}
                                                    helperText={errorFields["year_of_harvest"] ? "This detail cannot be empty" : ''}
                                                    style={{ width: '49%' }}

                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInputDisabled,
                                                            input: classes.disabledInput
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.disabledLabel
                                                        }
                                                    }}
                                                    value={postCommodityPayload.year_of_harvest}
                                                    onChange={this.handleInputChange.bind(this)}
                                                    variant="outlined"
                                                >
                                                </TextField>
                                            </div>

                                            <div className="mt-4 d_lg_none_sm">
                                                <TextField
                                                    id="year_of_harvest"
                                                    name="year_of_harvest"
                                                    disabled
                                                    label="Year of harvest"
                                                    error={errorFields["year_of_harvest"] ? true : false}
                                                    helperText={errorFields["year_of_harvest"] ? "This detail cannot be empty" : ''}
                                                    // style={{ width: '49%' }}
                                                    fullWidth
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInputDisabled,
                                                            input: classes.disabledInput
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.disabledLabel
                                                        }
                                                    }}
                                                    value={postCommodityPayload.year_of_harvest}
                                                    onChange={this.handleInputChange.bind(this)}
                                                    variant="outlined"
                                                >
                                                </TextField>
                                            </div>




                                            <div className="mt-4 postEnquiry_title">Enquiry Details</div>
                                            <div className="mt-3 d575_none" style={{ display: "flex" }}>
                                                <TextField
                                                    id="price"
                                                    error={errorFields["price"] ? true : false}
                                                    helperText={errorFields["price"] ? "This detail cannot be empty" : ''}
                                                    name="price"
                                                    label="Estimated FOB price"
                                                    type="number"
                                                    disabled
                                                    value={currencyMapping.price}
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInputDisabled,
                                                            input: classes.disabledInput
                                                        },
                                                        inputProps: { min: 0 }
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.disabledLabel
                                                        }
                                                    }}
                                                    style={{ width: '50%' }}
                                                    variant="outlined"
                                                />
                                                 &nbsp;
                                            &nbsp;
                                            <TextField
                                                    select
                                                    id="price_currency"
                                                    name="price_currency"
                                                    label="Currency"
                                                    error={errorFields["price_currency"] ? true : false}
                                                    helperText={errorFields["price_currency"] ? "This detail cannot be empty" : ''}
                                                    style={{ width: '22%', background: "#ffffff" }}
                                                    value={currencyMapping.price_currency}
                                                    onChange={this.handleCurrencyChange.bind(this)}

                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInputDisabled,
                                                            input: classes.disabledInput
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.disabledLabel
                                                        }
                                                    }}
                                                    variant="outlined"
                                                >
                                                    {["INR", "USD", "AED"].map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}

                                                </TextField>
                                            &nbsp;
                                            <div className="per_text ">
                                                    Per
                                            </div>
                                            &nbsp;
                                            <TextField
                                                    id="price_unit"
                                                    name="price_unit"
                                                    label="Unit"
                                                    error={errorFields["price_unit"] ? true : false}
                                                    helperText={errorFields["price_unit"] ? "This detail cannot be empty" : ''}
                                                    type="text"
                                                    disabled
                                                    style={{ width: '22%' }}
                                                    value={postCommodityPayload.price_unit}
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInputDisabled,
                                                            input: classes.disabledInput
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.disabledLabel
                                                        }
                                                    }}
                                                    variant="outlined">
                                                </TextField>

                                            </div>

                                            <div className="mt-3 d_lg_none_sm">
                                                <TextField
                                                    id="price"
                                                    error={errorFields["price"] ? true : false}
                                                    helperText={errorFields["price"] ? "This detail cannot be empty" : ''}
                                                    name="price"
                                                    label="Estimated FOB price"
                                                    disabled
                                                    value={currencyMapping.price + " " + currencyMapping.price_currency + " per " + postCommodityPayload.price_unit}
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInputDisabled,
                                                            input: classes.disabledInput
                                                        },
                                                        inputProps: { min: 0 }
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.disabledLabel
                                                        }
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            </div>
                                            <div className="free_search mt-1 d_lg_none_sm">
                                                <div className="radio_toolbar" id="price_currency" style={{ margin: "0px" }}>
                                                    <input type="radio" id="INR" name="INR" value={"INR"}
                                                        // style={{ color: postCommodityPayload.payment_type === "L/C" ? "#25282B" : "#FFFFFF" }}
                                                        checked={currencyMapping.price_currency === "INR"}
                                                    />
                                                    <label htmlFor="INR" className="radio_ps"
                                                        style={{
                                                            background: currencyMapping.price_currency === "INR" ? "#247BC7" : "unset",
                                                            color: currencyMapping.price_currency !== "INR" ? "rgb(37, 40, 43)" : "#FFFFFF",
                                                            border: currencyMapping.price_currency !== "INR" ? "0px" : 'unset'
                                                        }}
                                                        onClick={(ev) => this.handleInputCurrencyRadioChange(ev, "INR", "price_currency")} >INR</label>

                                                    <input type="radio" id="USD" name="USD"
                                                        // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                        value="USD"
                                                        checked={currencyMapping.price_currency === "USD"}
                                                    />
                                                    <label htmlFor="USD" className="radio_ps"
                                                        style={{
                                                            marginLeft: "10px",
                                                            background: currencyMapping.price_currency === "USD" ? "#247BC7" : "unset",
                                                            color: currencyMapping.price_currency !== "USD" ? "rgb(37, 40, 43)" : "#FFFFFF",
                                                            border: currencyMapping.price_currency !== "USD" ? "0px" : 'unset'
                                                        }} onClick={(ev) => this.handleInputCurrencyRadioChange(ev, "USD", "price_currency")}>USD</label>

                                                    <input type="radio" id="AED" name="AED"
                                                        // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                        value="AED"
                                                        checked={currencyMapping.price_currency === "AED"}
                                                    />
                                                    <label htmlFor="AED" className="radio_ps"
                                                        style={{
                                                            marginLeft: "10px",
                                                            background: currencyMapping.price_currency === "AED" ? "#247BC7" : "unset",
                                                            color: currencyMapping.price_currency !== "AED" ? "rgb(37, 40, 43)" : "#FFFFFF",
                                                            border: currencyMapping.price_currency !== "AED" ? "0px" : 'unset'
                                                        }} onClick={(ev) => this.handleInputCurrencyRadioChange(ev, "AED", "price_currency")}>AED</label>
                                                </div>
                                            </div>
                                            <div className="mt-4 postEnquiry_title">Payment Preference &nbsp;&nbsp;
                                                <Tooltip
                                                    open={this.state.showTooltip2}
                                                    classes={{ tooltip: classes.tooltipCls }}
                                                    title="Please choose one of L/C or Advance Payment as your preferred payment mode. If Advance Payment is your preferred payment mode, mention the portion of total amount (%) that you would like to receive in advance." arrow>
                                                    <img src={info_icon}
                                                        onMouseOver={() => this.setState({ showTooltip2: !this.state.showTooltip2 })}
                                                        onMouseOut={() => this.setState({ showTooltip2: !this.state.showTooltip2 })}
                                                        onClick={() => this.setState({ showTooltip2: !this.state.showTooltip2 })}
                                                        alt="" />
                                                </Tooltip>
                                            </div>

                                            <div className="free_search mt-4">
                                                <div className="radio_toolbar">
                                                    <input type="radio"
                                                        id={postCommodityPayload.payment_type}
                                                        name={postCommodityPayload.payment_type}
                                                        value={postCommodityPayload.payment_type}
                                                        checked={true}
                                                    />
                                                    <label for={postCommodityPayload.payment_type} className="radio_ps"
                                                        style={{
                                                            background: "rgb(145 149 153)",
                                                            color: "#FFFFFF"
                                                        }}
                                                    >{postCommodityPayload.payment_type}</label>

                                                    {/* <input type="radio" id="Advance payment" name="Advance payment"
                                                        value="Advance payment"
                                                        checked={postCommodityPayload.payment_type === "Advance payment"}
                                                    />
                                                    <label for="Advance payment" className="radio_ps"
                                                        style={{
                                                            marginLeft: "10px",
                                                            background: postCommodityPayload.payment_type === "Advance payment" ? "#247BC7" : "#FFFFFF",
                                                            color: postCommodityPayload.payment_type !== "Advance payment" ? "#25282B" : "#FFFFFF"
                                                        }} >Advance Payment</label> */}
                                                </div>
                                                {errorFields["payment_type"] ? <div className="inputFrameinputError_txt">Please select an option</div> : ''}
                                            </div>

                                            {postCommodityPayload.payment_type &&
                                                postCommodityPayload.payment_type === "Advance payment" &&
                                                <div className="mt-4">

                                                    <TextField
                                                        id="advance_percentage"
                                                        error={errorFields["advance_percentage"] ? true : false}
                                                        helperText={errorFields["advance_percentage"] ? "This detail cannot be empty" : ''}
                                                        name="price"
                                                        label="Advance payment percentage"
                                                        type="number"
                                                        disabled
                                                        className="d575_none"
                                                        value={postCommodityPayload.advance_percentage}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.customInputDisabled,
                                                                input: classes.disabledInput
                                                            },
                                                            inputProps: { min: 1, max: 100 }
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.disabledLabel
                                                            }
                                                        }}
                                                        style={{ width: '59%' }}
                                                        variant="outlined"
                                                    />
                                                    <TextField
                                                        id="advance_percentage"
                                                        error={errorFields["advance_percentage"] ? true : false}
                                                        helperText={errorFields["advance_percentage"] ? "This detail cannot be empty" : ''}
                                                        name="price"
                                                        label="Advance payment percentage"
                                                        type="number"
                                                        disabled
                                                        className="d_lg_none_sm"
                                                        value={postCommodityPayload.advance_percentage}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.customInputDisabled,
                                                                input: classes.disabledInput
                                                            },
                                                            inputProps: { min: 1, max: 100 }
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.disabledLabel
                                                            }
                                                        }}
                                                        fullWidth
                                                        variant="outlined"
                                                    />
                                                </div>
                                            }
                                            <div className="mt-4">
                                                <TextField
                                                    id="offer_expiry_date"
                                                    error={errorFields["offer_expiry_date"] ? true : false}
                                                    helperText={errorFields["offer_expiry_date"] ? "This detail cannot be empty" : ''}
                                                    name="price"
                                                    label="Offer expiry date"
                                                    className="d575_none"
                                                    disabled
                                                    value={postCommodityPayload["offer_expiry_date"]}
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInputDisabled,
                                                            input: classes.disabledInput
                                                        },
                                                        inputProps: { min: 0 }
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.disabledLabel
                                                        }
                                                    }}
                                                    style={{ width: '59%' }}
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    id="offer_expiry_date"
                                                    error={errorFields["offer_expiry_date"] ? true : false}
                                                    helperText={errorFields["offer_expiry_date"] ? "This detail cannot be empty" : ''}
                                                    name="price"
                                                    label="Offer expiry date"
                                                    disabled
                                                    className="d_lg_none_sm"
                                                    value={postCommodityPayload["offer_expiry_date"]}
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInputDisabled,
                                                            input: classes.disabledInput
                                                        },
                                                        inputProps: { min: 0 }
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.disabledLabel
                                                        }
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            </div>

                                            <div className="mt-4 postEnquiry_title">Commodity Parameters</div>
                                            <div className="mt-4 d575_none">
                                                {postCommodityPayload && postCommodityPayload["other_quality_details"] &&
                                                    postCommodityPayload["other_quality_details"]["data"] &&
                                                    postCommodityPayload["other_quality_details"]["data"].length > 0 &&
                                                    postCommodityPayload["other_quality_details"]["data"].map((item, indobj) =>
                                                        <TextField
                                                            key={indobj + "__"}
                                                            id={item.name}
                                                            name={item.name}
                                                            disabled
                                                            error={errorFields[item.name] ? true : false}
                                                            label={item.name + " " + (item.unit ? item.unit : "")}
                                                            style={{
                                                                width: "49%",
                                                                marginLeft: indobj % 2 === 1 ? "2%" : '0%',
                                                                textTransform: "capitalize",
                                                                marginTop: "10px"
                                                            }}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.customInputDisabled,
                                                                    input: classes.disabledInput
                                                                },
                                                            }}
                                                            InputLabelProps={{
                                                                classes: {
                                                                    root: classes.disabledLabel
                                                                }
                                                            }}
                                                            value={item.value ? item.value : ""}
                                                            variant="outlined"
                                                        />)}

                                            </div>
                                            <div className="mt-4 d_lg_none_sm">
                                                {postCommodityPayload && postCommodityPayload["other_quality_details"] &&
                                                    postCommodityPayload["other_quality_details"]["data"] &&
                                                    postCommodityPayload["other_quality_details"]["data"].length > 0 &&
                                                    postCommodityPayload["other_quality_details"]["data"].map((item, indobj) =>
                                                        <TextField
                                                            key={indobj + "__"}
                                                            id={item.name}
                                                            name={item.name}
                                                            disabled
                                                            error={errorFields[item.name] ? true : false}
                                                            label={item.name + " " + (item.unit ? item.unit : "")}
                                                            style={{
                                                                textTransform: "capitalize",
                                                                marginTop: "10px"
                                                            }}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.customInputDisabled,
                                                                    input: classes.disabledInput
                                                                },
                                                            }}
                                                            fullWidth
                                                            InputLabelProps={{
                                                                classes: {
                                                                    root: classes.disabledLabel
                                                                }
                                                            }}
                                                            value={item.value ? item.value : ""}
                                                            variant="outlined"
                                                        />)}

                                            </div>


                                            <div className="mt-4 mb-5">
                                                {<TextField
                                                    id="other_comment"
                                                    label="Any other remarks"
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInputDisabled,
                                                            input: classes.disabledInput
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: classes.disabledLabel
                                                        }
                                                    }}
                                                    disabled
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    error={errorFields["other_comment"] ? true : false}
                                                    type="text"
                                                    value={postCommodityPayload.other_comment}
                                                    variant="outlined"
                                                />}

                                            </div>

                                            <div className="col-md-12 p-0 mt-4 d575_none" style={{ textAlign: "end" }}>
                                                <input type="submit"
                                                    disabled={!CommonUtil.checkIfKYCverified('buyer') || !CommonUtil.CheckIfUserLoggedIn()['isLoggedIn']}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        console.log(postCommodityPayload);
                                                        this.checkIfBuyerIsTheSeller();
                                                    }}
                                                    className={classes.buttoncss + " mt-5 btn btn-success cus_btn"}
                                                    style={{ background: "#198548", padding: "16px 24px" }} value="SEND OFFER" />
                                                <Tooltip
                                                    classes={{ tooltip: classes.tooltipCls }}
                                                    style={{ cursor: "pointer", height: "50px" }}
                                                    title="Talk to the expert" arrow>
                                                    <img src={whatsappicon}
                                                        className={classes.buttoncss + " ml-3 mt-5 btn cus_btn"}
                                                        onClick={() => CommonUtil.openLinkInNewTab("https://wa.me/918585958484?text=" + encodeURI(`I am interested to know about enquiry no. ${CommonUtil.getFormatedId(postCommodityPayload.sell_id).replace("#","")} - ${postCommodityPayload.name};`))}
                                                        alt="" />
                                                </Tooltip>

                                            </div>
                                            {/* Mobile button */}
                                            <div className="col-12 text-right mt-5 d_lg_none_sm fixed-bottom py-3 bg-white">
                                            {CommonUtil.CheckIfUserLoggedIn()['isLoggedIn'] && !CommonUtil.checkIfKYCverified('buyer') && <div className="inputFrameinputError_txt pb-2 mt-0 text-center" style={{ textAlign: 'end' }}>Your Kyc is pending!</div>}
                                                {this.state.buyerSellerCheckError && <div className="inputFrameinputError_txt pb-2 mt-0 text-center" style={{ textAlign: 'end' }}>You cannot make a Enquiry to your own post</div>}
                                                {!CommonUtil.CheckIfUserLoggedIn()['isLoggedIn'] && <div className="inputFrameinputError_txt pb-2 mt-0 text-center" style={{ textAlign: 'end' }}>Please Login to continue</div>}
                                                <button className="btn btn-success px-4 text-uppercase cus_bg_green w-75 py-3 closeBtn sendOffer_btn"
                                                    disabled={!CommonUtil.checkIfKYCverified('buyer') || !CommonUtil.CheckIfUserLoggedIn()['isLoggedIn']}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        console.log(postCommodityPayload);
                                                        this.checkIfBuyerIsTheSeller();
                                                    }}
                                                >SEND OFFER
                                                </button>
                                                <Tooltip
                                                    classes={{ tooltip: classes.tooltipCls }}
                                                    style={{ cursor: "pointer", height: "58px" }}
                                                    title="Talk to the expert" arrow>
                                                    <img src={whatsappicon}
                                                        className={"pl-2"}
                                                        onClick={() => CommonUtil.openLinkInNewTab("https://wa.me/918585958484?text=" + encodeURI(`I am interested to know about enquiry no. ${CommonUtil.getFormatedId(postCommodityPayload.sell_id).replace("#","")} - ${postCommodityPayload.name};`))}
                                                        alt="" />
                                                </Tooltip>

                                            </div>
                                            {CommonUtil.CheckIfUserLoggedIn()['isLoggedIn'] && !CommonUtil.checkIfKYCverified('buyer') && <div className="inputFrameinputError_txt d575_none" style={{ textAlign: 'end' }}>Your Kyc is pending!</div>}
                                            {!CommonUtil.CheckIfUserLoggedIn()['isLoggedIn'] && <div className="inputFrameinputError_txt d575_none" style={{ textAlign: 'end' }}>Please Login to continue</div>}
                                            {this.state.buyerSellerCheckError && <div className="inputFrameinputError_txt d575_none" style={{ textAlign: 'end' }}>You cannot make a Enquiry to your own post</div>}

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }

            {showBuyerSendEnquiryPage &&
                <section className="sec_bg my_account_section">
                    <div className="container">
                        <div className="cus_sec_width tb_padding" style={{ padding: "40px 15px" }}>
                            <div className="pt-2">
                                <div className="mb-4">
                                    <img src={backtopage} alt="" style={{ cursor: 'pointer' }}
                                        onClick={() => this.setState({ showBuyerSendEnquiryPage: false })} /> </div>
                                <div className="row mb-4">
                                    <div className="col-sm-4 d575_none">
                                        <div className="comm_upload_title"> Commodity Docs </div>
                                        <div className="comm_img_upload_title pt-4 pb-1"> Commodity Image </div>
                                        {postCommodityPayload.commodity_images && postCommodityPayload.commodity_images["data"] &&
                                            postCommodityPayload.commodity_images["data"].length > 0 &&
                                            postCommodityPayload.commodity_images["data"].map((item, img_index) =>
                                                <img key={img_index + "--"}
                                                    onClick={() => CommonUtil.openLinkInNewTab(item.url)}
                                                    className="mt-2 w-100  cursor-pointer" src={item["url"]} alt="" />
                                            )
                                        }

                                        <div className="comm_img_upload_title pt-4 pb-1"> Documents </div>
                                        {postCommodityPayload.other_doc_images && postCommodityPayload.other_doc_images["data"] &&
                                            postCommodityPayload.other_doc_images["data"].length > 0 ?
                                            postCommodityPayload.other_doc_images["data"].map((item, img_indexw) => (
                                                item["fname"].indexOf(".pdf") > -1 ?
                                                    <div className="card flex-row align-items-center px-2 py-3 mt-2 mb-4  cursor-pointer"
                                                        onClick={() => CommonUtil.openLinkInNewTab(item.url)}>
                                                        <div className="col-2 pl-0">
                                                            <img src={pdf_icon} width="35" alt=" " />
                                                        </div>
                                                        <div className="col-10">
                                                            <p className="mb-0">{item["fname"]}</p>
                                                        </div>
                                                    </div> :
                                                    <img key={img_indexw + "--"} className="mt-2 w-100 mb-4  cursor-pointer"
                                                        onClick={() => CommonUtil.openLinkInNewTab(item.url)}
                                                        src={item["url"]} alt="" />)
                                            ) : <div className="no_document">&nbsp;No documents</div>
                                        }
                                    </div>

                                    <div className="col-sm-8 ">
                                        <div className="comm_upload_title cus_sec_width d575_none">Product description</div>
                                        <div className="mobile_commodity_sendoffer-title cus_sec_width d_lg_none_sm">Send Offer to Enquiry</div>
                                        <form className="bootstrap-form needs-validation cus_sec_width " novalidate>

                                            <div key={"poi"} className="card mt-3 d575_none">
                                                <div className="card-body " >
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p>#{postCommodityPayload.sell_id}</p>
                                                        </div>
                                                        <div className="col-3">
                                                            <div>
                                                                {/* <img src={postCommodityPayload.commodity_images} alt="" /> */}
                                                                <img src={postCommodityPayload.commodity_images && postCommodityPayload.commodity_images["data"] && postCommodityPayload.commodity_images["data"][0]["url"]} alt=""
                                                                    style={postCommodityPayload.commodity_images && postCommodityPayload.commodity_images["data"] && postCommodityPayload.commodity_images["data"][0] ?
                                                                        { height: '142px', width: '136px', borderRadius: '50%', border: "2px solid #FFFFFF" } : {}} />
                                                            </div>
                                                        </div>
                                                        <div className="col-9 pl-4">
                                                            <h5>{postCommodityPayload.name}</h5>
                                                            <span className="cus_purple">
                                                                <img src={locationicon} alt="" />&nbsp;{postCommodityPayload.locality ? postCommodityPayload.locality : ""}</span>
                                                            <div className="row pt-4">
                                                                <div className="col-3">
                                                                    <span className="text-muted font-weight-normal">Volume per Month</span>
                                                                    <p className="font-weight-bold">{postCommodityPayload.quantity + " " + postCommodityPayload.quantity_unit}</p>
                                                                </div>
                                                                <div className="col-5">
                                                                    <span className="text-muted font-weight-normal">Price</span>
                                                                    <p className="font-weight-bold">{postCommodityPayload.price_currency + " " + postCommodityPayload.price}/{postCommodityPayload.price_unit}</p>
                                                                </div>
                                                                <div className="col-4">
                                                                    <span className="text-muted font-weight-normal">Expiry date</span>
                                                                    <p className="font-weight-bold cus_light_green">{postCommodityPayload.offer_expiry_date}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="card-body d_lg_none_sm">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p className="mobile_searchId" style={{ marginBottom: '8px' }}>#{postCommodityPayload.sell_id}</p>
                                                        </div>
                                                        <div className="col-3">
                                                            <div>
                                                                <img src={postCommodityPayload.commodity_images && postCommodityPayload.commodity_images["data"] && postCommodityPayload.commodity_images["data"][0]["url"]} alt=""

                                                                    className={postCommodityPayload.commodity_images && postCommodityPayload.commodity_images["data"] && postCommodityPayload.commodity_images["data"][0]["url"] ? "mobileImg_search" : "mobileImg_search_empty"}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-9">
                                                            <h5 style={{ letterSpacing: "0.15px", fontWeight: "bold", color: "#25282B" }}>{postCommodityPayload.commodity_desc ? postCommodityPayload.commodity_desc : postCommodityPayload.name}</h5>
                                                            <span className="cus_purple" style={{ letterSpacing: "0.1px" }}><img src={locationicon} alt="" /> &nbsp;{postCommodityPayload.locality ? postCommodityPayload.locality : ""}</span>
                                                        </div>
                                                        <div className="col-12 pt-3">
                                                            <span className="mobile_volume_search font-weight-normal">Volume : &nbsp;
                                        <span className="font-weight-bold mobile_volume_search_value">{postCommodityPayload.quantity + " " + postCommodityPayload.quantity_unit}</span>
                                                            </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className="text-muted font-weight-normal">Price</span>
                                                            <p className="font-weight-bold">{postCommodityPayload["price_currency"] + " " + (postCommodityPayload.curreny_price).toFixed(2)}/{postCommodityPayload.price_unit}</p>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className="text-muted font-weight-normal">Expiry Date</span>
                                                            <p className="font-weight-bold cus_light_green">{postCommodityPayload.offer_expiry_date}</p>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>

                                            <div className="mt-4 postEnquiry_title">Make Your Offer</div>
                                            <div className="mt-4" style={{ display: "flex" }}>
                                                <TextField
                                                    id="buyer_quantity"
                                                    error={errorFields["buyer_quantity"] ? true : false}
                                                    helperText={errorFields["buyer_quantity"] ? "This detail cannot be empty" : ''}
                                                    type="number"
                                                    label="Quantity requirement"
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInput,
                                                        },
                                                        inputProps: { min: 0 }
                                                    }}
                                                    fullWidth
                                                    // style={{ width: '60%' }}
                                                    value={buyerQuery.buyer_quantity}
                                                    onChange={this.handleInputChange.bind(this)}
                                                    variant="outlined"
                                                /> &nbsp;&nbsp;
                                            <TextField
                                                    select
                                                    id="buyer_unit"
                                                    name="buyer_unit"
                                                    label="Unit"
                                                    error={errorFields["buyer_unit"] ? true : false}
                                                    helperText={errorFields["buyer_unit"] ? "This detail cannot be empty" : ''}
                                                    type="text"
                                                    // label="Commodity Description"
                                                    className="d575_none"
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInput,
                                                        },
                                                    }}
                                                    fullWidth
                                                    value={buyerQuery.buyer_unit}
                                                    onChange={this.handleInputChange.bind(this)}
                                                    style={{ width: '39%' }}

                                                    // helperText="Some important text"
                                                    variant="outlined"
                                                >
                                                    {["Kg", "Quintal", "MT"].map((key, i) => (
                                                        <MenuItem key={i} value={key} selected={true}>
                                                            {key}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </div>

                                            <div className="free_search mt-1 d_lg_none_sm">
                                                <div className="radio_toolbar" id="buyer_unit" style={{ margin: "0px" }}>
                                                    <input type="radio" id="Kg" name="Kg" value={"Kg"}
                                                        // style={{ color: postCommodityPayload.payment_type === "L/C" ? "#25282B" : "#FFFFFF" }}
                                                        checked={buyerQuery.buyer_unit === "Kg"}
                                                    />
                                                    <label htmlFor="Kg" className="radio_ps"
                                                        style={{
                                                            background: buyerQuery.buyer_unit === "Kg" ? "#247BC7" : "#FFFFFF",
                                                            color: buyerQuery.buyer_unit !== "Kg" ? "#25282B" : "#FFFFFF"
                                                        }}
                                                        onClick={(ev) => this.handleInputRadioChange(ev, "Kg", "buyer_unit")} >Kg</label>

                                                    <input type="radio" id="Quintal" name="Quintal"
                                                        // style={{ color: buyerQuery.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                        value="Quintal"
                                                        checked={buyerQuery.buyer_unit === "Quintal"}
                                                    />
                                                    <label htmlFor="Quintal" className="radio_ps"
                                                        style={{
                                                            marginLeft: "10px",
                                                            background: buyerQuery.buyer_unit === "Quintal" ? "#247BC7" : "#FFFFFF",
                                                            color: buyerQuery.buyer_unit !== "Quintal" ? "#25282B" : "#FFFFFF"
                                                        }} onClick={(ev) => this.handleInputRadioChange(ev, "Quintal", "buyer_unit")}>Quintal</label>

                                                    <input type="radio" id="MT" name="MT"
                                                        // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                        value="MT"
                                                        checked={buyerQuery.buyer_unit === "MT"}
                                                    />
                                                    <label htmlFor="MT" className="radio_ps"
                                                        style={{
                                                            marginLeft: "10px",
                                                            background: buyerQuery.buyer_unit === "MT" ? "#247BC7" : "#FFFFFF",
                                                            color: buyerQuery.buyer_unit !== "MT" ? "#25282B" : "#FFFFFF"
                                                        }} onClick={(ev) => this.handleInputRadioChange(ev, "MT", "buyer_unit")}>MT</label>
                                                </div>
                                                {errorFields["buyer_unit"] ? <div className="inputFrameinputError_txt">Please select an option</div> : ''}
                                            </div>
                                            <div className="mt-4 d575_none" style={{ display: "flex" }}>
                                                <TextField
                                                    id="buyer_price"
                                                    error={errorFields["buyer_price"] ? true : false}
                                                    helperText={errorFields["buyer_price"] ? "This detail cannot be empty" : ''}
                                                    name="price"
                                                    label="Quote your FOB price"
                                                    type="number"

                                                    value={buyerQuery.buyer_price}
                                                    onChange={this.handleInputChange.bind(this)}
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInput,
                                                        },
                                                        inputProps: { min: 0 }
                                                    }}
                                                    style={{ width: '50%' }}
                                                    // helperText="Some important text"
                                                    variant="outlined"
                                                />
                                                 &nbsp;
                                            &nbsp;
                                            <TextField
                                                    select
                                                    id="buyer_currency"
                                                    name="buyer_currency"
                                                    label="Currency"
                                                    error={errorFields["buyer_currency"] ? true : false}
                                                    helperText={errorFields["buyer_currency"] ? "This detail cannot be empty" : ''}
                                                    type="text"
                                                    style={{ width: '22%' }}
                                                    value={buyerQuery.buyer_currency}
                                                    onChange={this.handleInputChange.bind(this)}

                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInput,
                                                        },
                                                    }}
                                                    variant="outlined"
                                                >
                                                    {["INR", "USD", "AED"].map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            &nbsp;
                                            <div className="per_text">
                                                    Per
                                            </div>
                                            &nbsp;
                                            <TextField
                                                    select
                                                    id="buyer_price_unit"
                                                    name="buyer_price_unit"
                                                    label="Price unit"
                                                    error={errorFields["buyer_price_unit"] ? true : false}
                                                    helperText={errorFields["buyer_price_unit"] ? "This detail cannot be empty" : ''}
                                                    type="text"
                                                    style={{ width: '22%' }}
                                                    value={buyerQuery.buyer_price_unit}
                                                    onChange={this.handleInputChange.bind(this)}

                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInput,
                                                        },
                                                    }}
                                                    variant="outlined"
                                                >
                                                    {["Kg", "Quintal", "MT"].map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>


                                            </div>
                                            <div className="mt-4 d_lg_none_sm" style={{ display: "flex" }}>
                                                <TextField
                                                    id="buyer_price"
                                                    error={errorFields["buyer_price"] ? true : false}
                                                    helperText={errorFields["buyer_price"] ? "This detail cannot be empty" : ''}
                                                    name="price"
                                                    label="Quote your FOB price"
                                                    type="number"

                                                    value={buyerQuery.buyer_price}
                                                    onChange={this.handleInputChange.bind(this)}
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInput,
                                                        },
                                                        inputProps: { min: 0 }
                                                    }}
                                                    // style={{ width: '59%' }}
                                                    fullWidth
                                                    // helperText="Some important text"
                                                    variant="outlined"
                                                />
                                            </div>


                                            <div className="free_search mt-1 d_lg_none_sm">
                                                <div className="radio_toolbar" id="buyer_currency" style={{ margin: "0px" }}>
                                                    <input type="radio" id="INR" name="INR" value={"INR"}
                                                        // style={{ color: postCommodityPayload.payment_type === "L/C" ? "#25282B" : "#FFFFFF" }}
                                                        checked={buyerQuery.buyer_currency === "INR"}
                                                    />
                                                    <label htmlFor="INR" className="radio_ps"
                                                        style={{
                                                            background: buyerQuery.buyer_currency === "INR" ? "#247BC7" : "#FFFFFF",
                                                            color: buyerQuery.buyer_currency !== "INR" ? "#25282B" : "#FFFFFF"
                                                        }}
                                                        onClick={(ev) => this.handleInputRadioChange(ev, "INR", "buyer_currency")} >INR</label>

                                                    <input type="radio" id="USD" name="USD"
                                                        // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                        value="USD"
                                                        checked={buyerQuery.buyer_currency === "USD"}
                                                    />
                                                    <label htmlFor="USD" className="radio_ps"
                                                        style={{
                                                            marginLeft: "10px",
                                                            background: buyerQuery.buyer_currency === "USD" ? "#247BC7" : "#FFFFFF",
                                                            color: buyerQuery.buyer_currency !== "USD" ? "#25282B" : "#FFFFFF"
                                                        }} onClick={(ev) => this.handleInputRadioChange(ev, "USD", "buyer_currency")}>USD</label>

                                                    <input type="radio" id="AED" name="AED"
                                                        // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                        value="AED"
                                                        checked={buyerQuery.buyer_currency === "AED"}
                                                    />
                                                    <label htmlFor="AED" className="radio_ps"
                                                        style={{
                                                            marginLeft: "10px",
                                                            background: buyerQuery.buyer_currency === "AED" ? "#247BC7" : "#FFFFFF",
                                                            color: buyerQuery.buyer_currency !== "AED" ? "#25282B" : "#FFFFFF"
                                                        }} onClick={(ev) => this.handleInputRadioChange(ev, "AED", "buyer_currency")}>AED</label>
                                                </div>
                                                {errorFields["buyer_currency"] ? <div className="inputFrameinputError_txt">Please select an option</div> : ''}
                                            </div>

                                            <div className="per_text text-center d_lg_none_sm" style={{ lineHeight: 2 }}>Per</div>

                                            <div className="free_search mt-1 d_lg_none_sm">
                                                <div className="radio_toolbar" id="buyer_price_unit" style={{ margin: "0px" }}>
                                                    <input type="radio" id="Kg" name="Kg" value={"Kg"}
                                                        // style={{ color: postCommodityPayload.payment_type === "L/C" ? "#25282B" : "#FFFFFF" }}
                                                        checked={buyerQuery.buyer_price_unit === "Kg"}
                                                    />
                                                    <label htmlFor="Kg" className="radio_ps"
                                                        style={{
                                                            background: buyerQuery.buyer_price_unit === "Kg" ? "#247BC7" : "#FFFFFF",
                                                            color: buyerQuery.buyer_price_unit !== "Kg" ? "#25282B" : "#FFFFFF"
                                                        }}
                                                        onClick={(ev) => this.handleInputRadioChange(ev, "Kg", "buyer_price_unit")} >Kg</label>

                                                    <input type="radio" id="Quintal" name="Quintal"
                                                        // style={{ color: buyerQuery.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                        value="Quintal"
                                                        checked={buyerQuery.buyer_price_unit === "Quintal"}
                                                    />
                                                    <label htmlFor="Quintal" className="radio_ps"
                                                        style={{
                                                            marginLeft: "10px",
                                                            background: buyerQuery.buyer_price_unit === "Quintal" ? "#247BC7" : "#FFFFFF",
                                                            color: buyerQuery.buyer_price_unit !== "Quintal" ? "#25282B" : "#FFFFFF"
                                                        }} onClick={(ev) => this.handleInputRadioChange(ev, "Quintal", "buyer_price_unit")}>Quintal</label>

                                                    <input type="radio" id="MT" name="MT"
                                                        // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                        value="MT"
                                                        checked={buyerQuery.buyer_price_unit === "MT"}
                                                    />
                                                    <label htmlFor="MT" className="radio_ps"
                                                        style={{
                                                            marginLeft: "10px",
                                                            background: buyerQuery.buyer_price_unit === "MT" ? "#247BC7" : "#FFFFFF",
                                                            color: buyerQuery.buyer_price_unit !== "MT" ? "#25282B" : "#FFFFFF"
                                                        }} onClick={(ev) => this.handleInputRadioChange(ev, "MT", "buyer_price_unit")}>MT</label>
                                                </div>
                                                {errorFields["buyer_price_unit"] ? <div className="inputFrameinputError_txt">Please select an option</div> : ''}
                                            </div>



                                            <div className="mt-4 postEnquiry_title">Select shipment preference</div>
                                            <div className="free_search mt-3 pb-2">
                                                <div className="radio_toolbar">
                                                    <input type="radio" id="CIF" name="CIF" value={"CIF"}
                                                        checked={buyerQuery.buyer_shipment === "CIF"}
                                                    />
                                                    <label for="CIF" className="radio_ps"
                                                        style={{
                                                            background: buyerQuery.buyer_shipment === "CIF" ? "#247BC7" : "#FFFFFF",
                                                            color: buyerQuery.buyer_shipment !== "CIF" ? "#25282B" : "#FFFFFF"
                                                        }}
                                                        onClick={(ev) => this.handleInputRadioChange(ev, "CIF", "buyer_shipment")}
                                                    >CIF</label>

                                                    <input type="radio" id="FOB" name="FOB"
                                                        value="FOB"
                                                        checked={buyerQuery.buyer_shipment === "FOB"}
                                                    />
                                                    <label for="FOB" className="radio_ps"
                                                        style={{
                                                            marginLeft: "10px",
                                                            background: buyerQuery.buyer_shipment === "FOB" ? "#247BC7" : "#FFFFFF",
                                                            color: buyerQuery.buyer_shipment !== "FOB" ? "#25282B" : "#FFFFFF"
                                                        }}
                                                        onClick={(ev) => this.handleInputRadioChange(ev, "FOB", "buyer_shipment")}
                                                    >FOB</label>
                                                </div>
                                                {errorFields["buyer_shipment"] ? <div className="inputFrameinputError_txt">Please select an option</div> : ''}
                                            </div>





                                            <div className="mt-4 postEnquiry_title">Payment Preference
                                            &nbsp;&nbsp;
                                                <Tooltip
                                                    open={this.state.showTooltip}
                                                    classes={{ tooltip: classes.tooltipCls }}
                                                    title="Please choose one of L/C or Advance Payment as your preferred payment mode. If Advance Payment is your preferred payment mode, mention the portion of total amount (%) that you would like to receive in advance." arrow>
                                                    <img src={info_icon}
                                                        onMouseOver={() => this.setState({ showTooltip: !this.state.showTooltip })}
                                                        onMouseOut={() => this.setState({ showTooltip: !this.state.showTooltip })}
                                                        onClick={() => this.setState({ showTooltip: !this.state.showTooltip })}
                                                        alt="" />
                                                </Tooltip>
                                            </div>
                                            {/* <div className="mt-4">
                                                <TextField
                                                    select
                                                    id="buyer_payment_type"
                                                    name="buyer_payment_type"
                                                    label="Buyer payment type"
                                                    error={errorFields["buyer_payment_type"] ? true : false}
                                                    helperText={errorFields["buyer_payment_type"] ? "This detail cannot be empty" : ''}
                                                    type="text"
                                                    style={{ width: '50%' }}
                                                    value={buyerQuery.buyer_payment_type}
                                                    onChange={this.handleInputChange.bind(this)}

                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInput,
                                                        },
                                                    }}
                                                    variant="outlined"
                                                >
                                                    {["L/C", "Advance payment"].map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </div> */}

                                            <div className="free_search mt-4">
                                                <div className="radio_toolbar">
                                                    <input type="radio" id="L/C" name="L/C" value={"L/C"}
                                                        checked={buyerQuery.buyer_payment_type === "L/C"}
                                                    />
                                                    <label for="L/C" className="radio_ps"
                                                        style={{
                                                            background: buyerQuery.buyer_payment_type === "L/C" ? "#247BC7" : "#FFFFFF",
                                                            color: buyerQuery.buyer_payment_type !== "L/C" ? "#25282B" : "#FFFFFF"
                                                        }}
                                                        onClick={(ev) => this.handleInputRadioChange(ev, "L/C", "buyer_payment_type")}
                                                    >L/c</label>

                                                    <input type="radio" id="Advance payment" name="Advance payment"
                                                        // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                        value="Advance payment"
                                                        checked={buyerQuery.buyer_payment_type === "Advance payment"}
                                                    />
                                                    <label for="Advance payment" className="radio_ps"
                                                        style={{
                                                            marginLeft: "10px",
                                                            background: buyerQuery.buyer_payment_type === "Advance payment" ? "#247BC7" : "#FFFFFF",
                                                            color: buyerQuery.buyer_payment_type !== "Advance payment" ? "#25282B" : "#FFFFFF"
                                                        }}
                                                        onClick={(ev) => this.handleInputRadioChange(ev, "Advance payment", "buyer_payment_type")}
                                                    >Advance Payment</label>
                                                </div>
                                                {errorFields["buyer_payment_type"] ? <div className="inputFrameinputError_txt">Please select an option</div> : ''}
                                            </div>

                                            {buyerQuery.buyer_payment_type && buyerQuery.buyer_payment_type === "Advance payment" &&
                                                <div className="mt-3 d575_none" style={{ display: "flex" }}>
                                                    <TextField
                                                        id="buyer_advance_percentage"
                                                        error={errorFields["buyer_advance_percentage"] ? true : false}
                                                        helperText={errorFields["buyer_advance_percentage"] ? "This detail cannot be empty" : ''}
                                                        name="price"
                                                        label="Advance payment percentage"
                                                        type="number"

                                                        value={buyerQuery.buyer_advance_percentage}
                                                        onChange={this.handleInputChange.bind(this)}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.customInput,
                                                            },
                                                            inputProps: { min: 1, max: 100 }
                                                        }}
                                                        style={{ width: '59%' }}
                                                        // helperText="Some important text"
                                                        variant="outlined"
                                                    />
                                                </div>}
                                            {buyerQuery.buyer_payment_type && buyerQuery.buyer_payment_type === "Advance payment" &&
                                                <div className="mt-3 d_lg_none_sm" style={{ display: "flex" }}>
                                                    <TextField
                                                        id="buyer_advance_percentage"
                                                        error={errorFields["buyer_advance_percentage"] ? true : false}
                                                        helperText={errorFields["buyer_advance_percentage"] ? "This detail cannot be empty" : ''}
                                                        name="price"
                                                        label="Advance payment percentage"
                                                        type="number"
                                                        value={buyerQuery.buyer_advance_percentage}
                                                        onChange={this.handleInputChange.bind(this)}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.customInput,
                                                            },
                                                            inputProps: { min: 1, max: 100 }
                                                        }}
                                                        // style={{ width: '59%' }}
                                                        fullWidth
                                                        // helperText="Some important text"
                                                        variant="outlined"
                                                    />
                                                </div>}


                                            <div className="mt-4 mb-5">
                                                {<TextField
                                                    id="buyer_comments"
                                                    label="Any other comments"
                                                    placeholder="Any other comments (For example, packaging preferences, request for samples, etc.)"
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInput,
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        classes: {
                                                            outlined: classes.paddingLabel
                                                        },
                                                    }}
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    // helperText="Some important text"
                                                    error={errorFields["buyer_comments"] ? true : false}
                                                    helperText={errorFields["buyer_comments"] ? "This detail cannot be empty" : ''}
                                                    type="text"

                                                    value={buyerQuery.buyer_comments}
                                                    onChange={this.handleInputChange.bind(this)}
                                                    variant="outlined"
                                                />}
                                            </div>
                                            {/* <div className="col-md-12 p-0 mt-4 " style={{ textAlign: "end" }}>
                                            <input type="submit"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    console.log(postCommodityPayload);
                                                    this.postCommodityToPortal();
                                                }}
                                                className={classes.buttoncss + " mt-5 btn btn-success cus_btn"}
                                                style={{ background: "#198548", padding: "16px 24px" }} value="Post Enquiry" />
                                        </div> */}
                                            <div className="col-md-12 p-0 mt-4 " style={{ textAlign: "end" }}>
                                                <input type="submit"
                                                    disabled={loading}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        console.log(buyerQuery);
                                                        // this.postCommodityToPortal();
                                                        this.setState({ loading: true }, () => {
                                                            this.sendEnquiry();
                                                        })
                                                    }}
                                                    className={classes.buttoncss + " mt-5 btn btn-success cus_btn d575_none"}
                                                    style={{ background: "#198548", padding: "16px 24px" }} value="Send Offer" />
                                                <Tooltip
                                                    classes={{ tooltip: classes.tooltipCls }}
                                                    style={{ cursor: "pointer", height: "50px" }}
                                                    title="Talk to the expert" arrow>
                                                    <img src={whatsappicon}
                                                        className={classes.buttoncss + " ml-3 mt-5 btn cus_btn d575_none"}
                                                        onClick={() => CommonUtil.openLinkInNewTab("https://wa.me/918585958484?text=" + encodeURI(`I am interested to know about enquiry no. ${CommonUtil.getFormatedId(postCommodityPayload.sell_id).replace("#","")} - ${postCommodityPayload.name};`))}
                                                        alt="" />
                                                </Tooltip>

                                                {/* Mobile button */}
                                                <div className="col-12 text-right mt-5 d_lg_none_sm fixed-bottom py-3 bg-white">
                                                    {this.state.sendEnquiryErrorMsg !== "" && <div className="inputFrameinputError_txt pb-2 mt-0" style={{ textAlign: 'end' }}>{this.state.sendEnquiryErrorMsg}</div>}
                                                    <button className="btn btn-success px-4 text-uppercase cus_bg_green w-75 py-3 closeBtn sendOffer_btn"
                                                        disabled={loading}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            console.log(buyerQuery);
                                                            // this.postCommodityToPortal();
                                                            this.setState({ loading: true }, () => {
                                                                this.sendEnquiry();
                                                            })
                                                        }}
                                                    >SEND OFFER
                                                </button>
                                                    <Tooltip
                                                        classes={{ tooltip: classes.tooltipCls }}
                                                        style={{ cursor: "pointer", height: "58px" }}
                                                        title="Talk to the expert" arrow>
                                                        <img src={whatsappicon}
                                                            className={"pl-2"}
                                                            onClick={() => CommonUtil.openLinkInNewTab("https://wa.me/918585958484?text=" + encodeURI(`I am interested to know about enquiry no. ${CommonUtil.getFormatedId(postCommodityPayload.sell_id).replace("#","")} - ${postCommodityPayload.name};`))}
                                                            alt="" />
                                                    </Tooltip>
                                                </div>
                                                {this.state.sendEnquiryErrorMsg !== "" && <div className="inputFrameinputError_txt" style={{ textAlign: 'end' }}>{this.state.sendEnquiryErrorMsg}</div>}
                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
            {/* <PopUpView
                modalText={this.state.popUpMessage}
                popUpType={this.state.popUpType}
                open={this.state.openModal}
                onPopUpViewClose={() => this.cloasePopUp()} /> */}
        </div>
        );
    }
}

export default withStyles(styles)(withRouter(ProductDetailsContainer))