import React from 'react';
import { Auth, Hub } from 'aws-amplify';
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from 'react-router-dom';
import login_img from '../../assests/img/login.jpg';
import login_sm from '../../assests/icons/login_img_sm.png';
import google_icn from '../../assests/icons/google.svg';
import black_callimg from '../../assests/icons/black_call.svg';
import { getListOfCountries, checkIfEmailOrPhoneAlreadyExists } from '../../services/commonService';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttoncss: {
    width: '50%',
    borderRadius: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "#32ad79",
    color: "#fff",
    border: "none",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: 'Lato, sans-serif',
    textTransform: "uppercase",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontStretch: "normal",
    fontStyle: "normal",
    marginRight: "10px"
  },
  customInput: {
    width: "100%",
    background: "#fff"
  }
});

class LoginContainer extends React.Component {

  state = { user: null, customState: null };
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      loading: false,
      verifyCode: '',
      newPassword: '',
      showChallengeNewPasswordView: false,
      user: null,
      settings: null,
      code: '',
      challengeName: "login",
      isForgotPasswordViewActive: false,
      logoUrl: undefined,
      clientName: "",

      attributeType: '',
      attributeValue: '',
      showAttributeVericationScreen: false,
      showAttributeVericationScreenCode: false,
      attributeVerificationCode: '',
      errorFields: {},
      showLoginErrorMessage: false,
      emailValidation: {
        errorMsg: "",
        showErr: false,
        showSuccess: false
      },
      showLoginWithMobileView: false,
      countryListDataObj: {},
      showCountryList: false,
      selectedCountry: {
        createdtime: "2021-01-24T16:29:45.197Z",
        id: 95,
        is_enabled: true,
        mob_code: "91",
        name: "India",
        name_code: "IN",
        updatedtime: "2021-01-24T16:29:45.197Z"
      },
      phoneValidationError: {
        showError: false,
        errMsg: ""
      },
      preExistingDataCheck: {
        mobile: false,
        email: false
      },
      phone_otp: "",
      phoneLoginUserData: undefined,
      isPhoneOtpSent: false,
      phoneOtpEmail: "",
      isOtpLoading: false,
      phone_otpError: {
        showError: false,
        errMsg: ""
      }
    }

    this.handleSigninSubmit = this.handleSigninSubmit.bind(this)

  }
  componentDidMount() {
    this.getCountryList();
  }
  async getCountryList(params) {
    try {
      let resp = await getListOfCountries(params);
      console.log(resp);
      let cArray = [];
      let cObj = {
        "IN": {
          createdtime: "2021-01-24T16:29:45.197Z",
          id: 95,
          is_enabled: true,
          mob_code: "91",
          name: "India",
          name_code: "IN",
          updatedtime: "2021-01-24T16:29:45.197Z"
        }
      };
      if (resp && resp.type === 1 && resp.data && resp.data.length > 0) {
        for (let i = 0; i < resp.data.length; i++) {
          if (resp.data[i]["is_enabled"]) {
            cArray.push(resp.data[i]["name_code"].toLowerCase());
            cObj[resp.data[i]["name_code"]] = resp.data[i];
          }
        }
      } else {
        cArray = ['in'];
      }

      this.setState({ countryList: cArray, countryListData: resp.data, countryListDataObj: cObj }, () => {
        // this.initaiteCountryDropDown(cArray);
      })

    } catch (error) {
      console.log(error);
    }
  }

  checkIfValidEmail(emailId) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId)) //eslint-disable-line
    {
      return true;
    }
    return false;
  }


  checkIfFormIsValid() {
    let errorFieldsVal = {};
    let isValid = true;

    if (!this.state.password || this.state.password.trim() === "") {
      errorFieldsVal["password"] = true;
      isValid = false;
    }
    if (!this.state.username || this.state.username.trim() === "") {
      errorFieldsVal["username"] = true;
      isValid = false;
    } else {
      if (this.state.username.trim() !== "" && !this.checkIfValidEmail(this.state.username)) {
        this.setState({
          emailValidation: {
            errorMsg: "Please Enter a vaild Email Address",
            showErr: true,
            showSuccess: false
          }
        })
        isValid = false;
      }
    }


    this.setState({ errorFields: errorFieldsVal })
    return isValid;

  }


  async handleSigninSubmit(e) {

    e.preventDefault();

    if (!this.checkIfFormIsValid()) {
      return;
    }
    this.setState({ loading: true });

    //console.log('Entered:', this.state)
    try {
      var data = await Auth.signIn(this.state.username, this.state.password);
      var response = data
      //console.log("Logged in" + JSON.stringify(response));
      if (response && response.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.state.challengeName = response.challengeName;
        this.state.showChallengeNewPasswordView = true;
        this.state.user = response;
        this.state.loading = false;
        this.setState(this.state);
        return;
      }
      if (response && response.challengeName === 'SMS_MFA') {
        this.state.challengeName = response.challengeName;
        this.state.showChallengeNewPasswordView = true;
        this.state.user = response;
        this.state.loading = false;
        this.setState(this.state);
        return;
      }
      if (response) {
        this.setState({
          user: response,
          loading: false
        })
        Auth.currentAuthenticatedUser().then((user) => {
          console.log(JSON.stringify(user));
          console.log(user);
          console.log('user email = ' + user.attributes.email);
          window.localStorage.setItem('loggedInUserName', user.attributes.name);
          // window.sessionStorage.setItem('is_user_loggedIn', "true");

          // const user = await Auth.currentAuthenticatedUser();
          // const result = await Auth.updateUserAttributes(user, {
          //   'phone_number': '+918826536055'
          // });
          // console.log(result)
          // this.setState({ challengeName: "otp" })
          var authk = data.signInUserSession.idToken.jwtToken;
          window.localStorage.setItem('bijakGlobalToken', authk);
          window.localStorage.setItem('is_user_loggedIn', "true");
          window.localStorage.setItem("bijakGlobalExpTime", (new Date()).getTime());
          this.props.history.push(`/home`);
          // window.location.reload()
        });
        // this.redirectToDashboard(data);
      }
    } catch (e) {
      if (e && e.message && e.message.indexOf("Incorrect username or password")) {
        this.setState({ showLoginErrorMessage: true });
      } else {
        this.setState({ showLoginErrorMessage: true });
      }
      this.setState({ loading: false });
    }
  }

  redirectToDashboard(data) {
    this.props.history.push(`/home`);
  }

  handelInputChange(event) {
    event.preventDefault();
    try {
      let err = this.state.errorFields;
      let id = event.target.id;
      let val = event.target.value;
      console.log(id)
      err[id] = false;
      this.setState({
        [id]: val,
        errorFields: err,
        showLoginErrorMessage: false,
        emailValidation: {
          errorMsg: "",
          showErr: false,
          showSuccess: false
        },
        preExistingDataCheck: {
          mobile: false,
          email: false
        },
        phone_otpError: {
          showError: false,
          errMsg: ""
        },
        phoneValidationError: {
          showError: false,
          errMsg: ""
        }

      })
    } catch (err) {
      console.log(err);
    }
  }

  async sendOtpToLoginViaPhone(e) {

    try {
      // e.preventDefault();
      let pn = this.state.phoneOtpEmail;
      console.log(pn)
      const cognitoUser = await Auth.signIn(pn);
      this.setState({ phoneLoginUserData: cognitoUser, isPhoneOtpSent: true, isOtpLoading: false })
      console.log(cognitoUser);
    } catch (err) {
      // Handle sign in errors 
      this.setState({ phoneLoginUserData: undefined, isPhoneOtpSent: false, isOtpLoading: false })
      console.log(err)
      alert(err.message)
    }
  }

  onCountryChange(event, obj) {
    try {
      event.preventDefault();
      let val = event.target.value;
      this.setState({ selectedCountry: obj, showCountryList: false })
    } catch (er) {
      console.log(er)
    }
  }

  async verifyPhoneLoginOtop(e) {
    e.preventDefault();
    try {
      if (!this.state.phone_otp || this.state.phone_otp.length === "" || this.state.phone_otp.length === 0) {
        this.setState({
          phone_otpError: {
            showError: true,
            errMsg: "This detail cannot be empty"
          }
        })
        return;
      } else if (this.state.phone_otp && this.state.phone_otp.length > 7) {
        this.setState({
          phone_otpError: {
            showError: true,
            errMsg: "Invalid otp"
          }
        })
        return;
      }
      const cognitoUser = await Auth.sendCustomChallengeAnswer(this.state.phoneLoginUserData, this.state.phone_otp);
      console.log(cognitoUser);
      Auth.currentAuthenticatedUser().then((user) => {
        console.log(JSON.stringify(user));
        console.log(user);
        console.log('user email = ' + user.attributes.email);
        window.localStorage.setItem('loggedInUserName', user.attributes.name);
        var authk = cognitoUser.signInUserSession.idToken.jwtToken;
        window.localStorage.setItem('bijakGlobalToken', authk);
        window.localStorage.setItem('is_user_loggedIn', "true");
        window.localStorage.setItem("bijakGlobalExpTime", (new Date()).getTime());
        this.props.history.push(`/home`);
        // window.location.reload()
      }).catch((err) => {
        console.log(err);
        this.setState({
          phone_otpError: {
            showError: true,
            errMsg: "Invalid otp"
          }
        })
      });
    } catch (err) {
      console.log(err);
      this.setState({
        phone_otpError: {
          showError: true,
          errMsg: "Invalid otp"
        }
      })
    }
  }



  async checkIfEmailOrMobileAlreadyexists(params) {
    try {
      if (!params.mobile) {
        this.setState({
          phoneValidationError: {
            showError: true,
            errMsg: "This detail cannot be empty"
          }
        });
        return;
      }
      if (params.type === "mobile" && params.mobile.length === 0) {
        this.setState({
          phoneValidationError: {
            showError: true,
            errMsg: "This detail cannot be empty"
          }
        });
        return;
      }

      if (params.type === "mobile") {
        params.mob_country_code = this.state.selectedCountry["mob_code"];
        params.mobileotp = true;
      }
      this.setState({ isOtpLoading: true });

      let resp = await checkIfEmailOrPhoneAlreadyExists(params);
      console.log(resp);
      let emailIdForOtp = "";
      let preExistingDataCheckVal = this.state.preExistingDataCheck;
      if (resp && resp.type === 1 && resp.data) {
        emailIdForOtp = resp.data.email;
        preExistingDataCheckVal[params.type] = false;
        this.setState({
          phoneOtpEmail: emailIdForOtp,
          preExistingDataCheck: preExistingDataCheckVal
        }, () => {
          this.sendOtpToLoginViaPhone(this);
        });

      } else {
        preExistingDataCheckVal[params.type] = true;
        this.setState({
          preExistingDataCheck: preExistingDataCheckVal,
          phoneOtpEmail: emailIdForOtp,
          isOtpLoading: false
        });
      }

    } catch (error) {
      console.log(error);
    }
  }

  resetValueOnViewChange() {
    this.setState({
      username: '',
      password: '',
      loading: false,
      verifyCode: '',
      newPassword: '',
      showChallengeNewPasswordView: false,
      user: null,
      settings: null,
      code: '',
      challengeName: "login",
      isForgotPasswordViewActive: false,
      logoUrl: undefined,
      clientName: "",

      attributeType: '',
      attributeValue: '',
      showAttributeVericationScreen: false,
      showAttributeVericationScreenCode: false,
      attributeVerificationCode: '',
      errorFields: {},
      showLoginErrorMessage: false,
      emailValidation: {
        errorMsg: "",
        showErr: false,
        showSuccess: false
      },
      countryListDataObj: {},
      showCountryList: false,
      selectedCountry: {
        createdtime: "2021-01-24T16:29:45.197Z",
        id: 95,
        is_enabled: true,
        mob_code: "91",
        name: "India",
        name_code: "IN",
        updatedtime: "2021-01-24T16:29:45.197Z"
      },
      phoneValidationError: {
        showError: false,
        errMsg: ""
      },
      preExistingDataCheck: {
        mobile: false,
        email: false
      },
      phone_otp: "",
      phoneLoginUserData: undefined,
      isPhoneOtpSent: false,
      phoneOtpEmail: "",
      isOtpLoading: false,
      phone_otpError: {
        showError: false,
        errMsg: ""
      }
    });
  }

  render() {
    const { classes } = this.props;
    const { username, password, errorFields, loading, emailValidation, showLoginWithMobileView,
      selectedCountry, countryListDataObj, showCountryList, phone_number,
      preExistingDataCheck, phoneValidationError, phone_otp, isPhoneOtpSent, isOtpLoading, phone_otpError } = this.state;
    return (<>
      <section className="login_form">
        <div className="d-block d-sm-none">
          <div className="img_sm"> <img src={login_sm} alt="" width="100%"></img></div>
        </div>
        <div className="container cus_mb_120_sm">
          <form className="bootstrap-form needs-validation cus_sec_width section_padding" noValidate >

            <div className="row">
              {!showLoginWithMobileView ?
                <div className="col-sm-6">
                  <h2 className="login_text">Log In</h2>
                  <div className="form-row pt-5">
                    <div className="col-12">
                      <TextField
                        id="username"
                        label="Email Address"
                        fullWidth
                        value={username}
                        name="username"
                        InputProps={{
                          classes: {
                            root: classes.customInput,
                          },
                        }}
                        error={errorFields["username"] ? true : false}
                        placeholder="Email Address"
                        onChange={(event) => this.handelInputChange(event)}
                        variant="outlined"
                      >
                      </TextField>
                      <div className={"invalid-feedback " + (errorFields["username"] ? 'show_div' : '')}>This details cannot be empty</div>
                      <div className={"invalid-feedback " + (emailValidation["showErr"] ? 'show_div' : '')}>{emailValidation["errorMsg"]}</div>
                    </div>
                    <div className="col-12 mt-5">
                      <TextField
                        id="password"
                        label="Password"
                        fullWidth
                        value={password}
                        name="username"
                        InputProps={{
                          classes: {
                            root: classes.customInput,
                          },
                        }}
                        type={"password"}
                        error={errorFields["password"] ? true : false}
                        placeholder="Password"
                        onChange={(event) => this.handelInputChange(event)}
                        variant="outlined"
                      >
                      </TextField>
                      <div className={"invalid-feedback " + (errorFields["password"] ? 'show_div' : '')}>This details cannot be empty</div>
                    </div>
                  </div>
                  {this.state.showLoginErrorMessage &&
                    <div className="pt-4">
                      <span className="d-inline-block text-danger" style={{ fontSize: '14px' }}>Incorrect username or password</span>
                    </div>}

                  <div className="pt-4">
                    <span className="d-inline-block forget_password_txt">Forgot Password? <a
                      href="#" className="cus_purple font_reset"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/forgotpassword");
                      }}
                    >Reset it here</a></span>
                  </div>

                  <div className="text-right mt-4 d575_none">
                    <button className="btn btn-success px-4 text-uppercase cus_bg_green"
                      // type="submit"
                      disabled={loading}
                      onClick={(event) => { if (!loading) { this.handleSigninSubmit(event) } }}
                    >Login {loading && <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>}</button>
                  </div>
                  <div className="text-center mt-5 forget_password_txt" style={{ fontSize: "16px" }}>or</div>
                  <div>
                    <button className="btn px-4 text-uppercase google_btn w-100 mt-5"
                      disabled={loading}
                      onClick={(event) => { event.preventDefault(); this.setState({ showLoginWithMobileView: true }) }}
                    ><img src={black_callimg} alt="" className="pr-2" />Login Via Phone Number</button>
                  </div>

                  <div>
                    <button className="btn px-4 text-uppercase google_btn w-100 mt-4"
                      disabled={loading}
                      onClick={(event) => { event.preventDefault(); Auth.federatedSignIn({ provider: 'Google' }) }}
                    ><img src={google_icn} alt="" className="pr-2" />Login Via Google</button>
                  </div>

                  <div className="col-12 text-right mt-5 d_lg_none_sm fixed-bottom py-4 bg-white">
                    <button className="btn btn-success px-4 text-uppercase cus_bg_green w-100 py-3"
                      disabled={loading}
                      onClick={(event) => { if (!loading) { this.handleSigninSubmit(event) } }}
                    >Login</button>
                  </div>

                </div>
                :
                <div className="col-sm-6">
                  <a href="#" className="cus_purple closeBtn serach_goBack_txt" onClick={(event) => {
                    event.preventDefault(); this.setState({ showLoginWithMobileView: false }, () => {
                      this.resetValueOnViewChange();
                    })
                  }}>
                    <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;
                                    Go back
                 </a>
                  <h2 className="login_text pt-4">Log In with Phone Number</h2>
                  <div className="form-row pt-5">
                    <div className="col-md-12 mb-5 cus_15p_left">


                      <label htmlFor="phone_number">Mobile Number</label><br />
                      <div className="iti iti--allow-dropdown iti--separate-dial-code w-100"><div className="iti__flag-container">
                        <div className="iti__selected-flag "
                          tabindex="-1"
                          onClick={() => this.setState({ showCountryList: !this.state.showCountryList })}
                          onKeyPress={(event) => {
                            try {
                              console.log(event.charCode)
                              if (event.key && event.charCode >= 65 && event.charCode <= 122) {
                                if (event.charCode < 91 || event.charCode >= 97) {
                                  var list = document.getElementById("country-listbox"),
                                    targetLi = document.getElementById(event.key.toLowerCase()); // id tag of the <li> element
                                  list.scrollTop = (targetLi.offsetTop);
                                }
                              }
                            } catch (er) {
                              console.log(er)
                            }
                          }
                          }
                          onBlur={() => {
                            setTimeout(() => {
                              this.setState({ showCountryList: false })
                            }, 300);
                          }}
                          role="combobox"
                          aria-owns="country-listbox"
                        // tabindex="0"
                        >
                          <div className={"iti__flag iti__" + selectedCountry.name_code.toLowerCase()}></div>
                          <div className="iti__selected-dial-code">+{selectedCountry.mob_code}</div>
                          <div className={"iti__arrow iti__arrow--" + (this.state.showCountryList ? "up" : "down")}></div>
                        </div>
                        <ul className={"iti__country-list iti__country-list--dropup " + (!this.state.showCountryList ? "iti__hide" : "")} id="country-listbox" aria-expanded="true" role="listbox" aria-activedescendant="iti-item-in">

                          {countryListDataObj && Object.keys(countryListDataObj).map((key, cObjind) => (
                            <li className="iti__country iti__standard flaglist_hover " tabindex="-1"
                              onClick={(event) => this.onCountryChange(event, countryListDataObj[key])}
                              // id={"iti-item-" + key.toLowerCase()}
                              id={countryListDataObj[key]["name"][0].toLowerCase()}
                              role="option" data-dial-code={countryListDataObj[key]["mob_code"]} data-country-code={key.toLowerCase()} aria-selected="true">
                              <div className="iti__flag-box" >
                                <div className={"iti__flag iti__" + key.toLowerCase()}></div>
                              </div>
                              <span className="iti__country-name">{countryListDataObj[key]["name"]}</span><span className="iti__dial-code">+{countryListDataObj[key]["mob_code"]}</span>
                            </li>
                          ))}

                        </ul>
                      </div>
                        <input id="phone_number"
                          name="phone_number"
                          placeholder="Mobile Number"
                          maxLength={10}
                          disabled={isOtpLoading}
                          value={phone_number}
                          style={{ float: "right" }}
                          // onBlur={() => this.checkIfEmailOrMobileAlreadyexists({ "type": "mobile", "mobile": phone_number })}
                          onChange={(event) => this.handelInputChange(event)} className={"form-control w-100 ml-3" + (errorFields["phone_number"] ? " is-invalid" : '')}
                          type="text" autocomplete="off" />

                      </div>



                      {preExistingDataCheck["mobile"] && <div className="invalid-feedback show_div">An account with this Mobile does not exists</div>}
                      {phoneValidationError["showError"] && <div className="invalid-feedback show_div">{phoneValidationError["errMsg"]}</div>}
                      {!isPhoneOtpSent && <div className="text-right mt-4 d575_none">
                        <button className="btn btn-success px-4 text-uppercase cus_bg_green w-50"
                          // type="submit"
                          disabled={preExistingDataCheck["mobile"]}

                          onClick={(event) => {
                            event.preventDefault();
                            this.checkIfEmailOrMobileAlreadyexists({ "type": "mobile", "mobile": phone_number });
                            // this.sendOtpToLoginViaPhone(event);
                          }}
                        >Send OTP {isOtpLoading && <i className="fa fa-spinner fa-spin" />}</button>
                      </div>}
                    </div>

                    {isPhoneOtpSent && <div className="col-12">
                      <TextField
                        id="phone_otp"
                        label="OTP"
                        fullWidth
                        value={phone_otp}
                        name="phone_otp"
                        InputProps={{
                          classes: {
                            root: classes.customInput,
                          },
                        }}
                        type={"number"}
                        maxLength={6}
                        error={phone_otpError["showError"] ? true : false}
                        // placeholder="OTP"
                        onChange={(event) => this.handelInputChange(event)}
                        variant="outlined"
                      >
                      </TextField>
                      <div className={"invalid-feedback " + (phone_otpError["showError"] ? 'show_div' : '')}>{phone_otpError["errMsg"]}</div>
                      <div className="text-right mt-4 d575_none">
                        <button className="btn btn-success px-4 text-uppercase cus_bg_green w-50"
                          // type="submit"
                          // disabled={loading}
                          onClick={(event) => { this.verifyPhoneLoginOtop(event) }}
                        >Verify OTP
                        </button>
                      </div>
                    </div>}
                  </div>
                  {this.state.showLoginErrorMessage &&
                    <div className="pt-4">
                      <span className="d-inline-block text-danger" style={{ fontSize: '14px' }}>Incorrect username or password</span>
                    </div>}

                  {!isPhoneOtpSent &&
                    <div className="col-12 text-right mt-5 d_lg_none_sm fixed-bottom py-4 bg-white">
                      <button className="btn btn-success px-4 text-uppercase cus_bg_green w-100 py-3"
                        disabled={preExistingDataCheck["mobile"]}
                        onClick={(event) => {
                          event.preventDefault();
                          this.checkIfEmailOrMobileAlreadyexists({ "type": "mobile", "mobile": phone_number });
                          // this.sendOtpToLoginViaPhone(event);
                        }}
                      >Send OTP {isOtpLoading && <i className="fa fa-spinner fa-spin" />}</button>
                    </div>}
                  {isPhoneOtpSent &&
                    <div className="col-12 text-right mt-5 d_lg_none_sm fixed-bottom py-4 bg-white">
                      <button className="btn btn-success px-4 text-uppercase cus_bg_green w-100 py-3"
                        // disabled={preExistingDataCheck["mobile"]}
                        onClick={(event) => { this.verifyPhoneLoginOtop(event) }}
                      >Verify OTP</button>
                    </div>}
                </div>

              }
              <div className="col-sm-6 d-flex justify-content-md-end d575_none" style={{ paddingRight: "0px" }}>
                <div>
                  <img src={login_img} className="w-100" alt="" height="586px" width="368px" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>);
  }
}

export default withStyles(styles)(withRouter(LoginContainer));
