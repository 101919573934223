import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from "@material-ui/core/styles/withStyles";
// import CommodityDetailContainer from '../productDetails/CommodityDetailContainer';

import { searchPostedCommodity, searchCommodity } from '../../services/commonService';
import Loader from './Loader';
import locationicon from '../../assests/img/icons/location.png';
import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CommonUtil from "../../shared/CommonUtil";
import $ from 'jquery';
import NoSearchPage from './NoSearchPage';
import whatsappicon from '../../assests/images/whatsapp_icon.png';

import Tooltip from '@material-ui/core/Tooltip';
var moment = require('moment');

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: "10px",
        position: "fixed"
        // alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttoncss: {
        borderRadius: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: "#32ad79",
        color: "#fff",
        border: "none",
        fontWeight: 400,
        marginTop: "10px",
        marginBottom: "5%",
        fontSize: "14px",
        fontFamily: 'Lato, sans-serif',
        textTransform: "uppercase",
        paddingTop: "5px",
        paddingBottom: "5px",
        fontStretch: "normal",
        fontStyle: "normal",
        marginRight: "10px"
    },
    materialInput: {
        color: "red",
        minWidth: "298px",
        width: "100%"
    },
    selectedDefault: {
        backgroundColor: "#ffffff"
    },
    tooltipCls: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.4px",
        color: "rgba(255, 255, 255, 0.88)",
        flex: "none",
        order: 1,
        flexGrow: 0,
        margin: "0px 8px"
    }
});

const myTheme = (val) => createMuiTheme({
    overrides: {
        MuiPickersStaticWrapper: {
            staticWrapperRoot: {
                minWidth: "298px"
            }
        },
        MuiPickersBasePicker: {
            pickerView: {
                maxWidth: "inherit",
                minWidth: "300px"
            }
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: val ? "#fff" : "#247BC7",
                color: "#fff"
            },
            dayDisabled: {
                '& p': {
                    color: "#9e9e9e !important"
                }
            }
        }
    }
})
const today = new Date()
const tomorrow = new Date(today)
tomorrow.setDate(tomorrow.getDate() + 1)


class SubListCommodity extends Component {

    state = {};
    constructor(props) {
        super(props);
        this.state = {
            showAddOrderModal: false,
            SubListCommodityData: [],
            commodityId: this.props.commodityId,
            showSearchedDetails: false,
            selectedProductObj: {},
            showLoader: false,
            rawObj: {},
            commodityListOptions: [],
            filterParams: {
                commodity_id: [],
                commodityNames: [],
                offerExpiry: "",
                currency: "INR",
                offset: 0,
                limit: 50,
            },
            dataCount: 0,
            loading: false,
            showTooltip2: false
        }
    }

    componentDidMount() {
        this.setState({ showLoader: true }, () => {
            // if (this.props.location.state && this.props.location.state.detail && this.props.location.state.detail.hasOwnProperty("commodity_id")) {
            //     let filterParamVal = this.state.filterParams;
            //     filterParamVal["commodity_id"].push(this.props.location.state.detail.id);
            //     filterParamVal["commodityNames"].push(this.props.location.state.detail.name);
            //     this.setState({ rawObj: this.props.location.state.detail, filterParams: filterParamVal }, () => {
            //         this.getSearchedSubList(this.props.location.state.detail.commodity_id)
            //     })
            // } else {
            //     let lastSearchData = CommonUtil.getDataFromLocal("SubListCommodity");
            //     if (lastSearchData) {
            //         let filterParamVal = this.state.filterParams;
            //         filterParamVal["commodity_id"].push(lastSearchData.id);
            //         filterParamVal["commodityNames"].push(lastSearchData.name);
            //         this.setState({ rawObj: lastSearchData, filterParams: filterParamVal }, () => {
            //             this.getSearchedSubList(lastSearchData.commodity_id)
            //         })
            //     } else {
            //         this.props.history.push("/");
            //     }
            // }
            let lastSearchData = CommonUtil.getDataFromSession("SubListCommodity");
            console.log(lastSearchData)
            if (lastSearchData) {
                this.setState({ filterParams: lastSearchData }, () => {
                    this.getSearchedSubList(lastSearchData.commodity_id.toString());
                })
            } else {
                this.props.history.push("/");
            }

        });
        this.searchCommodity();
    }

    componentWillReceiveProps(nextProps) {
        if (CommonUtil.getDataFromSession("SubListCommodity") &&
            CommonUtil.getDataFromSession("SubListCommodity")["commodity_id"] !== this.state.filterParams["commodity_id"]) {
            this.setState({ filterParams: CommonUtil.getDataFromSession("SubListCommodity") }, () => {
                this.getSearchedSubList(CommonUtil.getDataFromSession("SubListCommodity").commodity_id.toString())
            })
        }
        // if (this.props.location && this.props.location.state && this.props.location.state.detail && this.props.location.state.detail.id &&
        //     nextProps.location && nextProps.location.state && nextProps.location.state.detail && nextProps.location.state.detail.id &&
        //     nextProps.location.state.detail.id !== this.props.location.state.detail.id) {
        //     this.setState({ showLoader: true }, () => {

        //         let filterParamVal = this.state.filterParams;
        //         filterParamVal["commodity_id"] = [];
        //         filterParamVal["commodityNames"] = [];
        //         filterParamVal["commodity_id"].push(nextProps.location.state.detail.id);
        //         filterParamVal["commodityNames"].push(nextProps.location.state.detail.name);
        //         this.setState({ rawObj: nextProps.location.state.detail, filterParams: filterParamVal }, () => {
        //             this.getSearchedSubList(nextProps.location.state.detail.commodity_id)
        //         })
        //     });
        // }
    }

    async searchCommodity() {
        // e.preventDefault();
        try {
            // let resp = await searchPostedCommodity(1);
            let resp = await searchCommodity(this.state.searchedText);
            console.log(resp)
            let clo = [];
            if (resp && resp.type === 1) {
                clo = resp.data;
            }
            this.setState({ commodityListOptions: clo }, () => {
                this.controllPopFilter();
            });
        } catch (error) {
            console.log(error);
        }
    }

    setLastSearchData(obj) {
        let newObj = Object.assign({}, obj)
        newObj.commodityNames = this.state.filterParams["commodityNames"];
        newObj.commodity_id = newObj.commodity_id ? newObj.commodity_id.split(",").map(i => Number(i)) : []
        console.log(newObj)
        CommonUtil.saveDataToSession("SubListCommodity", newObj);
    }

    async getSearchedSubList(commodityid) {
        try {
            // let commodityid = '1';
            let paginationObj = {
                offset: this.state.filterParams.offset,
                limit: this.state.filterParams.limit,
                "commodity_id": commodityid,
                currency: "INR"
            };

            if (!paginationObj["commodity_id"] || paginationObj["commodity_id"] === "") {
                delete paginationObj["commodity_id"];
            }
            if (this.state.filterParams.offerExpiry && this.state.filterParams.offerExpiry !== '') {
                paginationObj['offer_expiry_date'] = moment(this.state.filterParams.offerExpiry).format('YYYY-MM-DD');
            }
            if (this.state.filterParams.currency && this.state.filterParams.currency !== '') {
                paginationObj['currency'] = this.state.filterParams.currency;
            }

            this.setLastSearchData(paginationObj)

            let resp = await searchPostedCommodity(paginationObj);
            console.log(resp)
            let clo = [];
            if (resp && resp.type === 1 && resp.data && resp.data["data"]) {
                clo = resp.data.data;
            }
            if (this.state.filterParams.offset !== 0) {
                let existingData = this.state.SubListCommodityData;
                clo = existingData.concat(clo)
            }
            this.setState({
                SubListCommodityData: clo,
                loading: false,
                showLoader: false,
                dataCount: resp.data && resp.data["total"] ? resp.data["total"] : 0
            }, () => {
                this.controllPopFilter();
            });

        } catch (error) {
            console.log(error);
        }
    }

    controllPopFilter() {
        try {
            $('.free_search_content .openBtn').on('click', function () {
                $('.free_search_content .remove_on_filter').hide();
                $('.free_search_content .popup').fadeIn(300);
                $('.free_search_content .popup').css('overflow', 'auto');
                $('.free_search_content .text_sm_change').html('Apply Filters');
                $('.free_search_content .text_sm_change').removeClass('text-muted');
                $('.free_search_content .text_sm_change').css({ "color": "#000 !important", "font-size": "24px", "font-weight": "bold" });
            });

            // Close Popup
            $('.free_search_content .closeBtn').on('click', function () {
                $('.free_search_content .popup').fadeOut(10);
                $('.free_search_content .remove_on_filter').show();
            });

            $('btn btn-success px-4 text-uppercase cus_bg_green w-100 py-3').on('click', function () {
                $('.free_search_content .popup').fadeOut(10);
                $('.free_search_content .remove_on_filter').show();
            });
        } catch (err) {
            console.log(err);
        }
    }


    // onProductSelected(event, data, isthroughButton) {
    //     try {
    //         console.log(data)
    //         event.preventDefault();
    //         event.stopPropagation();
    //         this.setState({
    //             selectedProductObj: data,
    //             showSearchedDetails: true,
    //             isEnquiryButtonClicked: isthroughButton
    //         })
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }


    onProductSelected(event, data, isthroughButton) {
        try {
            console.log(data)
            event.preventDefault();
            event.stopPropagation();
            // this.setState({
            //     selectedProductObj: data,
            //     showSearchedDetails: true,
            //     isEnquiryButtonClicked: isthroughButton
            // })
            CommonUtil.saveDataToLocal("commoditydetail", data);
            this.props.history.push({
                pathname: '/commoditydetail',
                search: '',
                state: { detail: { selectedProductObj: data, isEnquiryButtonClicked: isthroughButton } }
            })
        } catch (err) {
            console.log(err);
        }
    }

    onFilterChanged(event, obj) {
        try {
            console.log(event.target.checked);
            console.log(event.target.id);
            console.log(obj);
            event.preventDefault();
            // event.stopPropagation();

            let filterParam = this.state.filterParams;
            // filterParam["commodity_id"]
            if (filterParam["commodity_id"] && filterParam["commodity_id"].indexOf(obj.id) > -1) {
                filterParam["commodity_id"].splice(filterParam["commodity_id"].indexOf(obj.id), 1);
                filterParam["commodityNames"].splice(filterParam["commodityNames"].indexOf(obj.name), 1);

            } else {
                filterParam["commodity_id"].push(obj.id);
                filterParam["commodityNames"].push(obj.name);
            }
            this.setState({ filterParams: filterParam }, () => {
                this.getSearchedSubList(filterParam["commodity_id"].toString())
            })
        } catch (err) {
            console.log(err);
        }
    }

    onFilterDateChange(dateval) {
        try {
            let filterParam = this.state.filterParams;
            filterParam["offerExpiry"] = dateval;
            this.setState({
                filterParams: filterParam
            }, () => {
                this.getSearchedSubList(filterParam["commodity_id"].toString())
            })

        } catch (err) {
            console.log(err)
        }
    }

    clearFilter(e) {
        e.preventDefault();
        try {
            this.setState({
                filterParams: {
                    commodity_id: [],
                    commodityNames: [],
                    offerExpiry: "",
                    currency: "INR",
                    offset: 0,
                    limit: 50
                }
            }, () => {
                this.getSearchedSubList("")
            })

        } catch (err) {
            console.log(err)
        }
    }


    handleInputRadioChange(event, value) {
        try {
            event.preventDefault();
            console.log(value);
            let filterParam = this.state.filterParams;
            filterParam["currency"] = value;
            this.setState({ filterParams: filterParam }, () => {
                this.getSearchedSubList(filterParam["commodity_id"].toString())
            });
        } catch (error) {
            console.log(error);
        }
    }

    loadMoreClick(e) {
        e.preventDefault();
        try {
            let filterParam = this.state.filterParams;
            filterParam["offset"] = filterParam["offset"] + filterParam.limit;
            this.setState({ filterParams: filterParam, loading: true }, () => {
                this.getSearchedSubList(filterParam["commodity_id"].toString())
            });
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const { classes } = this.props;
        const { SubListCommodityData, commodityListOptions,
            selectedProductObj, isEnquiryButtonClicked, showLoader, filterParams,
            dataCount, loading } = this.state;
        console.log(CommonUtil.getTommorowSDate());
        console.log(filterParams["offerExpiry"])
        return (<>
            {!showLoader &&
                <section className="free_search free_search_content">
                    <div className="container tb_padding">
                        <div className="row cus_sec_width cus_mb_120_sm">
                            <div className="col-lg-4" id="filters">
                                <div className="popup">
                                    <div className="pop-content">
                                        <div className="d-block d-sm-none mb-4">
                                            <a href="#" className="cus_purple closeBtn serach_goBack_txt">
                                                <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;
                                    Go back
                                    </a>
                                        </div>
                                        <div>
                                            <p className="text-muted text_sm_change">Filters</p>
                                        </div>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <p>Filter by commodity</p>

                                                {commodityListOptions && commodityListOptions.length > 0 &&
                                                    commodityListOptions.map((obj, indexx) =>
                                                        <label key={"WW" + indexx} className="chkbox_toolbar font_r" >
                                                            {obj.name}
                                                            <input type="checkbox" id={obj.id}
                                                                onChange={(e) => {
                                                                    this.onFilterChanged(e, obj);
                                                                }}
                                                                checked={filterParams["commodity_id"].indexOf(obj.id) > -1} />
                                                            <span className="mark"></span>
                                                        </label>)}

                                            </div>
                                        </div>
                                        {/* <div className="card mt-4">
                                            <div className="card-body">
                                                <p>Filter by Veriety</p>
                                                <label className="chkbox_toolbar">Commodity Name
                                        <input type="checkbox" checked="checked" />
                                                    <span className="mark"></span>
                                                </label>
                                                <label className="chkbox_toolbar">Commodity Name
                                        <input type="checkbox" />
                                                    <span className="mark"></span>
                                                </label>
                                                <label className="chkbox_toolbar">Commodity Name
                                        <input type="checkbox" />
                                                    <span className="mark"></span>
                                                </label>
                                                <label className="chkbox_toolbar">Commodity Name
                                        <input type="checkbox" />
                                                    <span className="mark"></span>
                                                </label>
                                            </div>
                                        </div> */}
                                        <div className="card mt-4 mb-0 mb-sm-5 mb-lg-0">
                                            <div className="card-body p-0">
                                                <p style={{ paddingLeft: "25px", paddingTop: "20px" }}>Expiry Date till</p>
                                                {/* <div className="calendar calendar-first pt-3" id="calendar_first"> */}
                                                {/* <div className="calendar_header">
                                                        <button className="switch-month switch-left"> <i
                                                            className="fa fa-chevron-left"></i></button>
                                                        <h2></h2>
                                                        <button className="switch-month switch-right"> <i
                                                            className="fa fa-chevron-right"></i></button>
                                                    </div>
                                                    <div className="calendar_weekdays"></div>
                                                    <div className="calendar_content"></div> */}

                                                {/* </div> */}
                                                <MuiThemeProvider theme={myTheme(filterParams["offerExpiry"] === "" || !filterParams["offerExpiry"])}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}
                                                        InputProps={{ classes: { staticWrapperRoot: classes.materialInput } }}
                                                        style={{ minWidth: "100%" }}>
                                                        <DatePicker
                                                            autoOk
                                                            // orientation="landscape"
                                                            id="calenderPick_view"
                                                            variant="static"
                                                            // minDate={CommonUtil.getTommorowSDate()}
                                                            // openTo={"02/2020"}
                                                            InputProps={{
                                                                classes: {
                                                                    pickerView: classes.materialInput,
                                                                    // MuiPickersDay: classes.selectedDefault
                                                                }
                                                            }}
                                                            disablePast
                                                            disableToolbar
                                                            selected={null}
                                                            value={filterParams["offerExpiry"]}
                                                            onChange={(dateval) => { this.onFilterDateChange(dateval) }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </MuiThemeProvider>
                                            </div>
                                        </div>
                                        <div className="col-12 text-right mt-5 d_lg_none_sm fixed-bottom py-4 bg-white">
                                            <div className="cus_purple text-center sublist_clearFilter_text mb-3" onClick={(e) => this.clearFilter(e)}>Clear All Filters</div>
                                            <button className="btn btn-success px-4 text-uppercase cus_bg_green w-100 py-3 closeBtn"
                                                disabled={loading}
                                                type="submit">Apply
                                                </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 remove_on_filter">
                                <div className="align-items-sm-center justify-content-sm-between d575_column">
                                    <div>
                                        {/* <span className="d-inline-block font_r">
                                            <h5 className="font_r">Searching for {filterParams && filterParams["commodityNames"] &&
                                                filterParams["commodityNames"].length > 0 ?
                                                (filterParams["commodityNames"].length < 4 ? filterParams["commodityNames"].toString() : filterParams["commodityNames"].slice(0, 3).toString() + '...') :
                                                'all commodities'}&nbsp;:</h5>
                                        </span> */}
                                        <Tooltip
                                            style={{ width: "100%" }}
                                            classes={{ tooltip: classes.tooltipCls }}
                                            open={this.state.showTooltip2}
                                            title={filterParams && filterParams["commodityNames"] &&
                                                filterParams["commodityNames"].length > 5 ?
                                                filterParams["commodityNames"].join(', ') : ""} arrow>
                                            <span className="d-inline-block font_r">
                                                <h5 className="font_r search_sublist"
                                                    onMouseOver={() => this.setState({ showTooltip2: !this.state.showTooltip2 })}
                                                    onMouseOut={() => this.setState({ showTooltip2: !this.state.showTooltip2 })}
                                                    onClick={() => this.setState({ showTooltip2: !this.state.showTooltip2 })}
                                                >Searching for {filterParams && filterParams["commodityNames"] &&
                                                    filterParams["commodityNames"].length > 0 ?
                                                    filterParams["commodityNames"].join(', ') :
                                                    'all commodities'}&nbsp;:</h5>
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <h5 className="font-weight-bold d-inline-block d_lg_none_sm " style={{ left: "2%" }}>&nbsp;{dataCount} Results found</h5>

                                    {SubListCommodityData && SubListCommodityData.length > 0 && <div>
                                        <div className="radio_toolbar text-center text-md-right  mt-2">
                                            <h5 className="font-weight-bold d-inline-block font_r d575_none " style={{ position: "absolute", left: "2%" }}>&nbsp;{dataCount} Results found</h5>
                                            <input type="radio" id="inr_web" name="currency" value="inr" checked={filterParams["currency"] === "INR"} />
                                            <label for="inr_web"
                                                onClick={(ev) => this.handleInputRadioChange(ev, "INR")}>INR</label>

                                            <input type="radio" id="usd_web" name="currency" value="usd" checked={filterParams["currency"] === "USD"} />
                                            <label for="usd_web" className="ml-2" onClick={(ev) => this.handleInputRadioChange(ev, "USD")}>USD</label>

                                            <input type="radio" id="aed_web" name="currency" value="aed" checked={filterParams["currency"] === "AED"} />
                                            <label for="aed_web" className="ml-2" onClick={(ev) => this.handleInputRadioChange(ev, "AED")}>AED</label>
                                        </div>
                                    </div>}
                                </div>

                                {SubListCommodityData && SubListCommodityData.length > 0 &&
                                    SubListCommodityData.map((obj, index) =>
                                        <div key={index + "poi"} className="card mt-3" style={{ cursor: 'pointer' }}>
                                            <div className="card-body d575_none" onClick={(event) => this.onProductSelected(event, obj, false)}>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <p>{CommonUtil.getFormatedId(obj.sell_id)}</p>
                                                    </div>
                                                    <div className="col-3">
                                                        <div>
                                                            <img src={obj.commodity_images && obj.commodity_images["data"] && obj.commodity_images["data"][0]["url"]} alt=""
                                                                style={obj.commodity_images && obj.commodity_images["data"] && obj.commodity_images["data"][0]["url"] ? { height: '142px', width: '136px', borderRadius: '50%', border: "2px solid #FFFFFF" } : {}} />
                                                        </div>
                                                    </div>
                                                    <div className="col-9">
                                                        <h5>{obj.name + " - " + (obj.commodity_desc ? obj.commodity_desc : "")}</h5>
                                                        <div className="d-flex justify-content-between">
                                                            <span className="cus_purple">
                                                                <img src={locationicon} alt="" />&nbsp;{obj.locality ? obj.locality : ""}
                                                            </span>
                                                            <span>
                                                                <Tooltip
                                                                    classes={{ tooltip: classes.tooltipCls }}
                                                                    style={{ cursor: "pointer" }}
                                                                    title="Talk to the expert" >
                                                                    <img src={whatsappicon}
                                                                        className={"pr-3"}
                                                                        onClick={(event) => {
                                                                            event.preventDefault();
                                                                            event.stopPropagation();
                                                                            CommonUtil.openLinkInNewTab("https://wa.me/918585958484?text=" + encodeURI(`I am interested to know about enquiry no. ${CommonUtil.getFormatedId(obj.sell_id).replace("#","")} - ${obj.name};`))
                                                                        }
                                                                        }
                                                                        alt="" />
                                                                </Tooltip>
                                                            </span>
                                                        </div>
                                                        <div className="row pt-4">
                                                            <div className="col-3">
                                                                <span className="text-muted font-weight-normal">Volume P.M.</span>
                                                                <p className="font-weight-bold">{obj.quantity + " " + obj.quantity_unit}</p>
                                                            </div>
                                                            <div className="col-5">
                                                                <span className="text-muted font-weight-normal">Est. FOB Price</span>
                                                                {/* <p className="font-weight-bold">{obj.price_currency}&nbsp;{obj.price}/{obj.price_unit}</p> */}
                                                                <p className="font-weight-bold">{obj.curreny_price.toFixed(2) + " " + filterParams["currency"] + " per " + obj.price_unit}</p>
                                                            </div>
                                                            <div className="col-4">
                                                                <span className="text-muted font-weight-normal">Expiry Date</span>
                                                                <p className="font-weight-bold cus_light_green">{obj.offer_expiry_date}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <!-- Card for sm devices (width < 576px) --> */}
                                            <div className="card-body d_lg_none_sm">
                                                <div className="row" onClick={(event) => this.onProductSelected(event, obj, false)}>
                                                    <div className="col-12">
                                                        <p className="mobile_searchId" style={{ marginBottom: '8px' }}>{CommonUtil.getFormatedId(obj.sell_id)}</p>
                                                    </div>
                                                    <div className="col-3">
                                                        <div>
                                                            {/* <img src="img/card_img1.png" className="w-100" alt="" /> */}
                                                            <img src={obj.commodity_images && obj.commodity_images["data"] && obj.commodity_images["data"][0]["url"]} alt=""

                                                                className={obj.commodity_images && obj.commodity_images["data"] && obj.commodity_images["data"][0]["url"] ? "mobileImg_search" : "mobileImg_search_empty"}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-9">
                                                        <h5 style={{ letterSpacing: "0.15px", fontWeight: "bold", color: "#25282B" }}>{obj.name + " - " + (obj.commodity_desc ? obj.commodity_desc : "")}</h5>
                                                        <span className="cus_purple" style={{ letterSpacing: "0.1px" }}>
                                                            <img src={locationicon} alt="" /> &nbsp;{obj.locality ? obj.locality : ""}
                                                        </span>
                                                        <span style={{ float: "right" }}>
                                                            <Tooltip
                                                                classes={{ tooltip: classes.tooltipCls }}
                                                                style={{ cursor: "pointer" }}
                                                                title="Talk to the expert" >
                                                                <img src={whatsappicon}
                                                                    className={"pr-3"}
                                                                    onClick={(event) => {
                                                                        event.preventDefault();
                                                                        event.stopPropagation();
                                                                        CommonUtil.openLinkInNewTab("https://wa.me/918585958484?text=" + encodeURI(`I am interested to know about enquiry no. ${CommonUtil.getFormatedId(obj.sell_id).replace("#","")} - ${obj.name};`))
                                                                    }
                                                                    }
                                                                    alt="" />
                                                            </Tooltip>
                                                        </span>
                                                    </div>
                                                    <div className="col-12 pt-3">
                                                        <span className="mobile_volume_search font-weight-normal">Volume P.M.: &nbsp;
                                        <span className="font-weight-bold mobile_volume_search_value">{obj.quantity + " " + obj.quantity_unit}</span>
                                                        </span>
                                                    </div>
                                                    <div className="col-6">
                                                        <span className="text-muted font-weight-normal">Est. FOB Price</span>
                                                        {/* <p className="font-weight-bold">{obj.price_currency}&nbsp;{obj.price}/{obj.price_unit}</p> */}
                                                        <p className="font-weight-bold">{(obj.curreny_price).toFixed(2) + " " + filterParams["currency"] + " per " + obj.price_unit}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <span className="text-muted font-weight-normal">Expiry Date</span>
                                                        <p className="font-weight-bold cus_light_green">{obj.offer_expiry_date}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)}
                                {SubListCommodityData && SubListCommodityData.length === 0 &&
                                    <NoSearchPage redirectToPath={''}
                                        buttonText={""}
                                        titleText={"No result found."}
                                        headerTxt={"Try searching Commodity Name like Rice/ Onion"} />}

                                {SubListCommodityData && SubListCommodityData.length > 0 && SubListCommodityData.length.toString() !== dataCount &&
                                    <div className="text-center d575_none">
                                        <button className="btn btn-success px-4 text-uppercase cus_bg_green mt-4"
                                            disabled={loading}
                                            onClick={(e) => this.loadMoreClick(e)}
                                            type="submit">Load more</button>
                                    </div>}


                                {/* <!-- filter button --> */}
                                < div className="col-12 text-center shadow mt-5 d_lg_none_sm fixed-bottom py-4" >
                                    <a className="btn btn-default bg-white text-uppercase font-weight-bold openBtn"
                                        style={{
                                            padding: "12px 38px", borderRadius: "50px", border: "1px solid #19854",
                                            boxShadow: "0px 3px 4px rgba(150, 143, 143, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2)",
                                            fontSize: "18px", color: "#25282B"
                                        }} href="#" >Filter</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
            {showLoader && <Loader />}
        </>);
    }
}

export default withStyles(styles)(withRouter(SubListCommodity))