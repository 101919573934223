import request from '../api/request';
import userAuth from '../api/authActions';


//http://localhost:3000/public/checkEmaiMobileExist?type=mobile&mobile=9205627721&mob_country_code=91
export function checkIfEmailOrPhoneAlreadyExists(param) {
    return request({
        url: "/public/checkEmaiMobileExist",
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        params: param
    });
}


// http://localhost:3000/public/getListOfCountries
export function getListOfCountries() {
    return request({
        url: "/public/getListOfCountries",
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        // params: param
    });
}

//http://localhost:3000/public/user/delete?email=mrtesting112@gmail.com
export function removedUnConfirmedUser(param) {
    return request({
        url: "/public/user/delete",
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        params: param
    });
}


//http://localhost:3000/public/getCurrencyConversionPrice?from_cur=INR&to_cur=AED
export function getCurrencyConversionPrice(param) {
    return request({
        url: "/public/getCurrencyConversionPrice",
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        params: param
    });
}



// http://localhost:3000/user/myAccount/1
export function getMyProfile(userId) {
    return request({
        url: "/user/myAccount/" + userId,
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        // params: param
    });
}

// http://localhost:3000/user/myAccount/update/1
export function updateMyProfile(userId, data) {
    return request({
        url: "/user/myAccount/update/" + userId,
        method: 'post',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        data: data
    });
}

//http://localhost:3000/user/myAccount/addSellerKYC/{id}
export function addKYCDetails(userId, data) {
    return request({
        url: "/user/myAccount/addSellerKYC/" + userId,
        method: 'post',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        data: data
    });
}

//http://localhost:3000/user/myAccount/addSellerKYC/{id}
export function updateMyKYCDetails(userId, payload) {
    return request({
        url: "/user/myAccount/updateSellerKYC/" + userId,
        method: 'post',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        data: payload
    });
}


//http://localhost:3000/public/searchCommodity?searchCond=ri
export function searchCommodity(searchkey) {
    let param = {
        searchCond: searchkey
    }
    return request({
        url: "/public/searchCommodity",
        method: 'get',
        // headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        params: param
    });
}



//http://localhost:3000/public/sellCommodityList/1?offset=0&limit=10
export function searchPostedCommodity(param) {

    return request({
        url: "/public/sellCommodityList",
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        params: param
    });
}

// http://localhost:3000/seller/sellerCommodityList/1?offset=0&limit=10
export function getMyPosts(id, param) {

    return request({
        url: "/seller/sellerCommodityList/" + id,
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        params: param
    });
}

// http://localhost:3000/seller/enquiryUpdate
export function makeCounterOffer(data) {
    // {
    //     id : number
    //     seller_co_quantity : number
    //     seller_co_unit : string
    //     seller_co_shipment : string
    //     seller_co_price : number
    //     seller_co_currency : string
    //     seller_co_payment_type : string
    //     seller_co_advance_percentage : number
    //     seller_co_status : string
    // }
    return request({
        url: "/seller/enquiryUpdate",
        method: 'post',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        data: data
    });
}



//http://localhost:3000/buyer/enquiryUpdate
export function buyerMakeOffer(data) {
    // {
    //     id : number
    //     seller_co_quantity : number
    //     seller_co_unit : string
    //     seller_co_shipment : string
    //     seller_co_price : number
    //     seller_co_currency : string
    //     seller_co_payment_type : string
    //     seller_co_advance_percentage : number
    //     seller_co_status : string
    // }
    return request({
        url: "/buyer/enquiryUpdate",
        method: 'post',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        data: data
    });
}

// http://localhost:3000/user/uploadFile/11
export function uplaodFileToServer(id, base_64){
    return request({
        url: "/user/uploadFile/" + id,
        method: 'post',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        data: base_64
    });
}