import React from 'react';
import { Auth, Hub } from 'aws-amplify';
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from 'react-router-dom';
import login_img from '../../assests/img/login.jpg';
import login_sm from '../../assests/icons/login_img_sm.png';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import successIcon from '../../assests/icons/ok_icon.svg';


const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttoncss: {
    width: '50%',
    borderRadius: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "#32ad79",
    color: "#fff",
    border: "none",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: 'Lato, sans-serif',
    textTransform: "uppercase",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontStretch: "normal",
    fontStyle: "normal",
    marginRight: "10px"
  },
  customInput: {
    width: "100%",
    background: "#fff"
  },
  snackbarRoot: {
    backgroundColor: "#fff"
  },
});

class ChangePasswordContainer extends React.Component {

  state = { user: null, customState: null };
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      oldPassword: '',
      loading: false,
      verifyCode: '',
      newPassword: '',
      showChallengeNewPasswordView: false,
      user: null,
      settings: null,
      otp: '',
      confirmpassword: '',
      challengeName: "login",
      isForgotPasswordViewActive: false,
      logoUrl: undefined,
      clientName: "",
      showSnackbar: false,
      attributeType: '',
      attributeValue: '',
      showAttributeVericationScreen: false,
      showAttributeVericationScreenCode: false,
      attributeVerificationCode: '',
      errorFields: { "oldPassword": {}, otp: {}, password: {}, confirmpassword: {} },
      showErrorMessage: { hasError: false, errMsg: "" },
      forgotPasswordStep: 1,
      successMsg: false,
      passwordValidation: {
        errorMsg: "",
        showErr: false
      },
    }
  }

  componentDidMount() {

  }

  async getId() {
    await Auth.currentAuthenticatedUser().then((user) => {
    }).catch((err) => {
      this.props.history.push("/")
    });
  }

  handelInputChange(event) {
    event.preventDefault();
    try {
      let err = this.state.errorFields;
      let id = event.target.id;
      let val = event.target.value;
      if (id === "otp") {
        if (val === "" || !isNaN(val)) {

        } else {
          return;
        }
      }
      console.log(id)
      err[id]["hasError"] = false;
      this.setState({
        [id]: val,
        errorFields: err,
        showErrorMessage: { hasError: false, errMsg: "" }
      }, () => {
        if (id === "password") {
          this.checkForValidPassWord(this.state.password)
        }
      })
    } catch (err) {
      console.log(err);
    }
  }

  checkIfFormIsValid() {
    let errorFieldsVal = this.state.errorFields;
    let isValid = true;


    if (!this.state.oldPassword || this.state.oldPassword.trim() === "") {
      errorFieldsVal["oldPassword"] = { "hasError": true, errMsg: "This details cannot be empty" };
      isValid = false;
    }

    if (!this.state.password || this.state.password.trim() === "") {
      errorFieldsVal["password"] = { "hasError": true, errMsg: "This details cannot be empty" };
      isValid = false;
    }


    if (!this.state.confirmpassword || this.state.confirmpassword.trim() === "") {
      errorFieldsVal["confirmpassword"] = { "hasError": true, errMsg: "This details cannot be empty" };
      isValid = false;
    } else if (this.state.password === this.state.oldPassword) {
      errorFieldsVal["password"] = { "hasError": true, errMsg: "New password cannot be same as current password." };
      isValid = false;
    } else if (this.state.confirmpassword !== this.state.password) {
      errorFieldsVal["confirmpassword"] = { "hasError": true, errMsg: "Confirm password mismatch" };
      isValid = false;
    }

    if (this.state.confirmpassword && this.state.confirmpassword.trim() === "") {
      errorFieldsVal["confirmpassword"] = { "hasError": true, errMsg: "This details cannot be empty" };
      isValid = false;
    }

    if (this.state.passwordValidation && this.state.passwordValidation["showErr"]) {
      isValid = false;
    }

    console.log(errorFieldsVal)
    this.setState({ errorFields: errorFieldsVal, showErrorMessage: { hasError: false, errMsg: "" } });
    return isValid;

  }

  redirectToDashboard(data) {
    this.props.history.push(`/home`);
  }

  checkForValidPassWord(pwd) {
    if (pwd && pwd.length > 0) {
      if (pwd.length >= 6) {
        this.setState({
          passwordValidation: {
            errorMsg: "",
            showErr: false
          }
        });
      } else {
        let inValidMsg = [];
        // if (!/^(?=.*[a-z])/.test(pwd)) {
        //   inValidMsg.push(" 1 lowercase character");
        // }
        // if (!/^(?=.*?[A-Z])/.test(pwd)) {
        //   inValidMsg.push(" 1 uppercase character");
        // }
        // if (!/^(?=.*?[0-9])/.test(pwd)) {
        //   inValidMsg.push(" 1 number");
        // }
        // if (!/^(?=.*?[#?!@$%^&*-])/.test(pwd)) {
        //   inValidMsg.push(" 1 special character");

        // }
        // if (!/^[A-Za-z\d@$!%*?&]{6,}/.test(pwd)) {
        //   inValidMsg.push(" and password length must be more than 5");
        // }
        // inValidMsg = "Password must contain atleast" + inValidMsg.toString();
        if (!/^[A-Za-z\d@$!%*?&]{6,}/.test(pwd)) {
          inValidMsg.push("Password length must be more than 5");
        }
        // inValidMsg = "Password must contain atleast" + inValidMsg.toString();
        inValidMsg = inValidMsg.toString();
        this.setState({
          passwordValidation: {
            errorMsg: inValidMsg,
            showErr: true
          }
        });
      }
    }
  }


  async handleConfirmClick(event) {
    event.preventDefault();
    if (!this.checkIfFormIsValid()) {
      return;
    }
    this.setState({ loading: true });
    try {
      await Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, this.state.oldPassword, this.state.password);
        })
        .then(data => {
          // console.log(data)
          if (data === "SUCCESS") {
            // this.setState({ successMsg: true });
            this.setState({ successMsg: true, showSnackBar: true }, () => {
              setTimeout(() => {
                this.setState({ showSnackBar: false }, () => {
                  try {
                    Auth.signOut();
                    // history.push(`/login`);
                    window.localStorage.setItem('loggedInUserName', '');
                    window.localStorage.setItem('is_user_loggedIn', "false");
                    // this.switchToRoute('login');
                    this.props.history.push(`/login` );
              
                  } catch (error) {
                    console.log('error signing out: ', error);
                  }
                })
              }, 3000);
            })
          }
        })
        .catch(err => {
          console.log(err);
          let showErrorMessageVal = this.state.showErrorMessage;
          let errorFieldsVal = this.state.errorFields;
          showErrorMessageVal["hasError"] = true;
          showErrorMessageVal["errMsg"] = err.message;
          if (err.message === "Incorrect username or password.") {
            errorFieldsVal["oldPassword"] = { "hasError": true, errMsg: "Incorrect current password" };
            showErrorMessageVal["hasError"] = false;
          }
          this.setState({
            showErrorMessage: showErrorMessageVal,
            loading: false,
            successMsg: false,
            errorFields: errorFieldsVal
          });
        });

      this.setState({ loading: false });
    } catch (error) {
      let showErrorMessageVal = this.state.showErrorMessage;
      showErrorMessageVal["hasError"] = true;
      showErrorMessageVal["errMsg"] = error.message;
      this.setState({ showErrorMessage: showErrorMessageVal, loading: false });
    }
  }

  render() {
    const { classes } = this.props;
    const { oldPassword, password, errorFields, loading, passwordValidation, otp, confirmpassword } = this.state;
    return (<>
      <section className="login_form">
        <div className="d-block d-sm-none">
          <div className="img_sm"> <img src={login_sm} alt="" width="100%"></img></div>
        </div>
        <div className="container cus_mb_120_sm">
          <form className="bootstrap-form needs-validation cus_sec_width section_padding" noValidate >
            <a href="#" className="cus_purple closeBtn serach_goBack_txt" onClick={(event) => { event.preventDefault(); this.props.history.goBack(); }}>
              <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;
                                    Go back
                                    </a>
            <div className="row">
              <div className="col-sm-6">
                <h2 className="login_text mt-4">Change Password</h2>
                <div className="form-row pt-3">
                  <div className="col-12">
                    <div className="mt-1">
                      <TextField
                        id="oldPassword"
                        label="Current Password"
                        fullWidth
                        value={oldPassword}
                        name="oldPassword"
                        type={"password"}
                        InputProps={{
                          classes: {
                            root: classes.customInput,
                          },
                        }}
                        error={errorFields["oldPassword"]["hasError"]}
                        placeholder="Enter your current password"
                        onChange={(event) => this.handelInputChange(event)}
                        variant="outlined"
                      />
                      <div className={"invalid-feedback " + (errorFields["oldPassword"]["hasError"] ? 'show_div' : '')}>{errorFields["oldPassword"]["errMsg"]}</div>
                    </div>
                  </div>
                  <div className="col-12 mt-5">
                    <TextField
                      id="password"
                      label="New Password"
                      fullWidth
                      value={password}
                      name="password"
                      InputProps={{
                        classes: {
                          root: classes.customInput,
                        },
                      }}
                      type={"password"}
                      error={errorFields["password"]["hasError"] ? true : false}
                      placeholder="Password"
                      onChange={(event) => this.handelInputChange(event)}
                      variant="outlined"
                    />
                    <div className={"invalid-feedback " + (errorFields["password"]["hasError"] ? 'show_div' : '')}>{errorFields["password"]["errMsg"]}</div>
                    {!errorFields["password"]["hasError"] && <div className={"invalid-feedback " + (passwordValidation["showErr"] ? 'show_div' : 'hide_div')}>{passwordValidation["errorMsg"]}</div>}
                  </div>
                  <div className="col-12 mt-5">
                    <TextField
                      id="confirmpassword"
                      label="Confirm New Password"
                      fullWidth
                      value={confirmpassword}
                      name="confirmpassword"
                      InputProps={{
                        classes: {
                          root: classes.customInput,
                        },
                      }}
                      type={"password"}
                      error={errorFields["confirmpassword"]["hasError"] ? true : false}
                      placeholder="Confirm Password"
                      onChange={(event) => this.handelInputChange(event)}
                      variant="outlined"
                    />
                    <div className={"invalid-feedback " + (errorFields["confirmpassword"]["hasError"] ? 'show_div' : '')}>{errorFields["confirmpassword"]["errMsg"]}</div>
                  </div>
                </div>

                {this.state.showErrorMessage["hasError"] &&
                  <div className="pt-4  text-center">
                    <span className="d-inline-block text-danger" style={{ fontSize: '14px' }}>
                      {this.state.showErrorMessage["errMsg"]}
                    </span>
                  </div>}
                {this.state.successMsg &&
                  <div className="pt-4 text-center">
                    <span className="d-inline-block text-success" style={{ fontSize: '14px' }}>
                      Success! Your Password has been changed!
                    </span>
                  </div>}


                <div className="text-right mt-4 d575_none">
                  <button className="btn btn-success px-4 text-uppercase cus_bg_green w-50"
                    disabled={loading}
                    onClick={(event) => { if (!loading) { this.handleConfirmClick(event) } }}
                  >Continue {loading && <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>}</button>
                </div>

                <div className="col-12 text-right mt-5 d_lg_none_sm fixed-bottom py-4 bg-white">
                  <button className="btn btn-success px-4 text-uppercase cus_bg_green w-100 py-3"
                    disabled={loading}
                    onClick={(event) => { if (!loading) { this.handleConfirmClick(event) } }}
                  >Continue</button>
                </div>

              </div>
              <div className="col-sm-6 d-flex justify-content-md-end d575_none" style={{ paddingRight: "0px" }}>
                <div>
                  <img src={login_img} className="w-100" alt="" height="586px" width="368px" />
                </div>
              </div>
            </div>
          </form>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.showSnackBar}
          style={{ backgroundColor: "#fff", top: "25%" }}
          ContentProps={{
            classes: {
              root: classes.snackbarRoot
            }
          }}
          onClose={() => this.setState({ showSnackBar: false })}
          message={
            <div className="d-flex">
              <div className="col-md-1 ml-auto">
                <img src={successIcon} alt="" />
              </div>
              <div className="col-11 ml-auto modal_text">
              Success! Your Password has been changed! Please login to continue.
            </div>
            </div>
          }
          key={"top" + "center"}
        />
      </section>
    </>);
  }
}

export default withStyles(styles)(withRouter(ChangePasswordContainer));
