import React from 'react';
// import PostCommodityContainer from '../views/postCommodity/PostCommodityContainer';
import ProductDetailsContainer from '../views/productDetails/ProductDetailsContainer';
// import PostEnquiryContainer from '../views/postEnquiry/PostEnquiryContainer';
import MyPostsContainer from '../views/myposts/MyPostsContainer';
import SubListCommodity from '../views/common/SubListCommodity';
// import SellerEnquiryList from '../views/table/SellerEnquiryViewList';
import BuyerEnquiryList from '../views/table/BuyerEnquiryList';
import LoginContainer from '../views/login/LoginContainer';
import SignUpContainer from '../views/signup/SignUpContainer';
import MyProfileContainer from '../views/myProfile/MyProfileContainer';
import SellerEnquiryContainer from '../views/sellerEnquiry/SellerEnquiryContainer';
import PostLoginPage from '../views/common/PostLoginPage';
import PreLoginPage from '../views/common/PreLoginPage';
import CommodityDetailContainer from '../views/productDetails/CommodityDetailContainer';
import ForgotPasswordContainer from '../views/forgotpassword/ForgotPasswordContainer';
import ChangePasswordContainer from '../views/changePassword/ChangePasswordContainer';
import BuyerEnquiryContainer from '../views/buyerEnquiry/BuyerEnquiryContainer';

// export const PatientCallCenterContainer = React.lazy(() => import('../views/EngagementRoi/PatientCallCenterContainer'));
export const DummyContainer = React.lazy(() => import('../views/dummy/DummyContainer'));
// export const PatientCallCenterContainer = React.lazy(() => import('../views/patientCallCenter/PatientCallCenterContainer'));





// icontype denotes either FontAwesonIcon or directly Image path
// sideNavIcon denotes fontawesone ClassName  or src path 

var routeMappings = [

    {
        componentName: PreLoginPage,
        path: "/",
        sideNaveName: "Post Commodity",
        sideNavIcon: "fa fa-home fa-fw",
        icontype: "fontAwesome"
    },
    {
        componentName: LoginContainer,
        path: "/login",
        sideNaveName: "Post Commodity",
        sideNavIcon: "fa fa-home fa-fw",
        icontype: "fontAwesome"
    },
    {
        componentName: SignUpContainer,
        path: "/signUp",
        sideNaveName: "Post Commodity",
        sideNavIcon: "fa fa-home fa-fw",
        icontype: "fontAwesome"
    },
    {
        componentName: ForgotPasswordContainer,
        path: "/forgotpassword",
        sideNaveName: "Forgot Password",
        sideNavIcon: "fa fa-home fa-fw",
        icontype: "fontAwesome"
    },

    // ------------------------------------------------Prelogin----------------------
    {
        componentName: ChangePasswordContainer,
        path: "/changepassword",
        sideNaveName: "Post Commodity",
        sideNavIcon: "fa fa-home fa-fw",
        icontype: "fontAwesome"
    },
    {
        componentName: CommodityDetailContainer,
        path: "/commoditydetail",
        sideNaveName: "Post Commodity",
        sideNavIcon: "fa fa-home fa-fw",
        icontype: "fontAwesome"
    },
    {
        componentName: PreLoginPage,
        // componentName: PostLoginPage,
        path: "/home",
        sideNaveName: "Post Commodity",
        sideNavIcon: "fa fa-home fa-fw",
        icontype: "fontAwesome"
    }, {
        componentName: ProductDetailsContainer,
        path: "/ProductDetailsContainer",
        sideNaveName: "Dashboard",
        sideNavIcon: "fa fa-support fa-fw",
        icontype: "fontAwesome"
    }, {
        componentName: SellerEnquiryContainer,
        path: "/SellerEnquiryContainer",
        sideNaveName: "NPS",
        sideNavIcon: "fa fa-envelope fa-fw",
        icontype: "fontAwesome"
    }, {
        componentName: MyPostsContainer,
        path: "/myenquiries",
        sideNaveName: "Patient Journey",
        sideNavIcon: "fa fa-bolt fa-fw",
        icontype: "fontAwesome"
    }, {
        componentName: SubListCommodity,
        path: "/SubListCommodity",
        sideNaveName: "Patient Roi",
        sideNavIcon: "fa fa-envelope-open-o fa-fw",
        icontype: "fontAwesome"
    },
    //  {
    //     componentName: SellerEnquiryList,
    //     path: "/SellerEnquiryList",
    //     sideNaveName: "Today's Analysis",
    //     sideNavIcon: "fa fa-wrench fa-fw",
    //     icontype: "fontAwesome"
    // },
     {
        componentName: BuyerEnquiryList,
        path: "/mytradelist",
        sideNaveName: "Today's Analysis",
        sideNavIcon: "fa fa-wrench fa-fw",
        icontype: "fontAwesome"
    }, {
        componentName: MyProfileContainer,
        path: "/myprofile",
        sideNaveName: "My profile",
        sideNavIcon: "fa fa-wrench fa-fw",
        icontype: "fontAwesome"
    },
    {
        componentName: BuyerEnquiryContainer,
        path: "/BuyerEnquiryContainer",
        sideNaveName: "NPS",
        sideNavIcon: "fa fa-envelope fa-fw",
        icontype: "fontAwesome"
    }

]

// To handel Te Inter Nal routinhg  within the component
var redirectUrl = [
    // { redirectPath: "/patientcallcenter/all-enquiry", "componentName": DummyContainer },

];

//   <Route exact path="/" render={() => (<Redirect to="/login" />)} />
//   <Route exact path="/forgotpassword" component={DummyContainer} />
//   <Route exact path="/signUp" component={SignUpContainer} />
//   <Route path="/login" component={LoginContainer} />

var preLoginUrls = [
    { redirectPath: "/login", "componentName": LoginContainer },
    { redirectPath: "/forgotpassword", "componentName": DummyContainer },
    { redirectPath: "/signUp", "componentName": SignUpContainer },
];


var appRoutes = {
    routeMappings,
    redirectUrl,
    preLoginUrls

}

export default appRoutes;



