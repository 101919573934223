/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { withRouter } from 'react-router-dom';
import addcommodityimg from '../../assests/icons/addcommodityimg.svg';
import pdf_icon from '../../assests/img/icons/pdf_icon.png';
import Loader from '../common/Loader';
import { Auth } from 'aws-amplify';
import { searchCommodity, uplaodFileToServer } from '../../services/commonService';
import { postSellCommodity } from '../../services/postSellService';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import CommonUtil from '../../shared/CommonUtil';
import Tooltip from '@material-ui/core/Tooltip';
import info_icon from '../../assests/icons/info_icon.svg';
import $ from 'jquery';

var moment = require('moment');

const styles = theme => ({
    heading: {
        fontSize: '21px',
        fontWeight: '500',
        marginTop: '0',
        marginBottom: '0',
        fontFamily: 'Montserrat, sans-serif',
    },
    dialogPaper: {
        minWidth: '700px',
        // maxWidth: '700px',
        minHeight: '400px',
        // maxHeight: '500px'
    },
    formAddBtn: {
        width: '90%',
        borderRadius: '10px',
        fontSize: '20px',
        textTransform: 'uppercase',
        backgroundColor: '#4d9fa0 ',
        color: '#fff',
        height: '45px',
        marginBottom: '15px',
        marginTop: "11px",
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    formRoot: {
        // display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        // marginLeft: '25%',
        border: '1px solid #ccc',
        boxShadow: '2px 2px 6px 0px  rgba(0,0,0,0.3)',
        borderRadius: '4px',
        marginBottom: '20px',
        marginTop: '8%',
        padding: '25px',
        textAlign: 'center'
    },
    input: {
        display: 'none',
    },
    buttoncss: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "1.25px",
        textTransform: "uppercase",
        color: "#FFFFFF"
    },
    customInput: {
        width: "100%",
        background: "#fff"
    },
    tooltipCls: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.4px",
        color: "rgba(255, 255, 255, 0.88)",
        flex: "none",
        order: 1,
        flexGrow: 0,
        margin: "0px 8px"
    }

});
// const statusOption = ["pending", "settled", "partial_settled"];

const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
    return data && data.is_primary ? (
        <div ref={innerRef} {...innerProps} className='custom_option'>
            <i className="fa fa-check" style={{ color: "#60c1d8" }} aria-hidden="true"></i> &nbsp; {data.label ? data.label : ""}
        </div>
    ) : (
            <div ref={innerRef} {...innerProps} className='custom_option' >
                {data.label ? data.label : ""}
            </div>

        );
};

class SellerEnquiryContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            userid: "",
            buyerid: "",
            supplierid: "",
            brokerid: "",
            tempVar: {},
            errorFields: {},
            attachmentArray: [],
            commodityList: { options: [], optionN_E: {}, optionE_N: {} },
            showLoader: false,
            subId: "",

            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            showFormError: false,

            tagsOption: [],
            orderTypeOptions: [],
            commodityListOptions: [],
            postCommodityPayload: {
                userid: '',
                commodity_id: '',
                commodity_desc: '',
                packing_type: '',
                locality: '',
                quantity: '',
                quantity_unit: 'MT',
                price: '',
                price_unit: '',
                price_currency: '',
                payment_type: '',
                shipment: '',
                advance_percentage: '',
                other_quality_details: { data: [] },  /* JSON LIKE  “quality_params”: {
                    “data”: [
                        {
                            “name”: “broken”,
                            “type”: “float”
                        }
                    ]
                }*/
                commodity_images: { data: [] },
                other_doc_images: { data: [] },
                other_comment: '',
                is_disabled: false,
                year_of_harvest: '',
                offer_expiry_date: ''
            },
            selectedDate: "",
            selectedCommodity: '',
            selectedCommodityObj: {},
            selectedQualityParams: [],
            loading: false,
            mobileStep: 1,
            isImageLoading: "",
            fileSizeError: {
                "showErrorcommodity_images": false,
                "showErrorMSgcommodity_images": "",
                "showErrorother_doc_images": false,
                "showErrorMSgother_doc_images": "",
            },
            showTooltip: false,
            showTooltip2: false

        }
        // this.getCommodityNames();
        // this.handelAutoCompleteChange = this.handelAutoCompleteChange.bind(this);
    }
    componentWillMount() {
        this.getId();
        this.getCommodityList();
    }

    async getId() {
        await Auth.currentAuthenticatedUser().then((user) => {
            if (user.attributes && user.attributes["custom:id"]) {
                let postCp = this.state.postCommodityPayload;
                postCp["userid"] = Number(user.attributes["custom:id"]);
                this.setState({ postCommodityPayload: postCp, userid: user.attributes["custom:id"] });
            }

        }).catch((err) => {
            this.props.history.push("/")
        });
    }
    handleDialogCancel(event) {
        this.props.onAddModalCancel();
    }

    async getCommodityList() {
        try {
            // let resp = await searchPostedCommodity(1);
            let resp = await searchCommodity('');

            let clo = [];
            if (resp && resp.type === 1) {
                clo = resp.data;
            }
            this.setState({ commodityListOptions: resp.data });
        } catch (error) {
            console.log(error);
        }
    }

    handelCommodityChange(obj) {
        // event.preventDefault();
        try {
            let errorVal = this.state.errorFields;
            let postCommodityPayloadVal = this.state.postCommodityPayload;
            postCommodityPayloadVal['commodity_id'] = obj.id;
            postCommodityPayloadVal["other_quality_details"]["data"] = obj["quality_params"]["data"] || [];
            errorVal["commodity_id"] = false;
            console.log(obj)
            this.setState({
                selectedQualityParams: obj["quality_params"]["data"] || [],
                selectedCommodityObj: obj,
                selectedCommodity: obj.name,
                postCommodityPayload: postCommodityPayloadVal
            })
        } catch (err) {
            console.log(err);
        }
    }

    handleDateInputChange(dateVal) {
        try {
            let postCommodityPayloadVal = this.state.postCommodityPayload;
            var errors = this.state.errorFields;

            console.log(dateVal)
            postCommodityPayloadVal["offer_expiry_date"] = moment(dateVal).format("YYYY-MM-DD");
            console.log(postCommodityPayloadVal["offer_expiry_date"])

            errors["offer_expiry_date"] = false;
            this.setState({
                selectedDate: dateVal,
                postCommodityPayload: postCommodityPayloadVal,
                errorFields: errors,
                showFormError: false
            })
        } catch (err) {
            console.log(err);
        }

    }

    handleInputChange(event) {
        try {
            console.log(event);

            var floatIds = ["price", "quantity"]; // this values need to be float
            let onlyInteger = [, "advance_percentage"];
            var errors = this.state.errorFields;
            let postCommodityPayloadVal = this.state.postCommodityPayload;
            let id = event.target.id;
            let val = event.target.value;
            if (!id && id === undefined) {
                id = event.target.name;
            }
            console.log(id)
            console.log(val)
            console.log(typeof (val))

            let flaotRegex = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
            if (floatIds.indexOf(id) > -1) {
                if (val === "" || flaotRegex.test(val)) {
                    if (id === "advance_percentage") {
                        if (val === "" || (Number(val) > 0 && Number(val) <= 100)) {
                            postCommodityPayloadVal[id] = val;
                        } else {
                            return;
                        }
                    } else {
                        postCommodityPayloadVal[id] = val;
                    }
                } else {
                    return;
                }

            } else if (onlyInteger.indexOf(id) > -1) {
                if (id === "advance_percentage") {
                    if (val === "" || (Number(val) > 0 && Number(val) <= 100)) {
                        // postCommodityPayloadVal[id] = val;
                    } else {
                        return;
                    }
                }
                if (val === "" || val.match(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)) {
                    postCommodityPayloadVal[id] = (val === "" ? val : Number(val));
                }
            } else {
                postCommodityPayloadVal[id] = val;
            }


            // if (floatIds.indexOf(id) > -1) {
            //     // if (val === "" || !isNaN(val)) {
            //     if (id === "advance_percentage") {
            //         if (val !== '') {
            //             if (val < 1 || val > 100) {
            //                 return;
            //             }
            //         }
            //     }
            //     if (val === "" || val.match(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)) {
            //         postCommodityPayloadVal[id] = val;
            //     }
            // } else {
            //     postCommodityPayloadVal[id] = val;
            // }

            if (id === "payment_type") {
                if (val !== "Advance payment") {
                    postCommodityPayloadVal["advance_percentage"] = '';
                }
            }



            if (errors.hasOwnProperty(id)) {
                delete errors[id];
            }

            this.setState({
                postCommodityPayload: postCommodityPayloadVal,
                errorFields: errors,
                showFormError: false
            })

        } catch (err) {
            console.log(err);
        }
    }

    handleQualityParamsInputChange(obj, index, event) {
        try {
            var floatIds = []; // this values need to be float
            var errors = this.state.errorFields;
            let postCommodityPayloadVal = this.state.postCommodityPayload;
            let other_quality_detailsVal = postCommodityPayloadVal["other_quality_details"]["data"];
            let id = event.target.id;
            let val = event.target.value;
            if (!id && id === undefined) {
                id = event.target.name;
            }


            let flaotRegex = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
            if (obj.type === "float") {
                // if (val === "" || val.match(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)) {
                if (val === "" || flaotRegex.test(val)) {
                    if (obj.unit && obj.unit === "%") {
                        if (val === "" || (Number(val) >= 0 && Number(val) <= 100)) {
                            other_quality_detailsVal[index]["value"] = val;
                        } else {
                            return;
                        }
                    } else {
                        other_quality_detailsVal[index]["value"] = val;
                    }

                } else {
                    return;
                }
            } else {
                other_quality_detailsVal[index]["value"] = val;
            }

            if (errors.hasOwnProperty(id)) {
                delete errors[id];
            }
            // let idToChange = -1;
            // if (other_quality_detailsVal.length > 0) {
            //     for (let indobj = 0; indobj < other_quality_detailsVal.length; indobj++) {
            //         if (other_quality_detailsVal[indobj]['name'] === obj['name']) {
            //             idToChange = indobj;
            //         }
            //     }
            // }
            // if (idToChange === -1) {
            //     other_quality_detailsVal.push(obj)
            // } else {
            //     other_quality_detailsVal[idToChange] = obj;
            // }

            console.log(postCommodityPayloadVal)
            postCommodityPayloadVal["other_quality_details"]["data"] = other_quality_detailsVal;
            // postCommodityPayloadVal["other_quality_details"] = other_quality_detailsVal;
            this.setState({
                postCommodityPayload: postCommodityPayloadVal,
                errorFields: errors,
                showFormError: false
            });

        } catch (err) {
            console.log(err);
        }
    }

    formatTheFloatValuesBeforeUpload(postCommodityPayloadObj) {
        try {
            let floatIds = ["price", "quantity"];
            let formatedObj = postCommodityPayloadObj["other_quality_details"]["data"];
            for (var i = 0; i < formatedObj.length; i++) {
                let obj = formatedObj[i];
                if (obj && obj.type && obj.type === "float" && formatedObj[i].hasOwnProperty("value")) {
                    formatedObj[i]["value"] = formatedObj[i]["value"] !== '' ? Number(formatedObj[i]["value"]) : '';
                }
            }
            postCommodityPayloadObj["other_quality_details"]["data"] = formatedObj;

            for (var j = 0; j < floatIds.length; j++) {
                postCommodityPayloadObj[floatIds[j]] = Number(postCommodityPayloadObj[floatIds[j]]);
            }

            return postCommodityPayloadObj;
        } catch (error) {
            return postCommodityPayloadObj;
        }

    }

    async postCommodityToPortal() {
        try {

            if (!this.checkIfFormIsValid()) {
                this.setState({ loading: false });
                return;
            }
            let payload = this.formatTheFloatValuesBeforeUpload(this.state.postCommodityPayload);
            console.log(payload);
            let resp = await postSellCommodity(payload);
            console.log(resp);

            if (resp && resp.type === 1) {
                // alert(resp.message)
            } else {

            }
            this.setState({ loading: false }, () => {
                this.props.history.push(`/myenquiries`);
            })

        } catch (error) {
            console.log(error)
        }
    }

    checkIfFormIsValid() {
        try {
            let errorId = [];
            let isValid = true;
            let errorFieldsVal = {};
            let ignoreKeyCheck = ["other_comment", "is_disabled", "packing_type", "shipment", "other_doc_images"];
            for (var key in this.state.postCommodityPayload) {

                if (key === "commodity_images" || key === "other_doc_images") {
                    if (!this.state.postCommodityPayload["commodity_images"] ||
                        !this.state.postCommodityPayload["commodity_images"]["data"] ||
                        this.state.postCommodityPayload["commodity_images"]["data"].length === 0) {
                        isValid = false;
                        errorFieldsVal["commodity_images"] = true;
                        errorId.push(key)

                    }
                    // if (!this.state.postCommodityPayload["other_doc_images"] ||
                    //     !this.state.postCommodityPayload["other_doc_images"]["data"] ||
                    //     this.state.postCommodityPayload["other_doc_images"]["data"].length === 0) {
                    //     isValid = false;
                    //     errorFieldsVal["other_doc_images"] = true;
                    //     errorId.push(key)
                    // }
                }

                if (!this.state.postCommodityPayload[key] || this.state.postCommodityPayload[key] === "") {
                    if (key === "advance_percentage") {
                        if (this.state.postCommodityPayload["payment_type"] === "Advance payment") {
                            isValid = false;
                            errorId.push(key)
                            errorFieldsVal[key] = true;
                        }

                    } else if (ignoreKeyCheck.indexOf(key) > -1) {

                    } else {
                        isValid = false;
                        errorId.push(key)
                        errorFieldsVal[key] = true;
                    }
                }

            }

            this.setState({ errorFields: errorFieldsVal })
            console.log(errorFieldsVal)
            if (!isValid) {
                // document
                //     .getElementById(errorId[0])
                //     .scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                $(window).scrollTop($('#' + errorId[0]).offset().top);

            }
            return isValid;
        } catch (err) {
            console.log(err);
        }
    }

    checkIfFormIsValidStepWise(stepNo) {

        let isValid = true;
        let errorFieldsVal = {};
        let ignoreKeyCheck = ["other_comment", "is_disabled"];
        let stepWiseKeyMap = {
            1: ["commodity_images"],
            2: ["commodity_id", "commodity_desc", "quantity", "quantity_unit", "locality", "year_of_harvest"], // "shipment",
            3: ["price_unit", "price_currency", "offer_expiry_date", "advance_percentage", "payment_type", "price"],
            4: []
        }


        for (let i = 0; i < stepWiseKeyMap[stepNo].length; i++) {
            if (!this.state.postCommodityPayload[stepWiseKeyMap[stepNo][i]] ||
                this.state.postCommodityPayload[stepWiseKeyMap[stepNo][i]] === "") {
                if (stepWiseKeyMap[stepNo][i] === "advance_percentage") {
                    if (this.state.postCommodityPayload["payment_type"] === "Advance payment") {
                        isValid = false;
                        errorFieldsVal[stepWiseKeyMap[stepNo][i]] = true;
                    }

                } else if (ignoreKeyCheck.indexOf(stepWiseKeyMap[stepNo][i]) > -1) {

                } else {
                    isValid = false;
                    errorFieldsVal[stepWiseKeyMap[stepNo][i]] = true;
                }
            }

            if (stepWiseKeyMap[stepNo][i] === "commodity_images" || stepWiseKeyMap[stepNo][i] === "other_doc_images") {
                if (!this.state.postCommodityPayload["commodity_images"] || !this.state.postCommodityPayload["commodity_images"]["data"] ||
                    this.state.postCommodityPayload["commodity_images"]["data"].length === 0) {
                    isValid = false;
                    errorFieldsVal["commodity_images"] = true;

                }
                // if (!this.state.postCommodityPayload["other_doc_images"] ||
                //     !this.state.postCommodityPayload["other_doc_images"]["data"] ||
                //     this.state.postCommodityPayload["other_doc_images"]["data"].length === 0) {
                //     isValid = false;
                //     errorFieldsVal["other_doc_images"] = true;

                // }
            }
        }

        this.setState({ errorFields: errorFieldsVal })
        console.log(errorFieldsVal)
        return isValid;
    }

    handleInputRadioChange(event, value, id) {
        try {
            event.preventDefault();
            console.log(id);
            console.log(value);
            var floatIds = []; // this values need to be float
            var errors = this.state.errorFields;
            errors[id] = false;
            let postCommodityPayloadVal = this.state.postCommodityPayload;
            postCommodityPayloadVal[id] = value
            this.setState({ postCommodityPayload: postCommodityPayloadVal })
        } catch (error) {
            console.log(error);
        }
    }

    imageChangedHandler_old = (certificateType, event) => {
        try {
            let fiNme = event.target.files[0] && event.target.files[0].name ? event.target.files[0].name : '';

            let cFtype = fiNme.slice((fiNme.lastIndexOf(".") - 1 >>> 0) + 2);
            let fileFormatAllowed = ['jpg', 'png', 'jpeg'];
            if (certificateType !== "commodity_images") {
                fileFormatAllowed.push('pdf')
            }
            if (fileFormatAllowed.indexOf(cFtype) > -1) {
                let fileSize = event.target.files[0].size;
                fileSize = Math.round((fileSize / 1024) / 1024); // kb
                console.log(fileSize)
                if (fileSize < 5) {
                    this.state.image = event.target.files[0];
                    let image = '';
                    this.state.selectedFileName = this.state.file ? this.state.file.name : null;
                    this.setState(this.state)
                    this.formatImageDataToBase64(event.target.files[0], (result) => {
                        image = result;
                        // console.log(image);
                        let data = {
                            data: image,
                            type: this.state.image.name.slice((this.state.image.name.lastIndexOf(".") - 1 >>> 0) + 2)
                        }
                        event.target.value = '';
                        this.uploadImageData(this.state.userid, data, fiNme, certificateType);
                    });
                } else {
                    // file size exceeds 
                    let fileSizeErrorVal = this.state.fileSizeError;
                    fileSizeErrorVal["showErrorMSg" + certificateType] = "File size is more than 5 MB";
                    fileSizeErrorVal["showError" + certificateType] = true;
                    this.setState({
                        fileSizeError: fileSizeErrorVal
                    });
                }
            } else {
                alert("Filetype  allowed (" + fileFormatAllowed.toString() + ")");
            }
        } catch (err) {
            console.log(err);
        }
    }

     imageChangedHandler = (certificateType, event) => {
        try {
            for (var i = 0; i < event.target.files.length; i++) {
                let cFile = event.target.files[i];
                let fiNme = cFile && cFile.name ? cFile.name : '';

                let cFtype = fiNme.slice((fiNme.lastIndexOf(".") - 1 >>> 0) + 2);
                let fileFormatAllowed = ['jpg', 'png', 'jpeg'];
                if (certificateType !== "commodity_images") {
                    fileFormatAllowed.push('pdf')
                }
                if (fileFormatAllowed.indexOf(cFtype) > -1) {
                    let fileSize = cFile.size;
                    fileSize = Math.round((fileSize / 1024) / 1024); // kb
                    console.log(fileSize)
                    if (fileSize < 5) {
                        this.state.image = cFile;
                        let image = '';
                        this.state.selectedFileName = this.state.file ? this.state.file.name : null;
                        this.setState(this.state)
                        this.formatImageDataToBase64(cFile, (result) => {
                            image = result;
                            // console.log(image);
                            let data = {
                                data: image,
                                type: this.state.image.name.slice((this.state.image.name.lastIndexOf(".") - 1 >>> 0) + 2)
                            }
                            event.target.value = '';
                            this.uploadImageData(this.state.userid, data, fiNme, certificateType);
                        });
                    } else {
                        // file size exceeds 
                        let fileSizeErrorVal = this.state.fileSizeError;
                        fileSizeErrorVal["showErrorMSg" + certificateType] = "File size is more than 5 MB";
                        fileSizeErrorVal["showError" + certificateType] = true;
                        this.setState({
                            fileSizeError: fileSizeErrorVal
                        });
                    }
                } else {
                    alert("Filetype  allowed (" + fileFormatAllowed.toString() + ")");
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    async uploadImageData(id, data, fiNme, certificateType) {
        try {
            let errorF = this.state.errorFields;
            errorF[certificateType] = false;
            this.setState({
                loading: true, isImageLoading: certificateType, errorFields: errorF, fileSizeError: {
                    "showErrorcommodity_images": false,
                    "showErrorMSgcommodity_images": "",
                    "showErrorother_doc_images": false,
                    "showErrorMSgother_doc_images": "",
                }
            });
            let resp = await uplaodFileToServer(id, data);
            console.log(resp);
            if (resp && resp.type === 1) {
                let postCommodityPayloadVal = this.state.postCommodityPayload;
                postCommodityPayloadVal[certificateType]["data"].push({ url: resp.data, fname: fiNme });
                // profiData[certificateType] = resp.data;
                // this.updateStatusInAPi({ type: resp.data });
                this.setState({ postCommodityPayload: postCommodityPayloadVal })
            }
            this.setState({ loading: false, isImageLoading: "" });
        } catch (error) {
            console.log(error)
        }
    }

    formatImageDataToBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    onDocImgDeleted = (event, indexOfdoc, obj, docId) => {
        try {

            console.log(event, indexOfdoc, obj, docId);
            let postCommodityPayloadVal = this.state.postCommodityPayload;
            // if () {
            // array.splice(index, 1);
            // }
            postCommodityPayloadVal[docId]["data"].splice(indexOfdoc, 1);
            this.setState({ postCommodityPayload: postCommodityPayloadVal })
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const { classes } = this.props;
        const { commodityListOptions, showLoader, postCommodityPayload, supplierid, buyerid,
            errorFields, showSweetAlert, sweetAlertData, showFormError, isImageLoading,
            loading, mobileStep,
            selectedCommodity, selectedQualityParams, fileSizeError } = this.state;
        return (<>
            <section className="sec_bg my_account_section">
                <div className="container">
                    {/* -------------------------------- Desktop View ------------------------------- */}
                    <div className="cus_sec_width tb_padding d575_none" style={{ padding: "64px 15px" }}>
                        <div className="pt-2">

                            <div className="row mb-4 ">
                                <div className="col-sm-4 pl-1" >
                                    <div className="comm_upload_title"> Commodity Docs </div>
                                    <div className="comm_img_upload_title pt-4"> Commodity Image </div>
                                    {postCommodityPayload.commodity_images && postCommodityPayload.commodity_images["data"] &&
                                        postCommodityPayload.commodity_images["data"].length > 0 &&
                                        postCommodityPayload.commodity_images["data"].map((item, img_index) =>
                                            <div key={img_index + "--"} style={{ position: "relative" }} className="mt-2">
                                                <img className="delete_upload_btn" src="http://cdn1.iconfinder.com/data/icons/diagona/icon/16/101.png"
                                                    onClick={(e) => { this.onDocImgDeleted(e, img_index, item, "commodity_images") }} />
                                                <img src={item.url} alt="" style={{ maxWidth: '88%' }} />
                                            </div>
                                        )
                                    }
                                    <span>
                                        <input type="file" id="commodity_images"
                                            disabled={loading}
                                            multiple
                                            onChange={this.imageChangedHandler.bind(this, 'commodity_images')}
                                            style={{ display: "none" }} />
                                        <label htmlFor='commodity_images'>

                                            {isImageLoading === "commodity_images" ?
                                                <div className="PostSeller_image_loader">
                                                    <Loader display="contents" primaryText="Uploading.. please wait" />
                                                </div> :
                                                <img src={addcommodityimg} alt="" style={{ cursor: 'pointer', marginLeft: '-2%' }} />
                                            }
                                        </label>
                                    </span>

                                    {errorFields["commodity_images"] && <div className="error_text">Please upload atleast one commodity image </div>}
                                    {fileSizeError["showErrorcommodity_images"] && <div className="error_text">{fileSizeError["showErrorMSgcommodity_images"]} </div>}

                                    <div className="comm_img_upload_title pt-4"> Upload Documents </div>

                                    {postCommodityPayload.other_doc_images && postCommodityPayload.other_doc_images["data"] &&
                                        postCommodityPayload.other_doc_images["data"].length > 0 &&
                                        postCommodityPayload.other_doc_images["data"].map((item, img_indexw) => (

                                            item.fname.indexOf(".pdf") > -1 ?
                                                <div key={img_indexw + "--"} style={{ position: "relative" }} className="mt-2">
                                                    <img className="delete_upload_btn" src="http://cdn1.iconfinder.com/data/icons/diagona/icon/16/101.png"
                                                        onClick={(e) => { this.onDocImgDeleted(e, img_indexw, item, "other_doc_images") }} />
                                                    <div className="card flex-row align-items-center px-2 py-3 mt-2" style={{ maxWidth: '88%' }}>
                                                        <div className="col-2 pl-0">
                                                            <img src={pdf_icon} width="35" alt=" " />
                                                        </div>
                                                        <div className="col-10">
                                                            <p className="mb-0">{item.fname ? item.fname : ""}</p>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div key={img_indexw + "-ww-"} style={{ position: "relative" }} className="mt-2">
                                                    <img className="delete_upload_btn" src="http://cdn1.iconfinder.com/data/icons/diagona/icon/16/101.png"
                                                        onClick={(e) => { this.onDocImgDeleted(e, img_indexw, item, "other_doc_images") }} />
                                                    <img key={img_indexw + "--"} src={item.url} alt="" style={{ maxWidth: '88%' }} />
                                                </div>)
                                        )
                                    }
                                    <span>
                                        <input type="file" id="other_doc_images"
                                            disabled={loading}
                                            multiple
                                            onChange={this.imageChangedHandler.bind(this, 'other_doc_images')}
                                            style={{ display: "none" }} />
                                        <label htmlFor='other_doc_images'>
                                            {isImageLoading === "other_doc_images" ?
                                                <div className="PostSeller_image_loader"><Loader display="contents" primaryText="Uploading.. please wait" /></div> :
                                                <img src={addcommodityimg} alt="" style={{ cursor: 'pointer', marginLeft: '-2%' }} />
                                            }
                                        </label>
                                    </span>
                                    {errorFields["other_doc_images"] && <div className="error_text">Please upload atleast one commodity document </div>}
                                    {fileSizeError["showErrorother_doc_images"] && <div className="error_text">{fileSizeError["showErrorMSgother_doc_images"]} </div>}
                                </div>
                                <div className="col-sm-8 pl-5">
                                    <div className="comm_upload_title cus_sec_width ">Commodity Details</div>
                                    <form className="bootstrap-form needs-validation cus_sec_width pt-4" novalidate>

                                        <div>
                                            <TextField
                                                id="commodity_id"
                                                select
                                                label="Select Commodity"
                                                value={selectedCommodity}
                                                fullWidth
                                                // style={{background:"#fff"}}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                }}
                                                error={errorFields["commodity_id"] ? true : false}
                                                helperText={errorFields["commodity_id"] ? "This detail cannot be empty" : ''}
                                                // onChange={handleChange}
                                                // helperText="Please select your currency"
                                                variant="outlined"
                                            >
                                                {commodityListOptions && commodityListOptions.map((obj, i) => (
                                                    <MenuItem key={obj.commodity_id} onClick={() => this.handelCommodityChange(obj)}
                                                        value={obj.name} id={obj.commodity_id}>
                                                        {obj.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div className="mt-4">
                                            <TextField
                                                id="commodity_desc"
                                                label="Commodity Description"
                                                name="commodity_desc"
                                                error={errorFields["commodity_desc"] ? true : false}
                                                helperText={errorFields["commodity_desc"] ? "This detail cannot be empty" : ''}
                                                value={postCommodityPayload.commodity_desc}
                                                onChange={this.handleInputChange.bind(this)}
                                                fullWidth
                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                }}
                                                // helperText="Some important text"
                                                variant="outlined"
                                            />

                                        </div>
                                        <div className="mt-4" style={{ display: "flex" }}>
                                            <TextField
                                                id="quantity"
                                                error={errorFields["quantity"] ? true : false}
                                                type="text"
                                                helperText={errorFields["quantity"] ? "This detail cannot be empty" : ''}
                                                label="Selling commodity volume per month"
                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                    inputProps: { min: 0 }
                                                }}

                                                fullWidth
                                                style={{ width: '60%' }}
                                                value={postCommodityPayload.quantity}
                                                onChange={this.handleInputChange.bind(this)}
                                                variant="outlined"
                                            /> &nbsp;&nbsp;
                                            <TextField
                                                select
                                                id="quantity_unit"
                                                name="quantity_unit"
                                                label="Unit"
                                                error={errorFields["quantity_unit"] ? true : false}
                                                helperText={errorFields["quantity_unit"] ? "This detail cannot be empty" : ''}
                                                type="text"
                                                // label="Commodity Description"
                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                }}
                                                fullWidth
                                                value={postCommodityPayload.quantity_unit}
                                                onChange={this.handleInputChange.bind(this)}
                                                style={{ width: '39%' }}
                                                // helperText="Some important text"
                                                variant="outlined"
                                            >
                                                {["Kg", "Quintal", "MT"].map((key, i) => (
                                                    <MenuItem key={i} value={key} selected={true}>
                                                        {key}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div className="mt-4">
                                            <TextField
                                                // id="outlined-helperText"
                                                label="Location of Commodity"
                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                }}
                                                fullWidth
                                                // helperText="Some important text"
                                                variant="outlined"
                                                id="locality"
                                                name="locality"
                                                error={errorFields["locality"] ? true : false}
                                                helperText={errorFields["locality"] ? "This detail cannot be empty" : ''}
                                                type="text"
                                                value={postCommodityPayload.locality}
                                                onChange={this.handleInputChange.bind(this)}
                                            >
                                            </TextField>

                                        </div>

                                        <div className="mt-4">
                                            <TextField
                                                id="year_of_harvest"
                                                name="year_of_harvest"
                                                select
                                                label="Year of harvest"
                                                error={errorFields["year_of_harvest"] ? true : false}
                                                helperText={errorFields["year_of_harvest"] ? "This detail cannot be empty" : ''}
                                                style={{ width: '49%' }}

                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                }}
                                                value={postCommodityPayload.year_of_harvest}
                                                onChange={this.handleInputChange.bind(this)}
                                                // onChange={handleChange}
                                                // helperText="Please select your currency"
                                                variant="outlined"
                                            >
                                                {['2020-2021',
                                                    '2019-2020',
                                                    '2018-2019',
                                                    '2017-2018'].map((option) => (
                                                        <MenuItem key={option.value} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                            </TextField>
                                        </div>




                                        <div className="mt-4 postEnquiry_title">Enquiry Details</div>
                                        <div className="mt-3" style={{ display: "flex" }}>
                                            <TextField
                                                id="price"
                                                error={errorFields["price"] ? true : false}
                                                helperText={errorFields["price"] ? "This detail cannot be empty" : ''}
                                                name="price"
                                                label="Estimated FOB price"
                                                type="text"

                                                value={postCommodityPayload.price}
                                                onChange={this.handleInputChange.bind(this)}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                    inputProps: { min: 0 }
                                                }}
                                                style={{ width: '50%' }}
                                                // helperText="Some important text"
                                                variant="outlined"
                                            />

                                            &nbsp;
                                            &nbsp;
                                            <TextField
                                                select
                                                id="price_currency"
                                                name="price_currency"
                                                label="Currency"
                                                error={errorFields["price_currency"] ? true : false}
                                                helperText={errorFields["price_currency"] ? "This detail cannot be empty" : ''}
                                                type="text"
                                                style={{ width: '22%' }}
                                                value={postCommodityPayload.price_currency}
                                                onChange={this.handleInputChange.bind(this)}

                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                }}
                                                variant="outlined"
                                            >
                                                {["INR", "USD", "AED"].map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            &nbsp;
                                            <div className="per_text">
                                                Per
                                            </div>
                                            &nbsp;
                                            <TextField
                                                select
                                                id="price_unit"
                                                name="price_unit"
                                                label="Unit"
                                                error={errorFields["price_unit"] ? true : false}
                                                helperText={errorFields["price_unit"] ? "This detail cannot be empty" : ''}
                                                type="text"
                                                style={{ width: '22%' }}
                                                value={postCommodityPayload.price_unit}
                                                onChange={this.handleInputChange.bind(this)}

                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                }}
                                                variant="outlined"
                                            >
                                                {["Kg", "Quintal", "MT"].map((key, i) => (
                                                    <MenuItem key={i} value={key} selected={true}>
                                                        {key}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>

                                        <div className="mt-4 postEnquiry_title d-flex">Payment Preference&nbsp;
                                        <Tooltip
                                                open={this.state.showTooltip2}
                                                classes={{ tooltip: classes.tooltipCls }}
                                                title="Please choose one of L/C or Advance Payment as your preferred payment mode. If Advance Payment is your preferred payment mode, mention the portion of total amount (%) that you would like to receive in advance." arrow>
                                                <img src={info_icon}
                                                    onMouseOver={() => this.setState({ showTooltip2: !this.state.showTooltip2 })}
                                                    onMouseOut={() => this.setState({ showTooltip2: !this.state.showTooltip2 })}
                                                    onClick={() => this.setState({ showTooltip2: !this.state.showTooltip2 })}
                                                    alt="" />
                                            </Tooltip>
                                        </div>

                                        <div className="free_search mt-4">
                                            <div className="radio_toolbar" id="payment_type">
                                                <input type="radio" id="L/C" name="L/C" value={"L/C"}
                                                    // style={{ color: postCommodityPayload.payment_type === "L/C" ? "#25282B" : "#FFFFFF" }}
                                                    checked={postCommodityPayload.payment_type === "L/C"}
                                                />
                                                <label htmlFor="L/C" className="radio_ps"
                                                    style={{
                                                        background: postCommodityPayload.payment_type === "L/C" ? "#247BC7" : "#FFFFFF",
                                                        color: postCommodityPayload.payment_type !== "L/C" ? "#25282B" : "#FFFFFF"
                                                    }}
                                                    onClick={(ev) => this.handleInputRadioChange(ev, "L/C", "payment_type")} x>L/c</label>



                                                <input type="radio" id="Advance payment" name="Advance payment"
                                                    // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                    value="Advance payment"
                                                    checked={postCommodityPayload.payment_type === "Advance payment"}
                                                />
                                                <label htmlFor="Advance payment" className="radio_ps"
                                                    style={{
                                                        marginLeft: "10px",
                                                        background: postCommodityPayload.payment_type === "Advance payment" ? "#247BC7" : "#FFFFFF",
                                                        color: postCommodityPayload.payment_type !== "Advance payment" ? "#25282B" : "#FFFFFF"
                                                    }} onClick={(ev) => this.handleInputRadioChange(ev, "Advance payment", "payment_type")}>Advance Payment</label>
                                            </div>
                                            {errorFields["payment_type"] ? <div className="inputFrameinputError_txt">Please select an option</div> : ''}
                                        </div>

                                        {postCommodityPayload.payment_type && postCommodityPayload.payment_type === "Advance payment" &&
                                            <div className="mt-4">

                                                <TextField
                                                    id="advance_percentage"
                                                    error={errorFields["advance_percentage"] ? true : false}
                                                    helperText={errorFields["advance_percentage"] ? "This detail cannot be empty" : ''}
                                                    name="price"
                                                    label="Advance payment percentage"
                                                    type="text"
                                                    value={postCommodityPayload.advance_percentage}
                                                    onChange={this.handleInputChange.bind(this)}
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInput,
                                                        },
                                                        inputProps: {
                                                            max: 100, min: 10
                                                        }
                                                    }}
                                                    style={{ width: '59%' }}
                                                    // helperText="Some important text"
                                                    variant="outlined"
                                                />
                                            </div>
                                        }
                                        <div className="mt-4">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    variant="outlined"
                                                    format="yyyy-MM-dd"
                                                    margin="normal"
                                                    inputVariant="outlined"
                                                    id="offer_expiry_date"
                                                    minDate={CommonUtil.getTommorowSDate()}
                                                    name="offer_expiry_date"
                                                    error={errorFields["offer_expiry_date"] ? true : false}
                                                    helperText={errorFields["offer_expiry_date"] ? "This detail cannot be empty" : ''}
                                                    label="Offer expiry date"

                                                    disablePast
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInput,
                                                        },
                                                        allowKeyboardControl: false,
                                                        readOnly: true,
                                                        autoFill: false,

                                                    }}

                                                    InputLabelProps={{
                                                        shrink: this.state.selectedDate === "" ? false : true,
                                                    }}
                                                    value={this.state.selectedDate}
                                                    onChange={(dateval) => this.handleDateInputChange(dateval)}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',

                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>



                                        <div className="mt-4 postEnquiry_title">Commodity Parameters</div>
                                        <div className="mt-4">
                                            {postCommodityPayload && postCommodityPayload["other_quality_details"] &&
                                                postCommodityPayload["other_quality_details"]["data"] &&
                                                postCommodityPayload["other_quality_details"]["data"].length > 0 &&
                                                postCommodityPayload["other_quality_details"]["data"].map((item, indobj) =>
                                                    <TextField
                                                        key={indobj + "__"}
                                                        id={item.name}
                                                        name={item.name}
                                                        error={errorFields[item.name] ? true : false}
                                                        label={item.name + " " + (item.unit ? item.unit : "")}
                                                        style={{
                                                            width: "49%",
                                                            textTransform: "capitalize",
                                                            marginLeft: indobj % 2 === 1 ? "2%" : '0%', marginTop: "10px"
                                                        }}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.customInput,
                                                            },
                                                        }}
                                                        value={item.value}
                                                        onChange={this.handleQualityParamsInputChange.bind(this, item, indobj)}
                                                        variant="outlined"
                                                    />)}

                                        </div>


                                        <div className="mt-4">
                                            {<TextField
                                                id="other_comment"
                                                label="Any other comments"
                                                placeholder="Any other comments (Enter details like quality params / packaging etc. requirements)"
                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                }}
                                                fullWidth
                                                multiline
                                                rows={4}
                                                // helperText="Some important text"
                                                error={errorFields["other_comment"] ? true : false}
                                                type="text"

                                                value={postCommodityPayload.other_comment}
                                                onChange={this.handleInputChange.bind(this)}
                                                variant="outlined"
                                            />}

                                        </div>

                                        <div className="col-md-12 p-0 mt-4 " style={{ textAlign: "end" }}>
                                            <input type="submit"
                                                disabled={!CommonUtil.checkIfKYCverified('seller') || loading}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    console.log(postCommodityPayload);
                                                    this.setState({ loading: true }, () => {
                                                        this.postCommodityToPortal();
                                                    })

                                                }}
                                                className={classes.buttoncss + " mt-5 btn btn-success cus_btn"}
                                                style={{ background: "#198548", padding: "16px 24px" }} value="Post Enquiry" />
                                            {!CommonUtil.checkIfKYCverified('seller') && <div className="inputFrameinputError_txt" style={{ textAlign: 'end' }}>Your Kyc is pending!</div>}
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/*-------------------------------- Desktop View End ----------------------- */}
                    {/* MobileView */}
                    <div className="cus_sec_width d_lg_none_sm" style={{ paddingBottom: "100px" }}>
                        <div className="pt-2">

                            <div className="row cus_sec_width ">
                                <div className="col-12 mb-3 mt-4">
                                    <a href="#" className="cus_purple go_back" onClick={(event) => {
                                        event.preventDefault();
                                        if (mobileStep === 1) {
                                            this.props.history.goBack();
                                        } else {
                                            this.setState({ mobileStep: mobileStep - 1 });
                                        }
                                    }}>
                                        <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                        &nbsp;&nbsp;GO BACK</a>
                                </div>
                                <div className="col-md-4">
                                    <h5 className="myPost_title">Post Enquiry (Step {mobileStep}/4)</h5>
                                </div>
                            </div>

                            <div className="row mb-4 cus_sec_width ">
                                <div className="col-sm-4 mt-3">
                                    {/*----------- Step 1 ----------------- */}
                                    {mobileStep === 1 && <>
                                        <div className="comm_upload_title"> Commodity Docs </div>
                                        <div className="comm_img_upload_title pt-4"> Commodity Image </div>
                                        {postCommodityPayload.commodity_images && postCommodityPayload.commodity_images["data"] &&
                                            postCommodityPayload.commodity_images["data"].length > 0 &&
                                            postCommodityPayload.commodity_images["data"].map((item, img_index) =>
                                                <div key={img_index + "--"} style={{ position: "relative" }} className="mt-2">
                                                    <img className="delete_upload_btn" src="http://cdn1.iconfinder.com/data/icons/diagona/icon/16/101.png"
                                                        onClick={(e) => { this.onDocImgDeleted(e, img_index, item, "commodity_images") }} />
                                                    <img key={img_index + "--"} src={item.url} alt="" style={{ maxWidth: '100%' }} />
                                                </div>
                                            )
                                        }
                                        <div style={{ textAlign: 'center' }}>
                                            <input type="file" id="commodity_images"
                                                disabled={loading}
                                                multiple
                                                onChange={this.imageChangedHandler.bind(this, 'commodity_images')}
                                                style={{ display: "none" }} />
                                            <label htmlFor='commodity_images'>

                                                {isImageLoading === "commodity_images" ?
                                                    <div className="image_loader" style={{ width: "18rem" }}>
                                                        <Loader display="contents" primaryText="Uploading.. please wait" />
                                                    </div> :
                                                    <img src={addcommodityimg} alt="" style={{ cursor: 'pointer' }} />
                                                }
                                            </label>
                                            {errorFields["commodity_images"] && <div className="error_text">Please upload atleast one commodity image </div>}
                                            {fileSizeError["showErrorcommodity_images"] && <div className="error_text">{fileSizeError["showErrorMSgcommodity_images"]} </div>}
                                        </div>

                                        <div className="comm_img_upload_title pt-4"> Upload Documents </div>
                                        {/* <img src={addcommodityimg} alt="" /> */}
                                        {/* other_doc_images */}

                                        {postCommodityPayload.other_doc_images && postCommodityPayload.other_doc_images["data"] &&
                                            postCommodityPayload.other_doc_images["data"].length > 0 &&
                                            postCommodityPayload.other_doc_images["data"].map((item, img_indexw) => (
                                                item.fname.indexOf(".pdf") > -1 ?
                                                    <div key={img_indexw + "--"} style={{ position: "relative" }} className="mt-2">
                                                        <img className="delete_upload_btn" src="http://cdn1.iconfinder.com/data/icons/diagona/icon/16/101.png"
                                                            onClick={(e) => { this.onDocImgDeleted(e, img_indexw, item, "other_doc_images") }} />
                                                        <div className="card flex-row align-items-center px-2 py-3">
                                                            <div className="col-2 pl-0">
                                                                <img src={pdf_icon} width="35" alt=" " />
                                                            </div>
                                                            <div className="col-10">
                                                                <p className="mb-0">{item.fname}</p>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    <div key={img_indexw + "--"} style={{ position: "relative" }} className="mt-2">
                                                        <img className="delete_upload_btn" src="http://cdn1.iconfinder.com/data/icons/diagona/icon/16/101.png"
                                                            onClick={(e) => { this.onDocImgDeleted(e, img_indexw, item, "other_doc_images") }} />
                                                        <img key={img_indexw + "--"} src={item.url} alt="" style={{ maxWidth: "100%" }} />
                                                    </div>)
                                            )
                                        }
                                        <div style={{ textAlign: 'center' }}>
                                            <input type="file" id="other_doc_images"
                                                disabled={loading}
                                                multiple
                                                onChange={this.imageChangedHandler.bind(this, 'other_doc_images')}
                                                style={{ display: "none" }} />
                                            <label htmlFor='other_doc_images'>
                                                {/* <img src={addcommodityimg} alt="" style={{ cursor: 'pointer' }} /> */}
                                                {isImageLoading === "other_doc_images" ?
                                                    <div className="image_loader mt-1" style={{ width: "18rem" }}>
                                                        <Loader display="contents" primaryText="Uploading.. please wait" />
                                                    </div> :
                                                    <img src={addcommodityimg} alt="" style={{ cursor: 'pointer' }} />
                                                }
                                            </label>
                                            {errorFields["other_doc_images"] && <div className="error_text">Please upload atleast one commodity document </div>}
                                            {fileSizeError["showErrorother_doc_images"] && <div className="error_text">{fileSizeError["showErrorMSgother_doc_images"]} </div>}
                                        </div>
                                    </>}
                                    {/*----------- Step 1 ----------------- */}
                                    {mobileStep > 1 &&
                                        <>
                                            <div className="col-sm-8 ">
                                                {mobileStep !== 4 && <div className="comm_upload_title">Commodity Details</div>}
                                                <form className="bootstrap-form needs-validation cus_sec_width pt-4" novalidate>

                                                    {mobileStep === 2 && <>
                                                        <div>
                                                            <TextField
                                                                id="outlined-select-currency-native"
                                                                select
                                                                label="Select Commodity"
                                                                value={selectedCommodity}
                                                                fullWidth
                                                                // style={{background:"#fff"}}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.customInput,
                                                                    },
                                                                }}
                                                                error={errorFields["commodity_id"] ? true : false}
                                                                helperText={errorFields["commodity_id"] ? "This detail cannot be empty" : ''}
                                                                // onChange={handleChange}
                                                                // helperText="Please select your currency"
                                                                variant="outlined"
                                                            >
                                                                {commodityListOptions && commodityListOptions.map((obj, i) => (
                                                                    <MenuItem key={obj.commodity_id} onClick={() => this.handelCommodityChange(obj)}
                                                                        value={obj.name} id={obj.commodity_id}>
                                                                        {obj.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </div>
                                                        <div className="mt-4">
                                                            <TextField
                                                                id="commodity_desc"
                                                                label="Commodity Description"
                                                                name="commodity_desc"
                                                                error={errorFields["commodity_desc"] ? true : false}
                                                                helperText={errorFields["commodity_desc"] ? "This detail cannot be empty" : ''}
                                                                value={postCommodityPayload.commodity_desc}
                                                                onChange={this.handleInputChange.bind(this)}
                                                                fullWidth
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.customInput,
                                                                    },
                                                                }}
                                                                // helperText="Some important text"
                                                                variant="outlined"
                                                            />

                                                        </div>
                                                        <div className="mt-4" style={{ display: "flex" }}>
                                                            <TextField
                                                                id="quantity"
                                                                error={errorFields["quantity"] ? true : false}
                                                                type="number"
                                                                helperText={errorFields["quantity"] ? "This detail cannot be empty" : ''}
                                                                label="Selling commodity volume per month"
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.customInput,
                                                                    },
                                                                    inputProps: { min: 0 }
                                                                }}

                                                                fullWidth
                                                                // style={{ width: '60%' }}
                                                                value={postCommodityPayload.quantity}
                                                                onChange={this.handleInputChange.bind(this)}
                                                                variant="outlined"
                                                            />
                                                            {/* <TextField
                                                                select
                                                                id="quantity_unit"
                                                                name="quantity_unit"
                                                                label="Unit"
                                                                error={errorFields["quantity_unit"] ? true : false}
                                                                helperText={errorFields["quantity_unit"] ? "This detail cannot be empty" : ''}
                                                                type="text"
                                                                // label="Commodity Description"
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.customInput,
                                                                    },
                                                                }}
                                                                fullWidth
                                                                value={postCommodityPayload.quantity_unit}
                                                                onChange={this.handleInputChange.bind(this)}
                                                                style={{ width: '39%' }}
                                                                // helperText="Some important text"
                                                                variant="outlined"
                                                            >
                                                                {["Kg", "Quintal", "MT"].map((key, i) => (
                                                                    <MenuItem key={i} value={key} selected={true}>
                                                                        {key}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField> */}
                                                        </div>

                                                        <div className="free_search mt-1">
                                                            <div className="radio_toolbar" id="quantity_unit" style={{ margin: "0px" }}>
                                                                <input type="radio" id="Kg" name="Kg" value={"Kg"}
                                                                    // style={{ color: postCommodityPayload.payment_type === "L/C" ? "#25282B" : "#FFFFFF" }}
                                                                    checked={postCommodityPayload.quantity_unit === "Kg"}
                                                                />
                                                                <label htmlFor="Kg" className="radio_ps"
                                                                    style={{
                                                                        background: postCommodityPayload.quantity_unit === "Kg" ? "#247BC7" : "#FFFFFF",
                                                                        color: postCommodityPayload.quantity_unit !== "Kg" ? "#25282B" : "#FFFFFF"
                                                                    }}
                                                                    onClick={(ev) => this.handleInputRadioChange(ev, "Kg", "quantity_unit")} >Kg</label>

                                                                <input type="radio" id="Quintal" name="Quintal"
                                                                    // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                                    value="Quintal"
                                                                    checked={postCommodityPayload.quantity_unit === "Quintal"}
                                                                />
                                                                <label htmlFor="Quintal" className="radio_ps"
                                                                    style={{
                                                                        marginLeft: "10px",
                                                                        background: postCommodityPayload.quantity_unit === "Quintal" ? "#247BC7" : "#FFFFFF",
                                                                        color: postCommodityPayload.quantity_unit !== "Quintal" ? "#25282B" : "#FFFFFF"
                                                                    }} onClick={(ev) => this.handleInputRadioChange(ev, "Quintal", "quantity_unit")}>Quintal</label>

                                                                <input type="radio" id="MT" name="MT"
                                                                    // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                                    value="MT"
                                                                    checked={postCommodityPayload.quantity_unit === "MT"}
                                                                />
                                                                <label htmlFor="MT" className="radio_ps"
                                                                    style={{
                                                                        marginLeft: "10px",
                                                                        background: postCommodityPayload.quantity_unit === "MT" ? "#247BC7" : "#FFFFFF",
                                                                        color: postCommodityPayload.quantity_unit !== "MT" ? "#25282B" : "#FFFFFF"
                                                                    }} onClick={(ev) => this.handleInputRadioChange(ev, "MT", "quantity_unit")}>MT</label>
                                                            </div>
                                                            {errorFields["quantity_unit"] ? <div className="inputFrameinputError_txt">Please select an option</div> : ''}
                                                        </div>

                                                        <div className="mt-4">
                                                            <TextField
                                                                // id="outlined-helperText"
                                                                label="Location of Commodity"
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.customInput,
                                                                    },
                                                                }}
                                                                fullWidth
                                                                // helperText="Some important text"
                                                                variant="outlined"
                                                                id="locality"
                                                                name="locality"
                                                                error={errorFields["locality"] ? true : false}
                                                                helperText={errorFields["locality"] ? "This detail cannot be empty" : ''}
                                                                type="text"
                                                                value={postCommodityPayload.locality}
                                                                onChange={this.handleInputChange.bind(this)}
                                                            >
                                                            </TextField>

                                                        </div>
                                                        {/* <div className="mt-4">
                                                            <TextField
                                                                // id="outlined-helperText"
                                                                label="Shipment type"
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.customInput,
                                                                    },
                                                                }}
                                                                fullWidth
                                                                // helperText="Some important text"
                                                                variant="outlined"
                                                                select
                                                                id="shipment"
                                                                name="shipment"
                                                                error={errorFields["shipment"] ? true : false}
                                                                helperText={errorFields["shipment"] ? "This detail cannot be empty" : ''}
                                                                type="text"
                                                                // style={{ width: '59%', marginTop: '5px' }}
                                                                value={postCommodityPayload.shipment}
                                                                onChange={this.handleInputChange.bind(this)}
                                                            >
                                                                {["CIF", "FOB"].map((key, i) => (
                                                                    <MenuItem key={i} value={key} selected={true}>
                                                                        {key}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>

                                                        </div> */}
                                                        <div className="mt-4">
                                                            <TextField
                                                                id="year_of_harvest"
                                                                name="year_of_harvest"
                                                                select
                                                                label="Year of harvest"
                                                                error={errorFields["year_of_harvest"] ? true : false}
                                                                helperText={errorFields["year_of_harvest"] ? "This detail cannot be empty" : ''}
                                                                // style={{ width: '49%' }}
                                                                fullWidth
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.customInput,
                                                                    },
                                                                }}
                                                                value={postCommodityPayload.year_of_harvest}
                                                                onChange={this.handleInputChange.bind(this)}
                                                                // onChange={handleChange}
                                                                // helperText="Please select your currency"
                                                                variant="outlined"
                                                            >
                                                                {["2020-2021",
                                                                    "2019-2020",
                                                                    "2018-2019",
                                                                    "2017-2018"
                                                                ].map((option) => (
                                                                    <MenuItem key={option.value} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </div>


                                                    </>}

                                                    {mobileStep === 3 && <>
                                                        {/* <div className="mt-4 postEnquiry_title">Enquiry Details</div> */}
                                                        <div className="mt-3" style={{ display: "flex" }}>
                                                            <TextField
                                                                id="price"
                                                                error={errorFields["price"] ? true : false}
                                                                helperText={errorFields["price"] ? "This detail cannot be empty" : ''}
                                                                name="price"
                                                                label="Estimated FOB price"
                                                                type="number"

                                                                value={postCommodityPayload.price}
                                                                onChange={this.handleInputChange.bind(this)}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.customInput,
                                                                    },
                                                                    inputProps: { min: 0 }
                                                                }}
                                                                // style={{ width: '59%' }}
                                                                fullWidth
                                                                // helperText="Some important text"
                                                                variant="outlined"
                                                            />
                                                            {/* &nbsp;
                                                            &nbsp;
                                                            <TextField
                                                                select
                                                                id="price_unit"
                                                                name="price_unit"
                                                                label="per"
                                                                error={errorFields["price_unit"] ? true : false}
                                                                helperText={errorFields["price_unit"] ? "This detail cannot be empty" : ''}
                                                                type="text"
                                                                style={{ width: '25%' }}
                                                                value={postCommodityPayload.price_unit}
                                                                onChange={this.handleInputChange.bind(this)}

                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.customInput,
                                                                    },
                                                                }}
                                                                variant="outlined"
                                                            >
                                                                {["Kg", "Quintal", "MT"].map((key, i) => (
                                                                    <MenuItem key={i} value={key} selected={true}>
                                                                        {key}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
    &nbsp;
    &nbsp;
    <TextField
                                                                select
                                                                id="price_currency"
                                                                name="price_currency"
                                                                label="Currency"
                                                                error={errorFields["price_currency"] ? true : false}
                                                                helperText={errorFields["price_currency"] ? "This detail cannot be empty" : ''}
                                                                type="text"
                                                                style={{ width: '24%' }}
                                                                value={postCommodityPayload.price_currency}
                                                                onChange={this.handleInputChange.bind(this)}

                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.customInput,
                                                                    },
                                                                }}
                                                                variant="outlined"
                                                            >
                                                                {["INR", "USD", "AED"].map((option) => (
                                                                    <MenuItem key={option} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField> */}
                                                        </div>



                                                        <div className="free_search mt-1">
                                                            <div className="radio_toolbar" id="price_currency" style={{ margin: "0px" }}>
                                                                <input type="radio" id="INR" name="INR" value={"INR"}
                                                                    // style={{ color: postCommodityPayload.payment_type === "L/C" ? "#25282B" : "#FFFFFF" }}
                                                                    checked={postCommodityPayload.price_currency === "INR"}
                                                                />
                                                                <label htmlFor="INR" className="radio_ps"
                                                                    style={{
                                                                        background: postCommodityPayload.price_currency === "INR" ? "#247BC7" : "#FFFFFF",
                                                                        color: postCommodityPayload.price_currency !== "INR" ? "#25282B" : "#FFFFFF"
                                                                    }}
                                                                    onClick={(ev) => this.handleInputRadioChange(ev, "INR", "price_currency")} >INR</label>

                                                                <input type="radio" id="USD" name="USD"
                                                                    // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                                    value="USD"
                                                                    checked={postCommodityPayload.price_currency === "USD"}
                                                                />
                                                                <label htmlFor="USD" className="radio_ps"
                                                                    style={{
                                                                        marginLeft: "10px",
                                                                        background: postCommodityPayload.price_currency === "USD" ? "#247BC7" : "#FFFFFF",
                                                                        color: postCommodityPayload.price_currency !== "USD" ? "#25282B" : "#FFFFFF"
                                                                    }} onClick={(ev) => this.handleInputRadioChange(ev, "USD", "price_currency")}>USD</label>

                                                                <input type="radio" id="AED" name="AED"
                                                                    // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                                    value="AED"
                                                                    checked={postCommodityPayload.price_currency === "AED"}
                                                                />
                                                                <label htmlFor="AED" className="radio_ps"
                                                                    style={{
                                                                        marginLeft: "10px",
                                                                        background: postCommodityPayload.price_currency === "AED" ? "#247BC7" : "#FFFFFF",
                                                                        color: postCommodityPayload.price_currency !== "AED" ? "#25282B" : "#FFFFFF"
                                                                    }} onClick={(ev) => this.handleInputRadioChange(ev, "AED", "price_currency")}>AED</label>
                                                            </div>
                                                            {errorFields["price_currency"] ? <div className="inputFrameinputError_txt">Please select an option</div> : ''}
                                                        </div>

                                                        <div className="per_text text-center" style={{ lineHeight: 2 }}>Per</div>

                                                        <div className="free_search mt-1">
                                                            <div className="radio_toolbar" id="price_unit" style={{ margin: "0px" }}>
                                                                <input type="radio" id="Kg" name="Kg" value={"Kg"}
                                                                    // style={{ color: postCommodityPayload.payment_type === "L/C" ? "#25282B" : "#FFFFFF" }}
                                                                    checked={postCommodityPayload.price_unit === "Kg"}
                                                                />
                                                                <label htmlFor="Kg" className="radio_ps"
                                                                    style={{
                                                                        background: postCommodityPayload.price_unit === "Kg" ? "#247BC7" : "#FFFFFF",
                                                                        color: postCommodityPayload.price_unit !== "Kg" ? "#25282B" : "#FFFFFF"
                                                                    }}
                                                                    onClick={(ev) => this.handleInputRadioChange(ev, "Kg", "price_unit")} >Kg</label>

                                                                <input type="radio" id="Quintal" name="Quintal"
                                                                    // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                                    value="Quintal"
                                                                    checked={postCommodityPayload.price_unit === "Quintal"}
                                                                />
                                                                <label htmlFor="Quintal" className="radio_ps"
                                                                    style={{
                                                                        marginLeft: "10px",
                                                                        background: postCommodityPayload.price_unit === "Quintal" ? "#247BC7" : "#FFFFFF",
                                                                        color: postCommodityPayload.price_unit !== "Quintal" ? "#25282B" : "#FFFFFF"
                                                                    }} onClick={(ev) => this.handleInputRadioChange(ev, "Quintal", "price_unit")}>Quintal</label>

                                                                <input type="radio" id="MT" name="MT"
                                                                    // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                                    value="MT"
                                                                    checked={postCommodityPayload.price_unit === "MT"}
                                                                />
                                                                <label htmlFor="MT" className="radio_ps"
                                                                    style={{
                                                                        marginLeft: "10px",
                                                                        background: postCommodityPayload.price_unit === "MT" ? "#247BC7" : "#FFFFFF",
                                                                        color: postCommodityPayload.price_unit !== "MT" ? "#25282B" : "#FFFFFF"
                                                                    }} onClick={(ev) => this.handleInputRadioChange(ev, "MT", "price_unit")}>MT</label>
                                                            </div>
                                                            {errorFields["price_unit"] ? <div className="inputFrameinputError_txt">Please select an option</div> : ''}
                                                        </div>

                                                        {/* <div className="mt-4 postEnquiry_title">Packing Type</div>
                                                        <div className="free_search mt-4">
                                                            <div className="radio_toolbar">
                                                                <input type="radio" id="big bag" name="big bag" value={"big bag"}
                                                                    // onChange={(ev) => this.handleInputRadioChange(ev, "payment_type")}
                                                                    // onChange={ e => this.handleInputRadioChange.bind(this)}
                                                                    style={{ color: postCommodityPayload.packing_type === "big bag" ? "#25282B" : "#FFFFFF" }}
                                                                    checked={postCommodityPayload.packing_type === "big bag"}
                                                                />
                                                                <label for="big bag" className="radio_ps"
                                                                    style={{
                                                                        background: postCommodityPayload.packing_type === "big bag" ? "#247BC7" : "#FFFFFF",
                                                                        color: postCommodityPayload.packing_type !== "big bag" ? "#25282B" : "#FFFFFF"
                                                                    }}
                                                                    onClick={(ev) => this.handleInputRadioChange(ev, "big bag", "packing_type")} >Big Bag</label>

                                                                <input type="radio" id="small bag" name="small bag"
                                                                    // style={{ color: postCommodityPayload.packing_type === "small bag" ? "#25282B" : "#FFFFFF" }}
                                                                    value="small bag"
                                                                    checked={postCommodityPayload.packing_type === "small bag"}
                                                                />
                                                                <label for="small bag" className="radio_ps"
                                                                    style={{
                                                                        marginLeft: "10px",
                                                                        color: postCommodityPayload.packing_type !== "small bag" ? "#25282B" : "#FFFFFF",
                                                                        background: postCommodityPayload.packing_type === "small bag" ? "#247BC7" : "#FFFFFF"
                                                                    }}
                                                                    onClick={(ev) => this.handleInputRadioChange(ev, "small bag", "packing_type")}>Small Bag</label>
                                                            </div>

                                                        </div> */}


                                                        <div className="mt-4 postEnquiry_title d-flex">Payment Preference&nbsp;
                                                            <Tooltip
                                                                open={this.state.showTooltip}
                                                                classes={{ tooltip: classes.tooltipCls }}
                                                                title="Please choose one of L/C or Advance Payment as your preferred payment mode. If Advance Payment is your preferred payment mode, mention the portion of total amount (%) that you would like to receive in advance." arrow>
                                                                <img src={info_icon}
                                                                    onMouseOver={() => this.setState({ showTooltip: !this.state.showTooltip })}
                                                                    onMouseOut={() => this.setState({ showTooltip: !this.state.showTooltip })}
                                                                    onClick={() => this.setState({ showTooltip: !this.state.showTooltip })}
                                                                    alt="" />
                                                            </Tooltip>
                                                        </div>

                                                        <div className="free_search mt-4">
                                                            <div className="radio_toolbar">
                                                                <input type="radio" id="L/C" name="L/C" value={"L/C"}
                                                                    // style={{ color: postCommodityPayload.payment_type === "L/C" ? "#25282B" : "#FFFFFF" }}
                                                                    checked={postCommodityPayload.payment_type === "L/C"}
                                                                />
                                                                <label htmlFor="L/C" className="radio_ps"
                                                                    style={{
                                                                        background: postCommodityPayload.payment_type === "L/C" ? "#247BC7" : "#FFFFFF",
                                                                        color: postCommodityPayload.payment_type !== "L/C" ? "#25282B" : "#FFFFFF"
                                                                    }}
                                                                    onClick={(ev) => this.handleInputRadioChange(ev, "L/C", "payment_type")} x>L/c</label>



                                                                <input type="radio" id="Advance payment" name="Advance payment"
                                                                    // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                                    value="Advance payment"
                                                                    checked={postCommodityPayload.payment_type === "Advance payment"}
                                                                />
                                                                <label htmlFor="Advance payment" className="radio_ps"
                                                                    style={{
                                                                        marginLeft: "10px",
                                                                        background: postCommodityPayload.payment_type === "Advance payment" ? "#247BC7" : "#FFFFFF",
                                                                        color: postCommodityPayload.payment_type !== "Advance payment" ? "#25282B" : "#FFFFFF"
                                                                    }} onClick={(ev) => this.handleInputRadioChange(ev, "Advance payment", "payment_type")}>Advance Payment</label>
                                                            </div>
                                                            {errorFields["payment_type"] ? <div className="inputFrameinputError_txt">Please select an option</div> : ''}
                                                        </div>

                                                        {postCommodityPayload.payment_type && postCommodityPayload.payment_type === "Advance payment" &&
                                                            <div className="mt-4">

                                                                <TextField
                                                                    id="advance_percentage"
                                                                    error={errorFields["advance_percentage"] ? true : false}
                                                                    helperText={errorFields["advance_percentage"] ? "This detail cannot be empty" : ''}
                                                                    name="advance_percentage"
                                                                    label="Advance payment percentage"
                                                                    type="text"
                                                                    value={postCommodityPayload.advance_percentage}
                                                                    onChange={this.handleInputChange.bind(this)}
                                                                    InputProps={{
                                                                        classes: {
                                                                            root: classes.customInput,
                                                                        },
                                                                        inputProps: {
                                                                            max: 100, min: 1
                                                                        }
                                                                    }}
                                                                    // style={{ width: '59%' }}
                                                                    fullWidth
                                                                    // helperText="Some important text"
                                                                    variant="outlined"
                                                                />
                                                            </div>
                                                        }
                                                        <div className="mt-4">
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardDatePicker
                                                                    disableToolbar
                                                                    variant="outlined"
                                                                    format="yyyy-MM-dd"
                                                                    margin="normal"
                                                                    inputVariant="outlined"
                                                                    id="offer_expiry_date"
                                                                    minDate={CommonUtil.getTommorowSDate()}
                                                                    name="offer_expiry_date"
                                                                    error={errorFields["offer_expiry_date"] ? true : false}
                                                                    helperText={errorFields["offer_expiry_date"] ? "This detail cannot be empty" : ''}
                                                                    label="Offer expiry date"
                                                                    disablePast
                                                                    InputProps={{
                                                                        classes: {
                                                                            root: classes.customInput,
                                                                        },
                                                                        allowKeyboardControl: false,
                                                                        readOnly: true,
                                                                        autoFill: false
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: this.state.selectedDate === "" ? false : true,
                                                                    }}
                                                                    style={{ width: "100%" }}
                                                                    value={this.state.selectedDate}
                                                                    onChange={(dateval) => this.handleDateInputChange(dateval)}
                                                                    // onChange={handleDateChange}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change date',
                                                                    }}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </div>

                                                    </>}

                                                    {mobileStep === 4 && <>
                                                        <div className="mt-4 postEnquiry_title">Commodity Parameters</div>
                                                        <div className="mt-4">
                                                            {postCommodityPayload && postCommodityPayload["other_quality_details"] &&
                                                                postCommodityPayload["other_quality_details"]["data"] &&
                                                                postCommodityPayload["other_quality_details"]["data"].length > 0 &&
                                                                postCommodityPayload["other_quality_details"]["data"].map((item, indobj) =>
                                                                    <TextField
                                                                        key={indobj + "__"}
                                                                        id={item.name}
                                                                        name={item.name}
                                                                        error={errorFields[item.name] ? true : false}
                                                                        label={item.name + " " + (item.unit ? item.unit : "")}
                                                                        fullWidth
                                                                        style={{

                                                                            textTransform: "capitalize",
                                                                            marginTop: "14px"
                                                                        }}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: classes.customInput,
                                                                            },
                                                                        }}
                                                                        value={postCommodityPayload["other_quality_details"][item.name]}
                                                                        onChange={this.handleQualityParamsInputChange.bind(this, item, indobj)}
                                                                        variant="outlined"
                                                                    />)}

                                                        </div>

                                                        <div className="mt-4">
                                                            {<TextField
                                                                id="other_comment"
                                                                label="Any other comments"
                                                                placeholder="Any other comments (Enter details like quality params / packaging etc. requirements)"
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.customInput,
                                                                    },
                                                                }}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                                // helperText="Some important text"
                                                                error={errorFields["other_comment"] ? true : false}
                                                                type="text"

                                                                value={postCommodityPayload.other_comment}
                                                                onChange={this.handleInputChange.bind(this)}
                                                                variant="outlined"
                                                            />}

                                                        </div>
                                                    </>}
                                                </form>
                                            </div>
                                        </>}
                                    <div className="col-12 text-right mt-5 d_lg_none_sm fixed-bottom py-3 bg-white">
                                        {!CommonUtil.checkIfKYCverified('seller') && <div className="inputFrameinputError_txt pb-2" style={{ textAlign: 'center' }}>Your Kyc is pending!</div>}
                                        <button className="btn btn-success px-4 text-uppercase cus_bg_green w-100 py-3"
                                            disabled={!CommonUtil.checkIfKYCverified('seller') || loading}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (mobileStep < 4) {
                                                    if (this.checkIfFormIsValidStepWise(mobileStep)) {
                                                        this.setState({ mobileStep: mobileStep + 1 })
                                                    }
                                                }
                                                if (mobileStep === 4) {

                                                    console.log(postCommodityPayload);
                                                    this.setState({ loading: true }, () => {
                                                        this.postCommodityToPortal();
                                                    })
                                                }
                                            }}
                                        >{mobileStep === 4 ? "POST ENQUIRY" : "NEXT"}</button>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    {/*------------------------- Mobile vie end ------------------------ */}
                </div>
            </section >
        </>);
    }
}

SellerEnquiryContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(SellerEnquiryContainer));